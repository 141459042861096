import { Button, Select, Spin, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import './ManAnalysisReporting.css'; // Import the CSS file
import { employeeAttributes } from './components/Data';
import Navbar from './components/Navbar';
import moment from 'moment';

const { Option } = Select;
const ManAnalysisReporting = () => {
    const [employeeData, setEmployeeData] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [employeeGroups, setEmployeeGroups] = useState({});
    // const [managerEmail, setManagerEmail]=useState(null);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const storedManagerEmail = localStorage.getItem('managerEmail') || '';
    const [managerEmail, setManagerEmail] = useState(storedManagerEmail);
    const [positions, setPositions] = useState([]); // For storing all positions
    const [selectedPosition, setSelectedPosition] = useState([]); // For storing the selected position
    const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);

    const fetchCompany = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/companies', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                if (data.companies && data.companies.length > 0) {
                    const dict = data.companies.reduce((acc, item) => {
						acc[item._id] = item.companyName;
						return acc;
						}, {});
					setCompanies(dict);
                    // setCompanies(data.companies); // 
                    const companyId = data.companies[0]._id;
                    const companyName = data.companies[0].companyName;

                    fetchEmployees();
                } else {
                    message.error('Company ID not found in the response');
                }
            } else {
                message.error(data.error || 'Failed to fetch company');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch company');
        }
    };
    useEffect(() => {
        fetchCompany();
    }, []);

    useEffect(() => {
        if (Object.keys(companies).length > 0)
            fetchEmployees()
    }, [companies]);

    const formatDatesInArray = (arr) => {
		return arr.map(item => {
		  let formattedItem = { ...item };
		  formattedItem['companyName'] = companies[item.company];
		  for (let key in formattedItem) {
			if (formattedItem.hasOwnProperty(key) && key.endsWith('Date')) {
			  let value = formattedItem[key];
			  if (moment(value, moment.ISO_8601, true).isValid()) {
				formattedItem[key] = moment(value).format('DD-MM-YYYY');
			  }
			}
		  }
		  return formattedItem;
		});
	  };

    const fetchEmployees = async () => {
        const companyQuery = selectedCompanies.length > 0 ? `companies=${selectedCompanies.join(',')}` : '';
        const departmentQuery = selectedDepartments.length > 0 ? `departments=${selectedDepartments.join(',')}` : '';
        const managerEmailQuery = managerEmail ? `managerEmail=${encodeURIComponent(managerEmail)}` : '';

        // Combine all queries
        const query = [companyQuery, departmentQuery, managerEmailQuery].filter(Boolean).join('&');
        try {
            const token = localStorage.getItem('token');
            const url = `https://hrms-5u7j.onrender.com/manager/getEmployeesByCompaniesAndDepartments?${query}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();

            if (response.ok) {
                if (data === null) {
                    console.error('No Companies Found');
                }
                const emps = formatDatesInArray(data.employees)
                setEmployeeData(emps);

                const uniquePositions = [...new Set(data.employees.map(employee => employee.position))];
                setPositions(uniquePositions);

                setIsLoading(false);
            } else {
                console.error('Error:', data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    useEffect(() => {
        // Apply filtering based on the selected position
        const filteredData = employeeData?.filter(employee => {
            // // console.log(selectedPosition)
            if (selectedPosition.length === 0) {
                return true;
            }
            return selectedPosition.some(position => employee.position === position);
        });
        setFilteredEmployeeData(filteredData);

        const groupEmployeesByStatus = filteredData?.reduce((acc, employee) => {
            const status = employee.status;
            if (!acc[status]) {
                acc[status] = [];
            }
            acc[status].push(employee);
            return acc;
        }, {});

        setEmployeeGroups(groupEmployeesByStatus);
    }, [employeeData, selectedPosition]);


    const handleUpdate = () => {
        // Call the fetchEmployees function to update data when the "Update" button is clicked
        fetchEmployees();
    };


    const handleCompanyChange = (selectedValues) => {
        setSelectedCompanies(selectedValues); // Corrected variable name
    };
    const statusCounts = useMemo(() => {
        // Determine which dataset to use for the calculation
        if (!Array.isArray(employeeData) || !Array.isArray(filteredEmployeeData)) {
            return {}; // If employeeData isn't an array, return an empty object
        }

        const dataToUse = filteredEmployeeData.length > 0 ? filteredEmployeeData : employeeData;

        // Proceed with the calculation using the determined dataset
        return dataToUse.reduce((acc, curr) => {
            const status = curr.status || 'Unknown'; // Fallback to 'Unknown' if status is not defined
            acc[status] = (acc[status] || 0) + 1;
            return acc;
        }, {});
    }, [filteredEmployeeData, employeeData]); // Depend on both filteredEmployeeData and employeeData



    const barChartData = useMemo(() => {
        if (employeeData === null) {
            return []; // Return an empty array when data is not available
        }
        return Object.entries(statusCounts).map(([status, count]) => ({ name: status, count }));
    }, [employeeData, statusCounts]);

    const pieChartData = useMemo(() => {
        if (employeeData === null) {
            return []; // Return an empty array when data is not available
        }
        return Object.entries(statusCounts).map(([status, count]) => ({
            name: status,
            value: count,
        }));
    }, [employeeData, statusCounts]);

    const totalEmployeeCount = useMemo(() => {
        if (employeeData === null) {
            return []; // Return an empty array when data is not available
        }
        return Object.values(statusCounts).reduce((total, count) => total + count, 0);
    }, [statusCounts]);

    const STATUS_COLORS = {
        'Active': '#11686D', // Blue
        'Sick/Accidented': '#44116D', // Orange
        'In-Active': '#6D1611', // Yellow
        'Terminated/Resigned': '#3A6D11', // Red
        'Vacation': '#3c49c3'
    };

    return (
        <>
            <Navbar />
            <div className=''>
                {isLoading ? (
                    <Spin style={{ position: 'absolute', top: '50%', left: '50%' }} size='large' />
                ) : (
                    <div className="d-flex" style={{ padding: '3rem 5rem' }}>
                        <div className='col-md-6 analysis-counts' >
                            <div className='col-md-12 company-selector'>
                                <div className="controls-container d-flex flex-column gap-2 px-3 mb-4" >
                                    {/* Company Select Dropdown */}
                                    <Select
                                        showSearch
                                        style={{ minWidth: '250px' }} // Ensures a responsive width with a reasonable minimum
                                        placeholder="All Companies"
                                        value={selectedCompanies}
                                        onChange={handleCompanyChange}
                                        mode="multiple"
                                        maxTagCount={"responsive"}
                                    >
                                        {Object.entries(companies).map(([id, name]) => (
                                            <Option key={id} value={id}>{name}</Option>
                                        ))}
                                    </Select>
                                    <div className='d-flex gap-5'>
                                        <Select
                                            showSearch
                                            style={{ flex: 1, minWidth: '250px' }}
                                            placeholder="Position"
                                            value={selectedPosition}
                                            onChange={setSelectedPosition}
                                            mode="multiple"
                                            maxTagCount={"responsive"}
                                            allowClear
                                        >
                                            {positions.map(position => {
                                                if (position)
                                                    return <Option key={position} value={position}>{position}</Option>
                                            })}
                                        </Select>


                                        <Button
                                            style={{ width: '20%', backgroundColor: "#6FB555", padding: '0 2vw' }}
                                            type="primary"
                                            onClick={handleUpdate}
                                        >
                                            Select
                                        </Button>
                                    </div>

                                </div>

                            </div>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className='grid-cell' onClick={() => document.getElementById('active-employees')?.click()}>
                                        <div className='cell-label'>Active</div>
                                        <div className='cell-content'>{statusCounts.Active || 0}</div>
                                        {employeeGroups.Active && <CSVLink
                                            style={{ display: 'none' }}
                                            id='active-employees'
                                            data={employeeGroups.Active}
                                            headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
                                            filename='active-employees.csv'
                                        ></CSVLink>}
                                    </div>
                                </div>
                                <div className='col-sm-6' >
                                    <div className='grid-cell' onClick={() => document.getElementById('sick-employees')?.click()}>
                                        <div className='cell-label'>Sick/Accidented</div>
                                        <div className='cell-content'>{statusCounts['Sick/Accidented'] || 0}</div>
                                        {employeeGroups['Sick/Accidented'] &&
                                            <CSVLink
                                                style={{ display: 'none' }}
                                                id='sick-employees'
                                                data={employeeGroups['Sick/Accidented']}
                                                headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
                                                filename='sick/accidented-employees.csv'
                                            ></CSVLink>
                                        }
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='grid-cell' onClick={() => document.getElementById('inactive-employees')?.click()}>
                                        <div className='cell-label'>In Active</div>
                                        <div className='cell-content'>{statusCounts['In-Active'] || 0}</div>
                                        {employeeGroups['In-Active'] &&
                                            <CSVLink
                                                style={{ display: 'none' }}
                                                id='inactive-employees'
                                                data={employeeGroups['In-Active']}
                                                headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
                                                filename='In-Active-employees.csv'
                                            ></CSVLink>
                                        }
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='grid-cell' onClick={() => document.getElementById('terminated-employees')?.click()}>
                                        <div className='cell-label'>Terminated/Resigned</div>
                                        <div className='cell-content'>{statusCounts['Terminated/Resigned'] || 0}</div>
                                        {employeeGroups['Terminated/Resigned'] &&
                                            <CSVLink
                                                style={{ display: 'none' }}
                                                id='terminated-employees'
                                                data={employeeGroups['Terminated/Resigned']}
                                                headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
                                                filename='Terminated/Resigned-employees.csv'
                                            ></CSVLink>
                                        }
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='grid-cell' onClick={() => document.getElementById('vacation-employees')?.click()}>
                                        <div className='cell-label'>Vacation</div>
                                        <div className='cell-content'>{statusCounts['Vacation'] || 0}</div>
                                        {employeeGroups['Vacation'] &&
                                            <CSVLink
                                                style={{ display: 'none' }}
                                                id='vacation-employees'
                                                data={employeeGroups['Vacation']}
                                                headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
                                                filename='Vacation-employees.csv'
                                            ></CSVLink>
                                        }
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='grid-cell' onClick={() => document.getElementById('all-employees')?.click()}>
                                        <div className='cell-label'>Total</div>
                                        <div className='cell-content'>{totalEmployeeCount || 0}</div>
                                        {totalEmployeeCount > 0 &&
                                            <CSVLink
                                                style={{ display: 'none' }}
                                                id='all-employees'
                                                data={employeeData}
                                                headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
                                                filename='all-employees.csv'
                                            ></CSVLink>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-md-6'>
                            <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
                                {employeeData !== null ? ( // Check if employeeData is not null before rendering charts
                                    <ResponsiveContainer width="90%" height={300}>
                                        <PieChart>
                                            <Pie
                                                dataKey="value"
                                                isAnimationActive={true}
                                                data={pieChartData}
                                                paddingAngle={5}
                                                innerRadius={'60%'}
                                                outerRadius={'100%'}
                                                fill="#8884d8"
                                                label
                                            >
                                                {pieChartData.map((entry, index) => {
                                                    return <Cell key={`cell-${index}`} fill={`rgba(17, 104, 109, ${Math.max(1 - (index * 2 / 10), 0.4)})`} />
                                                })}
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>
                                    </ResponsiveContainer>
                                ) : (
                                    <div>No data available</div> // Display a message when data is not available
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default ManAnalysisReporting;
