import { BellOutlined, CheckOutlined, EditOutlined, FileExcelOutlined, FileSearchOutlined, PictureFilled, PlusOutlined, PoweroffOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Dropdown, Form, Input, Modal, Popover, Select, Spin, Switch, Tooltip, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// const formatter = (value) => <CountUp end={value} separator="," />;


const Settings = ({ setSettingsOpen }) => {
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const [showManagerModal, setShowManagerModal] = useState(false);
	const [showCompanyModal, setShowCompanyModal] = useState(false);
	const [manEmail, setManEmail] = useState('');
	const [manPassword, setManPassword] = useState('');
	const [manConfirmPassword, setManConfirmPassword] = useState('');
	const [access, setAccess] = useState(false);
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmNewPassword, setConfirmNewPassword] = useState('');
	const [storageData, setStorageData] = useState(null);
	const managerEmail = localStorage.getItem('managerEmail') || '';
	const [selectedCompany, setSelectedCompany] = useState('');
	const [managerCompany, setManagerCompany] = useState('undefined');
	const [logo, setLogo] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const bytesToMB = (bytes) => {
		return (bytes / (1024 * 1024 * 1024)).toFixed(2); // Converts bytes to MB and rounds to 2 decimal places
	};

	const createManager = async () => {
		if (manPassword !== manConfirmPassword) {
			message.error('Passwords do not match');
			return;
		}
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/createAccessManager', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ manEmail, manPassword, access }),
			});
			const data = await response.json();
			console.log(data);
			if (response.ok) {
				message.success('Successfully created manager');
				setShowManagerModal(false);
			} else {
				message.error('Email already exists');
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleUploadLogo = async (e) => {
		setIsLoading(true);
		const logoFile = e.target.files[0];
		// console.log(logoFile);
		try {
			const token = localStorage.getItem('token');

			const formData = new FormData();
			formData.append('logo', logoFile);

			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/addCompanyLogo`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});
			// console.log('response', response);

			if (response.ok) {
				message.success('Logo uploaded successfully');
				console.log(URL.createObjectURL(logoFile))
				setLogo(URL.createObjectURL(logoFile));
			} else {
				message.error('Failed to upload logo');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to upload logo');
		}
		finally {
			setIsLoading(false);
		}
	};

	const fetchLogo = async () => {
		console.log('fetching logo');
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/fetchCompanyLogo`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			console.log('response', response);
			if (response.ok) {
				const logoBlob = await response.blob();
				const logoUrl = URL.createObjectURL(logoBlob);
				console.log(logoUrl);
				setLogo(logoUrl);
			} else {
				console.error('Failed to fetch logo: Response no OK', response);
			}
		} catch (error) {
			console.error('Failed to fetch logo', error);
		}
	};


	const fetchStorageData = async (managerEmail) => {
		try {
			const token = localStorage.getItem('token');
			const url = `https://hrms-5u7j.onrender.com/manager/getStorageForManager/${managerEmail}`;

			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
			});

			const data = await response.json();
			console.log(data);

			if (response.ok) {
				if (data.manager.company)
					setManagerCompany(data.manager.company.companyName);
				setStorageData({
					...data,
					totalCollectionSize: bytesToMB(data.totalCollectionSize),
					totalStorageSize: bytesToMB(data.totalStorageSize),
					totalIndexSize: bytesToMB(data.totalIndexSize),
				});
			} else {
				console.error('Error fetching storage data:', data.message);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	useEffect(() => {
		fetchStorageData(managerEmail);
		fetchLogo();
	}, [managerEmail]);
	const handleLogout = () => {
		localStorage.clear(); // Clears all localStorage data
		navigate('/'); // Redirects to the home or login page
	};

	const handleUpdatePassword = async () => {
		if (!currentPassword || !newPassword || !confirmNewPassword) {
			message.error('Please fill in all password fields');
			return;
		}

		if (newPassword !== confirmNewPassword) {
			message.error('New passwords do not match');
			return;
		}

		try {
			const token = localStorage.getItem('token');

			const managerEmail = localStorage.getItem('managerEmail');
			const requestData = { managerEmail, currentPassword, newPassword };

			// Log the data being sent
			// console.log('Data being sent:', requestData);

			const response = await fetch('https://hrms-5u7j.onrender.com/manager/update-password', {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(requestData),
			});

			if (response.ok) {
				message.success('Password updated successfully');
				handleLogout();
			} else {
				const data = await response.json();
				message.error(`Failed to update password: ${data.message}`);
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to update password');
		}
	};


	const handleUpdateCompany = async () => {
		if (selectedCompany === '') {
			message.error('Please select a company');
			return;
		}
		const company = storageData.manager.companies[selectedCompany];
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/update-company', {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ companyId: company._id }),
			});
			const data = await response.json();
			console.log(data);
			if (response.ok) {
				message.success('Company updated successfully');
				setShowCompanyModal(false);
				setManagerCompany(company.companyName);
			} else {
				message.error(`Failed to update company: ${data.message}`);
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to update company');
		}
	};

	const handlePasswordModalClick = () => {
		console.log('clicked');
		setSettingsOpen(false);
		setShowPasswordModal(true);
	};

	const handleManagerModalClick = () => {
		setSettingsOpen(false);
		setShowManagerModal(true);
	};

	useEffect(() => {
		console.log(showPasswordModal)
	}, [showPasswordModal]);


	return (
		<div className='settings-container'>
			<h5>{managerCompany}
				<Button
					style={{ position: 'absolute', color: 'gray', borderRadius: '50%' }}
					type='text'
					icon={<EditOutlined onClick={() => setShowCompanyModal(true)} />}>
				</Button>
			</h5>

			<div className='settings-icon mb-2'>
				{isLoading ?
					<Spin />
					:
					<>
						<div className='icon'>
							{logo ?
								<img src={logo} alt='swift logo' />
								:
								<PictureFilled />
							}
						</div>
						<input id='upload-logo' type="file" onChange={handleUploadLogo} style={{ display: 'none' }} />
						<Button onClick={() => document.getElementById('upload-logo').click()} className='overlay' type='text' icon={<UploadOutlined style={{ fontSize: '30px', color: 'gray' }} />}></Button>
					</>}
			</div>
			<div className='d-flex w-100 justify-content-between align-items-baseline'>
				<div className='d-flex align-items-baseline'>
					<h5>{storageData?.totalStorageSize}</h5> &nbsp;
					GB used
				</div>
				<div>
					10 GB total
				</div>
			</div>
			<div style={{ height: '10px', width: '100%', backgroundColor: 'lightgray', borderRadius: '25px' }}>
				<div style={{ height: '100%', width: `${storageData?.totalStorageSize * 10}%`, backgroundColor: 'black', borderRadius: '25px' }}></div>
			</div>
			{storageData && storageData.manager.managerEmail !== 'mudassir@swift.com' ?
				<div className='buttons-container'>
					<div onClick={handlePasswordModalClick} style={{ width: '100%', border: 'none', textAlign: 'center', borderRadius: '25px' }}>
						Update Password
						<EditOutlined />
					</div>
				</div>
				:
				<div className='buttons-container'>
					<div onClick={handleManagerModalClick}>
						<PlusOutlined />
						Create Manager
					</div>
					<div onClick={handlePasswordModalClick}>
						Update Password
						<EditOutlined />
					</div>
				</div>
			}

			<Modal
				title="Update Company"
				open={showCompanyModal}
				onOk={handleUpdateCompany}
				onCancel={() => setShowCompanyModal(false)}
			>
				<Select
					showSearch
					style={{ width: '100%' }}
					placeholder="Select Company"
					optionFilterProp="children"
					onChange={(value) => setSelectedCompany(value)}
					value={selectedCompany}
				>
					{storageData && storageData.manager.companies.map((company, index) => (
						<Select.Option key={company._id} value={index}>
							{company.companyName}
						</Select.Option>
					))}
				</Select>
			</Modal>

			{/* <input id='upload-logo' type="file" onChange={handleUploadLogo} style={{ display: 'none' }} /> */}
			<Modal
				// title="Update Password"
				open={showPasswordModal}
				onOk={handleUpdatePassword}
				onCancel={() => setShowPasswordModal(false)}
			>
				<Form layout="vertical">
					<Form.Item label="Current Password">
						<Input.Password value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
					</Form.Item>
					<Form.Item label="New Password">
						<Input.Password value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
					</Form.Item>
					<Form.Item label="Confirm New Password">
						<Input.Password value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				// title="Create Manager"
				open={showManagerModal}
				onOk={createManager}
				onCancel={() => setShowManagerModal(false)}
			>
				<Form layout="vertical">
					<Form.Item label="Manager Email">
						<Input value={manEmail} onChange={(e) => setManEmail(e.target.value)} />
					</Form.Item>
					<Form.Item label="Manager Password">
						<Input.Password value={manPassword} onChange={(e) => setManPassword(e.target.value)} />
					</Form.Item>
					<Form.Item label="Confirm Password">
						<Input.Password value={manConfirmPassword} onChange={(e) => setManConfirmPassword(e.target.value)} />
					</Form.Item>
					<Form.Item label="Access">
						<Switch onChange={(e) => setAccess(!access)} checkedChildren="Full" unCheckedChildren="Limited" />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	)
}

const Navbar = () => {
	const [notificationCount, setNotificationCount] = useState(0);
	const [notifications, setNotifications] = useState({
		today: [],
		yesterday: [],
		older: [],
	});
	const [settingsOpen, setSettingsOpen] = useState(false);
	const logoIcons = [
		<span className='logo-container' style={{ backgroundColor: 'limegreen' }}>
			<CheckOutlined style={{ fontSize: '15px' }} />
		</span>,
		<span className='logo-container'>
			<FileSearchOutlined style={{ fontSize: '15px' }} />
		</span>,
		<span className='logo-container' style={{ backgroundColor: 'sandybrown' }}>
			<FileExcelOutlined style={{ fontSize: '15px' }} />
		</span>,
	]

	const getNotificationIcon = (message) => {
		if (message.includes('Termination'))
			return logoIcons[1];
		if (message.includes('Leave'))
			return logoIcons[2];
		return logoIcons[0];
	};

	const groupNotificationsByDate = (notifications) => {
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);

		const grouped = {
			today: [],
			yesterday: [],
			older: [],
		};

		notifications.forEach((notification) => {
			const notificationDate = new Date(notification.date); // Assuming each notification has a `date` field
			if (
				notificationDate.getDate() === today.getDate() &&
				notificationDate.getMonth() === today.getMonth() &&
				notificationDate.getFullYear() === today.getFullYear()
			) {
				grouped.today.push(notification);
			} else if (
				notificationDate.getDate() === yesterday.getDate() &&
				notificationDate.getMonth() === yesterday.getMonth() &&
				notificationDate.getFullYear() === yesterday.getFullYear()
			) {
				grouped.yesterday.push(notification);
			} else {
				grouped.older.push(notification);
			}
		});

		return grouped;
	};

	const fetchNotifications = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/notifications', {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			if (response.ok) {
				setNotificationCount(data.length);
				setNotifications(groupNotificationsByDate(data));
			}
			else {
				console.error('Error:', data);
			}

		} catch (error) {
			console.error('Error:', error);
		}
	};

	const readNotification = async (id) => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/read-notification', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id }),
			});
			const data = await response.json();
			if (response.ok) {
				fetchNotifications();
			}
			else {
				console.error('Error:', data.message);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const readAllNotifications = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/read-all-notifications', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				}
			});
			const data = await response.json();
			if (response.ok) {
				setNotifications({
					today: [],
					yesterday: [],
					older: [],
				});
				setNotificationCount(0);
				message.success('All notifications marked as read');
			}
			else {
				console.error('Error:', data.message);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	useEffect(() => {
		fetchNotifications();
	}, []);

	const navigate = useNavigate();
	const location = useLocation();
	const items = [
		{
			key: '1',
			label: (
				<Link className='dropdown-link' to='/manager/analysis/reporting'>
					Status Analysis
				</Link>
			),
		},
		{
			key: '2',
			label: (
				<Link className='dropdown-link' to='/manager/analysis/date-reports'>
					Date Reports
				</Link>
			),
		},
		{
			key: '3',
			label: (
				<Link className='dropdown-link' to='/manager/analysis/vacation-reports'>
					Vacation Reports
				</Link>
			),
		},
		{
			key: '4',
			label: (
				<Link className='dropdown-link' to='/manager/analysis/sick-leave-reports'>
					Sick Leave Reports
				</Link>
			),
		},
	]

	const manageItems = [
		{
			key: '1',
			label: (
				<Link className='dropdown-link' to='/manager/managerCompanies'>Companies</Link>

			),
		},
		{
			key: '2',
			label: (
				<Link className='dropdown-link' to='/manager/accommodation-management'>Accommodation</Link>

			),
		},
		{
			key: '3',
			label: (
				<Link className='dropdown-link' to='/manager/vehicle-management'>Vehicle</Link>

			),
		}
		// {
		// 	key: '3',
		// 	label: (
		// 		<Link className='dropdown-link' to='/manager/analysis/vacation-reports'>
		// 			Vacation Reports
		// 		</Link>
		// 	),
		// }
	]

	const handleLogout = () => {
		localStorage.removeItem('managerName');
		localStorage.removeItem('managerEmail');
		localStorage.removeItem('token');
		navigate('/');
	};

	const isActive = (path) => {
		return location.pathname.startsWith('/manager/' + path) ? 'active' : '';
	};

	const leftEyeRef = useRef(null);
	const rightEyeRef = useRef(null);

	useEffect(() => {
		const handleMouseMove = (event) => {
			const moveEye = (eye) => {
				const eyeRect = eye.getBoundingClientRect();
				const eyeX = eyeRect.left + eyeRect.width / 2;
				const eyeY = eyeRect.top + eyeRect.height / 2;
				const rad = Math.atan2(event.clientX - eyeX, event.clientY - eyeY);
				const rot = (rad * (180 / Math.PI) * -1) + 230;
				eye.style.transform = `rotate(${rot}deg)`;
			};

			if (leftEyeRef.current) moveEye(leftEyeRef.current);
			if (rightEyeRef.current) moveEye(rightEyeRef.current);
		};

		document.addEventListener('mousemove', handleMouseMove);

		return () => {
			document.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);


	return (
		<header className='header'>
			<Link className='ps-5 position-relative' to='/manager'>
				<img src='/crootive.png' draggable='false' alt='logo' />
				<div className='eye-container'>
					<div className='eye' ref={leftEyeRef}></div>
					<div className='eye' ref={rightEyeRef}></div>
				</div>
			</Link>

			<div className='nav-links'>
				<Link to='/manager/employeeDataDashboard' className={isActive('employeeDataDashboard')}>Dashboard</Link>
				<Dropdown className='dropdown' menu={{
					items
				}}>
					<Link className={isActive('analysis')}>Analysis & Reporting</Link>
				</Dropdown>
				<Dropdown className='dropdown' menu={{
					items: manageItems
				}}>
					<Link className={isActive('manage')}>Manage</Link>
				</Dropdown>
				{/* <Link to='/manager/accommodation-management' className={isActive('accommodation-management')}>accommodation</Link>
				<Link to='/manager/managerCompanies' className={isActive('managerCompanies')}>Companies</Link> */}
				<Link to='/manager/hr-forms' className={isActive('hr-forms')}>HR Forms</Link>
				<Link to='/manager/approvals' className={isActive('approvals')}>Approvals</Link>
				<Link to='/manager/looker-studio'><img src='/looker-studio.svg' style={{ height: '30px' }} draggable='false' alt='logo' /></Link>
				<Link to='/' className='logout' onClick={handleLogout}><PoweroffOutlined style={{ fontSize: 23 }} /></Link>
				<Popover onOpenChange={setSettingsOpen} open={settingsOpen} placement='bottomRight' trigger={'click'} content={<Settings setSettingsOpen={setSettingsOpen} />}>
					<Link><SettingOutlined style={{ fontSize: 25 }} /></Link>
				</Popover>
				{/* <Link></Link> */}
				<Popover trigger={'click'} placement='bottomRight' content={
                    <div className={'noti-container'}>
                        {notificationCount > 0 ?
                            <div className='d-flex justify-content-end'>
                                <Link onClick={() => readAllNotifications()}>Mark all as read</Link>
                            </div>
                            :
                            <div className='no-notifications'>
                                <img src="/bell-slash.png" />
                                <h5>No notifications yet</h5>
                                <p style={{ textAlign: 'center' }}>When there are notifications, they will appear here</p>
                                <Button type='text' onClick={() => fetchNotifications()}>Refresh</Button>
                            </div>
                        }
                        {notifications.today.length > 0 &&
                            <>
                                <Divider orientation='left'> Today </Divider>
                                {notifications.today.map((notification, index) => (
                                    <Tooltip title='Mark as read'>
                                        <div className='notification'>
                                            {getNotificationIcon(notification.message)}
                                            <p onClick={() => readNotification(notification._id)} key={index}>
                                                {notification.message}
                                            </p>
                                        </div>
                                    </Tooltip>
                                ))}
                                <div style={{ height: '10px' }}></div>
                            </>
                        }

                        {notifications.yesterday.length > 0 &&
                            <>
                                <Divider orientation='left'> Yesterday </Divider>
                                {notifications.yesterday.map((notification, index) => (
                                    <Tooltip title='Mark as read'>
                                        <div className='notification'>
                                            {getNotificationIcon(notification.message)}
                                            <p onClick={() => readNotification(notification._id)} key={index}>
                                                {notification.message}
                                            </p>
                                        </div>
                                    </Tooltip>
                                ))}
                                <div style={{ height: '10px' }}></div>
                            </>
                        }
                        {notifications.older.length > 0 && <Divider orientation='left'> Older </Divider>}
                        {notifications.older.map((notification, index) => (
                            <Tooltip title='Mark as read'>
                                <div className='notification'>
                                    {getNotificationIcon(notification.message)}
                                    <p onClick={() => readNotification(notification._id)} key={index}>
                                        {notification.message}
                                    </p>
                                </div>
                            </Tooltip>
                        ))}
                    </div>
                }>
                    <Badge count={notificationCount} color='#11686D'>
                        <BellOutlined style={{ fontSize: 25, cursor: 'pointer' }} />
                    </Badge>
                </Popover>
			</div>
		</header>
	);
}

export default Navbar;