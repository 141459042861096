import React, { useEffect, useState } from 'react';
import './ContractResidential.css';
import html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';
import { DatePicker } from 'antd';

const EmployeeProbationaryEvaluation = () => {
    const [logoComponent, setLogoComponent] = useState(null);

	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);
    
    const handleDownload = async () => {
        const pdfElement = document.querySelector('.local-leave-container');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'employee-probationary-evaluation.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
    };

    return (
        <div className="center-container">
            <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p>
            <div className="disciplinary-action-form">
                <div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
                    <div className='form-container' style={{ padding: '5px' }}>
                        
                    <div style={{position:'absolute'}}>{logoComponent}</div><br/>
                        <h3 style={{ fontWeight: 'bolder', border: 'none', marginBottom: '0' }}>Employee Probationary Evaluation Form</h3>
                        <h3 style={{ fontWeight: 'bolder', border: 'none', marginBottom: '0' }}>HR/Admin Department</h3><br/>
                        <hr style={{ marginTop: '2px', marginBottom: '2px' }} className='hr-line'></hr>
                        <div className="row " style={{ fontSize: 'small' }}>
                            <div className="col-md-6 ">
                                <label>Employee Name:</label>
                                <input style={{ marginLeft: '20px', width: '50%' }} type="text" />
                            </div>
                            <div className="col-md-6 ">
                                <label>Employee Code:</label>
                                <input style={{ marginLeft: '20px', width: '50%' }} type="text" />
                            </div>
                            <div className=" col-md-6" style={{ paddingTop: '0' }}>
                                <label>Department:</label>
                                <input style={{ marginLeft: '50px', width: '50%' }} type="text" />
                            </div>
                            <div className=" col-md-6" style={{ paddingTop: '0' }}>
                                <label>Position:</label>
                                <input style={{ marginLeft: '75px', width: '50%' }} type="text" />
                            </div>
                        </div>
                        <h6 style={{ fontWeight: 'bolder' }}>Section I: Employee Details</h6>
                        <hr className='hr-line' style={{ marginTop: '5px', marginBottom: '5px' }}></hr>
                        <div className="row" style={{ fontSize: 'small' }}>
                            <div className=" col-md-6" style={{ paddingTop: '0' }}>
                                <label>Joining Date:</label>
                                <DatePicker style={{ marginLeft: '20px', width: '60%' }} />
                            </div>
                            <div className=" col-md-6" style={{ paddingTop: '0' }}>
                                <label>Work Location:</label>
                                <input style={{ marginLeft: '20px', width: '55%' }} type="text" />
                            </div>
                        </div>
                        <h6 style={{ fontWeight: 'bolder' }}>Section II: Evaluation Details</h6>
                        <h6 style={{ fontWeight: 'bolder' }}>*Please mark under each heading from 1-5</h6>
                        <hr className='hr-line' style={{ marginTop: '2px', marginBottom: '0px' }}></hr>
                        <hr className='hr-line' style={{ marginTop: '2px', marginBottom: '2px' }}></hr>
                        <small>*Points 11 to 14 are applicable only for supervisory positions and above</small>
                        <div><small>*5=Excellent, 4=Good, 3=Satisfactory, 2=fair, 1=Poor</small></div>

                        <hr className='hr-line' style={{ marginTop: '2px', marginBottom: '0px' }}></hr>
                        <hr className='hr-line' style={{ marginTop: '2px', marginBottom: '10px' }}></hr>

                        <table className='leave-table' style={{ fontSize: 'small' }}>
                            <tr>
                                <td>
                                    <table className="leave-table" style={{ textAlign: 'center', fontSize: 'smaller' }}>
                                        <tr>
                                            <td style={{ width: '10%' }}></td>
                                            <td >
                                                <label>Details</label>
                                            </td>
                                            <td >
                                                <label>Mark</label>
                                            </td>
                                            <td style={{ width: '10%' }}></td>
                                            <td>
                                                <label>Details</label>
                                            </td>
                                            <td>
                                                <label>Mark</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Quality of work</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}}/>
                                            </td>
                                            <td>8</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Customer Service</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Knowledge of work</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                            <td>9</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Personal Grooming</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>3</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Communication Skills</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                            <td>10</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Interpersonal Relations</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>4</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Attendance/Punctuality</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                            <td>11*</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Problem analysis and decision making</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Dependability</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                            <td>12*</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Leadership skills</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Team work</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                            <td>13*</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Competition Info (market knowledge)</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Initiative</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                            <td>14*</td>
                                            <td style={{ textAlign: 'left' }}>
                                                <label>Achieving Targets (Sales/Task)</label>
                                            </td>
                                            <td>
                                                <input type="text" style={{border:'none'}} />
                                            </td>
                                        </tr>
                                    </table>


                                </td>
                            </tr>
                        </table>
                        <div className=" col-md-12" style={{ paddingTop: '5px', paddingBottom: '5px', marginBottom: '2px', border: '1px solid', fontSize: 'small', marginTop: '2px' }}>
                            <label>Total Marks:</label>
                            <input style={{ marginLeft: '25px', width: '80%', borderBottom: '1px solid gray' }} type="text" />
                        </div>
                        <div className="row form-fields" style={{ marginTop: '2px', fontSize: 'small' }}>
                            <div className="col-md-4 ">
                                <label>Rating (as per evaluation form):</label>
                            </div>
                            <div className="col-md-8 checkbox-group" style={{ display: 'flex', justifyContent: 'center' }}>
                                <label>
                                    <input
                                        type="checkbox"
                                    />
                                    Poor
                                </label>
                                <label style={{ marginLeft: '30px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    Average
                                </label>
                                <label style={{ marginLeft: '30px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    Good
                                </label>
                                <label style={{ marginLeft: '30px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    Very Good
                                </label>
                                <label style={{ marginLeft: '30px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    Excellent
                                </label>
                            </div>
                        </div>
                        <div className=" col-md-12" style={{ paddingLeft: '0', fontSize: 'small' }}>
                            <label>Comments (if any):</label>
                            <input style={{ marginLeft: '25px', width: '70%' }} type="text" />
                        </div>
                        <br></br>
                        <div className='row' style={{ marginTop: '10px', fontSize: 'small' }}>
                            <div className='col-md-2'></div>
                            <div className="col-md-8 checkbox-group" style={{ display: 'flex', justifyContent: 'center', border: '1px solid' }}>
                                <label>
                                    <input
                                        type="checkbox"
                                    />
                                    Recommend
                                </label>
                                <label style={{ marginLeft: '30px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    Do Not Recommend
                                </label>
                            </div>
                        </div>

                        <div className='row' style={{ marginBottom: '5px', fontSize: 'small' }}>
                            <div className='manager-sign col-md-3'>
                                <div style={{ width: '200px' }}>
                                    <br></br>
                                    <hr className='manager-hr' style={{ width: '100%' }}></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder', fontSize: 'small' }}>Divisional Head</h4>

                                </div>
                            </div>
                            <div className='manager-sign col-md-3'>
                                <div >
                                    <br></br>
                                    <hr className='manager-hr'></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder', fontSize: 'small' }}>Chief Financial Officer</h4>

                                </div>
                            </div>
                            <div className='manager-sign col-md-3'>
                                <div >
                                    <br></br>
                                    <hr className='manager-hr'></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder', fontSize: 'small' }}>Chief HR Officer</h4>

                                </div>
                            </div>
                            <div className='manager-sign col-md-3'>
                                <div >
                                    <br></br>
                                    <hr className='manager-hr'></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder', fontSize: 'small' }}>CEO/Board Member</h4>

                                </div>
                            </div>
                        </div>

                        <div className='note' style={{ fontSize: 'small', padding: '0', paddingLeft: '5px' }}>
                            <h6><strong>Weightage:</strong></h6>
                            <h6>1. Hospitality Service Staff – Sr. no. 1 to 4 &amp; 8 together 50% for remaining 50%</h6>
                            <h6>2. Retail Sales &amp; Marketing Staff – Sr. no. 3, 8 &amp; 14 together 70% for remaining 30%</h6>
                            <h6>3. Management Staff – Sr. no. 3, 5, 11, 12 - 40%. Sr. no. 1 &amp; 2 - 30% for remaining 30% (non-sales target holders)</h6>
                            <h6>4. Management Staff – Sr. No.3, 5, 11, 12, 14 - 60%, remaining 40% (sales target holders)</h6>
                        </div>

                    </div>
                    <h5 style={{ fontWeight: 'bolder' }}>HR Form No: 08</h5>


                </div>
                <div className="button-group">
                    <button className="download-button" onClick={handleDownload}>
                        Download
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EmployeeProbationaryEvaluation;
