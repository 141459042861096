import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';
import { DatePicker, FloatButton, Input, Tooltip, message } from 'antd';
import Navbar from '../components/Navbar';
import dayjs from 'dayjs';

const { TextArea } = Input;

const LoanRequest = () => {
    const { ecode } = useParams();

    const [logoComponent, setLogoComponent] = useState(null);
    const [formData, setFormData] = useState({
        employeeName: '',
        eCode: ecode,
        startingDate: null,
    });

	useEffect(() => {
        if (ecode)
            fetchEmployeeInfo(ecode);
		fetchAndDisplayLogo().then((logo) => {
			setLogoComponent(logo);
		});
	}, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };


    const fetchEmployeeInfo = async (employeeCode) => {
        // console.log(employeeCode)
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getByECode`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ eCodes: employeeCode }),
			});

			if (!response.ok) {
                message.error('Failed to fetch employee information');
                return;
				// throw new Error('Failed to fetch employee information');
			}

			const employeeInfo = await response.json(); // This line extracts the body of the response
			// console.log(employeeInfo)
            if (employeeInfo.length > 0)
			    setFormData({...employeeInfo[0], startingDate: dayjs(employeeInfo[0].startingDate)});
		} catch (error) {
			console.error(error);
		}
	};

    const handleDownload = async () => {
        const pdfElement = document.getElementById('section-to-print');

        if (!pdfElement) {
            console.error('PDF element not found.');
            return;
        }

        const options = {
            margin: 10,
            filename: 'loan-request.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(pdfElement).set(options).save();
    };

    return (
        <>
        <div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
            <Navbar />
        </div>
        <div className="center-container" style={{ paddingTop: 120 }}>
                <div className="disciplinary-action-form">
                    <div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
                        <div className='form-container' id='section-to-print' style={{ border: '1px solid black', padding:'10px' }}>
                            <div style={{ position: 'absolute' }}>{logoComponent}</div>
                            <h3 style={{ fontWeight: 'bolder', border: 'none', marginBottom: '0px' }}>Loan Application Form</h3>
                            <h4 style={{ fontWeight: 'bolder', textAlign: 'center' }} >HR/Admin Department</h4><br />
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '30px' }}></hr>
                            <div className="form-fields d-flex" style={{flexWrap: 'wrap', rowGap: 10}}>
                                <div className="w-50">
                                    <label>Employee Name:</label>
                                    <TextArea name='employeeName' onChange={handleChange} value={formData.employeeName} autoSize style={{ marginLeft: '30px', width: '50%', borderRadius: 0 }} />
                                </div>
                                <div className="w-50">
                                    <label>Code:</label>
                                    <Input onPressEnter={() => fetchEmployeeInfo(formData.eCode)} name='eCode' onChange={handleChange} value={formData.eCode} style={{ marginLeft: '90px', width: '50%', borderRadius: 0, textAlign: 'center' }} type="text" />
                                </div>
                                <div className="w-50">
                                    <label>QID Number:</label>
                                    <TextArea value={formData.qidNumber} onChange={handleChange} name='qidNumber' autoSize style={{ marginLeft: '61px', width: '50%', borderRadius: 0 }} type="text" />
                                </div>
                                <div className="w-50">
                                    <label>Date of Joining:</label>
                                    <DatePicker name='startingDate' value={formData.startingDate} onChange={(date) => setFormData({ ...formData, startingDate: date })} style={{ marginLeft: '20px', width: '50%' }} />
                                </div>
                            </div>
                            <br></br>
                            <h4 style={{ fontWeight: 'bolder' }}>Section I: Details</h4>
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                            {/* <br></br> */}
                            <div className="d-flex flex-wrap  form-fields salary-fields">
                                <div >
                                    <label>Monthly Salary:</label>
                                    <label >QR.</label>
                                    <input type="text" />
                                </div>
                                <div>
                                    <label >Amount Requested: Qr.</label>
                                    <input type="text" />
                                </div>
                                <div>
                                    <label>Repayment Details:</label>
                                    <TextArea autoSize style={{ borderRadius: 0 }} type="text" />
                                </div>
                                <div>
                                    <label>Last Loan Availed (if any):</label>
                                    <TextArea  autoSize style={{ borderRadius: 0 }} type="text" />
                                </div>
                            </div>
                            <br></br>
                            <h4 style={{ fontWeight: 'bolder' }}>Section II: For HR Use Only</h4>
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                            <br></br>
                            <div className="form-fields" style={{height: '350px'}}>
                                <div className="d-flex ">
                                    <label>Last Loan Repaid Details(if any):</label>
                                    <TextArea autoSize type="text" style={{ marginLeft: '10px', width: '62%', borderRadius: 0 }} />
                                </div>
                                {/* <br></br>
                                <br></br> */}
                                <div className="mt-3 d-flex">
                                    <label>Comments:</label>
                                    <TextArea autoSize type="text" style={{ marginLeft: '10px', width: '85%', borderRadius: 0 }} />
                                </div>
                            </div>
                            {/* <br></br>
                            <br></br> */}
                            <div className='d-flex mb-4'>
                                <div className='manager-sign w-50'>
                                    <div style={{ width: '200px' }}>
                                        {/* <br></br> */}
                                        <hr className='manager-hr' style={{ width: '100%' }}></hr>
                                        <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>Employee</h4>

                                    </div>
                                </div>
                                <div className='manager-sign w-50'>
                                    <div >
                                        {/* <br></br> */}
                                        <hr className='manager-hr'></hr>
                                        <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>Operations Manager</h4>

                                    </div>
                                </div>
                            </div>
                            {/* <br></br> */}
                            <div className='note' style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <h4><strong>Note:</strong> Applicant should successfully complete 1 year of continuous service.</h4>
                            </div>
                        </div>
                        {/* <h4 style={{ fontWeight: 'bolder' }}>HR Form No: 11</h4> */}
                    </div>
                    {/* <Tooltip title="Print" placement='right'>
                        <FloatButton
                            icon={<PrinterOutlined />}
                            onClick={() => window.print()}
                            style={{
                                right: 100,
                            }}
                        />
                    </Tooltip> */}
                    <Tooltip title="Download" placement='top'>
                        <FloatButton
                            icon={<DownloadOutlined />}
                            onClick={handleDownload}
                            style={{
                                right: 150,
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
    </>
    );
};

export default LoanRequest;
