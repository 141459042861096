import React, { useEffect, useState } from 'react';
import { Form, Input, Select, DatePicker, Row, Col } from 'antd';import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';

const ExitInterview = () => {
    const [logoComponent, setLogoComponent] = useState(null);

	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);

    const handleDownload = async () => {
        const pdfElement = document.querySelector('.local-leave-container');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'exit-interview.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
    };

    return (
        <div className="center-container">
            <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p>
            <div className="disciplinary-action-form">
                <div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
                    <div className='form-container'>
                    <div style={{position:'absolute'}}>{logoComponent}</div><br/>
                        <h3 style={{ fontWeight: 'bolder', border: 'none', marginBottom: '0' }}>Exit Interview Form</h3>
                        <h4 style={{ fontWeight: 'bolder', textAlign: 'center' }} >HR/Admin Department</h4>
                        <hr className='hr-line' style={{ marginTop: '20px', marginBottom: '20px' }}></hr>
                        <div className="row form-fields">
                            <div className="col-md-6  ">
                                <label>Employee Name:</label>
                                <input style={{ marginLeft: '20px', width: '50%' }} type="text" />
                            </div>
                            <div className="col-md-6  ">
                                <label>Employee Code:</label>
                                <input style={{ marginLeft: '28px', width: '50%' }} type="text" />
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-6  ">
                                <label>Department:</label>
                                <input style={{ marginLeft: '50px', width: '50%' }} type="text" />
                            </div>
                            <div className="col-md-6  ">
                                <label>Position:</label>
                                <input style={{ marginLeft: '85px', width: '50%' }} type="text" />
                            </div>
                        </div>
                        <br></br>
                        <h4 style={{ fontWeight: 'bolder' }}>Section I: Exit Details</h4>

                        <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                        <br></br>
                        <div className="row  ">
                            <div className="col-md-6  ">
                                <label>Joining Date:</label>
                                <DatePicker style={{ marginLeft: '90px', width: '39%' }} ></DatePicker>
                            </div>
                            <div className="col-md-6  ">
                                <label>Relieving Date:</label>
                                <DatePicker style={{ marginLeft: '72px', width: '40%' }}/>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-6  ">
                                <label>Joining Position:</label>
                                <input style={{ marginLeft: '67px', width: '39%' }} type="text" />
                            </div>
                            <div className="col-md-6  ">
                                <label>Relieving Position:</label>
                                <input style={{ marginLeft: '50px', width: '40%' }} type="text" />
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-6  ">
                                <label>Joining Salary:</label>
                                <input style={{ marginLeft: '82px', width: '39%' }} type="text" />
                            </div>
                            <div className="col-md-6  ">
                                <label>Relieving Salary:</label>
                                <input style={{ marginLeft: '65px', width: '40%' }} type="text" />
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-6  ">
                                <label>Residence Expiry Date:</label>
                                <DatePicker style={{ marginLeft: '20px', width: '39%' }} />
                            </div>
                            <div className="col-md-6  ">
                                <label>Tentative Travel Date:</label>
                                <DatePicker style={{ marginLeft: '30px', width: '39%' }} />
                            </div>
                            <br></br>
                            <br></br>
                            <div className="row">
                                <label className='col-md-4' style={{ paddingLeft: '28px', marginRight: '0px' }}>Reason for Leaving:</label>
                                <span contentEditable className="textarea col-md-8" role="textbox" style={{ minWidth: '65%', borderBottom: '1px solid black', marginLeft: '-18px' }} />
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-4  ">
                                <label>Do you consider him/her for Re-Hire:</label>
                            </div>
                            <div className="col-md-4 checkbox-group">
                                <label>
                                    <input
                                        type="checkbox"
                                    />
                                    Yes
                                </label>
                                <label style={{ marginLeft: '20px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    No
                                </label>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-12  ">
                                <div className='row'>
                                    <label className="col-md-4">If Yes, Please state with reason:</label>
                                    <input className="col-md-8" style={{ marginLeft: '-16px', width: '67%' }} type="text" />
                                </div>
                            </div>
                        </div>

                        <div className='row' style={{ paddingTop: '100px', paddingBottom: '100px' }}>
                            <div className='manager-sign col-md-6'>
                                <div style={{ width: '200px' }}>
                                    <br></br>
                                    <hr className='manager-hr' style={{ width: '100%' }}></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>Assistant HR Manager Signature</h4>

                                </div>
                            </div>
                            <div className='manager-sign col-md-6'>
                                <div >
                                    <br></br>
                                    <hr className='manager-hr'></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>Chief HR Officer Signature</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 style={{ fontWeight: 'bolder' }}>HR Form No: 04</h4>
                </div>
                <div className="button-group">
                    <button className="download-button" onClick={handleDownload}>
                        Download
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ExitInterview;
