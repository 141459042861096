import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, message, Modal, Form, Input, Popconfirm, Card, Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Navbar from './components/Navbar';

const ManagerCompanies = () => {
	const [companies, setCompanies] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();


	useEffect(() => {
		fetchCompanies();
	}, []);
	const fetchCompanies = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/companies', {
				headers: {
					'Authorization': 'Bearer ' + token,
				},
			});
			const responseText = await response.text();
			// console.log("Response text:", responseText);
			try {
				const data = JSON.parse(responseText);
				if (response.ok) {
					setCompanies(data.companies);
				} else {
					message.error(data.error || 'Failed to fetch companies');
				}
			} catch (error) {
				console.error('Failed to parse response text:', responseText);
				message.error('Failed to fetch companies');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch companies');
		}
	};


	const createCompany = async () => {
		const companyName = form.getFieldValue('companyName');

		try {

			const response = await fetch('https://hrms-5u7j.onrender.com/manager/createCompany', {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('token'),
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ companyName }),

			});

			if (response.ok) {
				message.success('Company created successfully');
				fetchCompanies();
				setIsModalVisible(false);
			} else {
				const data = await response.json();
				if (response.status === 401) {
					message.error('Unauthorized, cannot create company');
					return;
				}
				message.error(data.error || 'Failed to create company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to create company');
		}
	};

	const deleteCompany = async (e, companyName) => {
		e.stopPropagation();
		e.preventDefault();
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/companies/${encodeURIComponent(companyName)}`, {
				method: 'DELETE',
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('token'),
					'Content-Type': 'application/json',
				},
			});

			if (response.ok) {
				message.success('Company deleted successfully');
				setCompanies(companies.filter((company) => company.companyName !== companyName));
			} else {
				const data = await response.json();
				if (response.status === 401) {
					message.error('Unauthorized, cannot delete company');
					return;
				}
				message.error(data.error || 'Failed to delete company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to delete company');
		}
	};

	const columns = [
		{
			title: 'Company Name',
			dataIndex: 'companyName',
			key: 'companyName',
			render: (text) => <Link style={{ textDecoration: 'none' }} to={`/managerAdmins/${encodeURIComponent(text)}`}>{text}</Link>,
			width: '30vw'
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<Popconfirm
					title="Delete the Company"
					description="Are you sure to delete this company?"
					onConfirm={() => deleteCompany(record.companyName)}
					okText="Yes"
					cancelText="No"
				>
					<Button danger>Delete</Button>
				</Popconfirm>
			),
		},
	];

	return (
		<>
			{/* <Navbar /> */}
			<div className='flex-fill'>
				<div className='px-5 pt-2'>
					<div>
						<Button className='create-button w-25' type="primary" onClick={() => setIsModalVisible(true)}>
							Create Company
						</Button>
						<br /><br />
						<Row gutter={[16, 16]}>
							{companies.map((company) => (
								<Col span={6} key={company.companyName}>
									<Link style={{textDecoration: 'none'}} to={`/managerAdmins/${encodeURIComponent(company.companyName)}`}>
										<Card hoverable className='company-card-container'>
											<div className="company-card">
												<p>{company.companyName}</p>
												<Popconfirm
													title="Delete the Company"
													description="Are you sure to delete this company?"
													onConfirm={(e) => deleteCompany(e, company.companyName)}
													onCancel={(e) => e.preventDefault()}
													okText="Yes"
													cancelText="No"
												>
													<Button danger icon={<DeleteOutlined />} onClick={(e) => e.preventDefault()} />
												</Popconfirm>
											</div>
										</Card>
									</Link>
								</Col>
							))}
						</Row>
					</div>

					<Modal
						title="Create Company"
						visible={isModalVisible}
						onCancel={() => setIsModalVisible(false)}
						footer={null}
					>
						<Form form={form} onFinish={createCompany}>
							<Form.Item
								name="companyName"
								rules={[{ required: true, message: 'Please enter the company name' }]}
							>
								<Input placeholder="Company Name" />
							</Form.Item>
							<Button type="primary" htmlType="submit">
								Create
							</Button>
						</Form>
					</Modal>
				</div>
				{/* <img src='/crootive.png' alt='Crootive' className='smalllogo-image' /> */}
				{/* <p className='home-header' style={{ top: '40px', left: '20px' }}>
				<Link to='/manager-router' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
				Manage Companies
			</p> */}
			</div>
		</>
	);
};

export default ManagerCompanies;
