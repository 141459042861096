import { HomeFilled } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Settings = () => {
  const [logoFile, setLogoFile] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const handleFileChange = (e) => {
    setLogoFile(e.target.files[0]);
  };

  const handleUploadLogo = async () => {
    try {
      const token = localStorage.getItem('token');

      let currentCompanyId = companyId;

      if (!currentCompanyId) {
        const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompanyAtt', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        currentCompanyId = data.company?._id;

        if (currentCompanyId) {
          setCompanyId(currentCompanyId);
        } else {
          message.error('Failed to fetch company ID');
          return;
        }
      }

      const formData = new FormData();
      formData.append('logo', logoFile);

      const response = await fetch(`https://hrms-5u7j.onrender.com/admin/${currentCompanyId}/addCompanyLogo`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        message.success('Logo uploaded successfully');
      } else {
        message.error('Failed to upload logo');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to upload logo');
    }
  };

  const handleUpdatePassword = async () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      message.error('Please fill in all password fields');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      message.error('New passwords do not match');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const adminEmail = localStorage.getItem('adminEmail');
      const requestData = { adminEmail, currentPassword, newPassword };

      // Log the data being sent
      // // console.log('Data being sent:', requestData);

      const response = await fetch('https://hrms-5u7j.onrender.com/admin/update-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        message.success('Password updated successfully');
        setShowPasswordFields(false);
      } else {
        const data = await response.json();
        message.error(`Failed to update password: ${data.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to update password');
    }
  };

  return (
    <div>
      <p className='home-header' style={{ top: '40px', left: '20px' }}>
        <Link to='/admin' style={{ color: '#11686D', paddingRight: '3vw' }}>
          <HomeFilled />
        </Link>
        Settings
      </p>
      {/* <Form style={{ marginTop: '15vh', marginLeft: '7vw' }} layout="vertical">
        <Form.Item label="Attribute Name">
          <Input style={{ width: '40%', backgroundColor: '#EEEE' }} value={attributeName} onChange={(e) => setAttributeName(e.target.value)} />
        </Form.Item>
        <Button style={{ backgroundColor: ' #5dcc4c', fontFamily: 'Montserrat', padding: '0 3vw' }} size='large' type="primary" onClick={handleAddAttribute}>
          Add Attribute
        </Button>
      </Form> */}

      <Form style={{ marginTop: '15vh', marginLeft: '7vw' }} layout="vertical">
        <Form.Item label="Upload Company Logo">
          <Input type="file" onChange={handleFileChange} style={{ width: '40%', backgroundColor: '#EEEE' }} />
        </Form.Item>
        <Button style={{ backgroundColor: ' #5dcc4c', fontFamily: 'Montserrat', padding: '0 3vw' }} size='large' type="primary" onClick={handleUploadLogo}>
          Upload Logo
        </Button>
      </Form>

      <Button style={{ marginTop: '15vh', marginLeft: '7vw', backgroundColor: ' #5dcc4c', fontFamily: 'Montserrat', padding: '0 3vw' }} size='large' type="primary" onClick={() => setShowPasswordFields(!showPasswordFields)}>
        Update Password
      </Button>

      {showPasswordFields && (
        <Form style={{ marginTop: '15vh', marginLeft: '7vw' }} layout="vertical">
          <Form.Item label="Current Password">
            <Input.Password style={{ width: '40%', backgroundColor: '#EEEE' }} value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
          </Form.Item>
          <Form.Item label="New Password">
            <Input.Password style={{ width: '40%', backgroundColor: '#EEEE' }} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          </Form.Item>
          <Form.Item label="Confirm New Password">
            <Input.Password style={{ width: '40%', backgroundColor: '#EEEE' }} value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
          </Form.Item>
          <Button style={{ backgroundColor: ' #5dcc4c', fontFamily: 'Montserrat', padding: '0 3vw' }} size='large' type="primary" onClick={handleUpdatePassword}>
            Update Password
          </Button>
        </Form>
      )}
    </div>
  );
};

export default Settings;
