import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { message, Timeline, ConfigProvider } from 'antd';
import moment from 'moment';

const EmployeeTimeline = () => {
	const { id } = useParams();
	const [employeeTimeline, setEmployeeTimeline] = useState([]);
	useEffect(() => {
		const fetchEmployeeTimeline = async () => {
			try {
				const token = localStorage.getItem('token');
				const response = await fetch(`https://hrms-5u7j.onrender.com/manager/${id}/timeline`, {
					headers: {
						Authorization: 'Bearer ' + token,
					},
				});
				const data = await response.json();

				if (response.ok) {
					setEmployeeTimeline(data.timeline);
				} else {
					message.error(data.message || 'Failed to fetch employee timeline');
				}
			} catch (error) {
				console.error('Error:', error);
				message.error('Failed to fetch employee timeline');
			}
		};

		fetchEmployeeTimeline();
	}, [id]);


	return (
		<div className='p-5' style={{margin: '0 auto'}}>
			<ConfigProvider theme={{
				components: {
					Timeline: {
						dotBorderWidth: 1
					}
				}
			}}>
				<Timeline mode="alternate">
					{employeeTimeline.map((event, index) => (
						// React code
						<Timeline.Item key={index} color={event.type === 'positive' ? 'green' : event.type === 'Action' ? 'blue' : 'red'}>
							{moment(event.date).format('YYYY-MM-DD')} - {event.description}
						</Timeline.Item>

					))}
				</Timeline>
			</ConfigProvider>
		</div>
	);
};

export default EmployeeTimeline;
