import React, { useEffect, useState } from 'react';
import './SickLeave.css';
import html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';

const EmployeeEvaluation = () => {
    const [logoComponent, setLogoComponent] = useState(null);

	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);

    const handleDownload = async () => {
        const pdfElement = document.querySelector('.local-leave-container');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'employee-evaluation-form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
    };

    return (
        <div className="center-container">
            <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p>
            <div className="disciplinary-action-form">
                <div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
                    <div className='form-container'>
                    <div style={{position:'absolute'}}>{logoComponent}</div>
                        <h3 style={{ fontWeight: 'bolder', border: 'none', marginBottom: '0' }}>Employee Evaluation Form</h3>
                        <h4 style={{ fontWeight: 'bolder', textAlign: 'center' }}>HR/Admin Department</h4>
                        <hr className='hr-line' style={{ marginBottom: '30px', marginTop: '30px' }}></hr>
                        <div className="row form-fields">
                            <div className="col-md-6 ">
                                <label>Employee Name:</label>
                                <input style={{ marginLeft: '20px', width: '50%' }} type="text" />
                            </div>
                            <div className="col-md-6">
                                <label>Employee Code:</label>
                                <input style={{ marginLeft: '28px', width: '50%' }} type="text" />
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-6">
                                <label>Department:</label>
                                <input style={{ marginLeft: '50px', width: '50%' }} type="text" />
                            </div>
                            <div className="col-md-6">
                                <label>Position:</label>
                                <input style={{ marginLeft: '80px', width: '50%' }} type="text" />
                            </div>
                        </div>
                        <br></br>
                        <h4 style={{ fontWeight: 'bolder' }}>Section I: Employee Details</h4>
                        <hr className='hr-line' style={{ marginTop: '5px', marginBottom: '10px' }}></hr>
                        <br></br>
                        <div className="row form-fields">
                            <div className="col-md-6 ">
                                <label>Joining Date:</label>
                                <input style={{ marginLeft: '62px', width: '48%' }} type="text" />
                            </div>
                            <div className="col-md-6 ">
                                <label>Work Location:</label>
                                <input style={{ marginLeft: '25px', width: '50%' }} type="text" />
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-6 ">
                                <label>Total working days:</label>
                                <input style={{ marginLeft: '20px', width: '47%' }} type="text" />
                            </div>
                            <div className="col-md-6 ">
                                <label>Visa No.</label>
                                <input style={{ marginLeft: '80px', width: '50%' }} type="text" />
                            </div>
                            <br></br>
                            <br></br>
                            <div className="col-md-6  p">
                                <label>Total Hours :</label>
                                <input style={{ marginLeft: '70px', width: '46%' }} type="text" />
                            </div>
                            <div className="col-md-6 ">
                                <label>Total Orders:</label>
                                <input style={{ marginLeft: '48px', width: '50%' }} type="text" />
                            </div>
                        </div>
                        <br></br>
                        <br></br>
                        <h4 style={{ fontWeight: 'bolder' }}>Section II: Evaluation Details</h4>
                        <h4 style={{ fontWeight: 'bolder' }}>SUPERVISOR COMMENTS:</h4>
                        <textarea style={{ resize:'none', height: '100px', width: '100%', border: '1px solid black' }} type="text" />
                        <div className="row form-fields" style={{ fontSize: '', padding: '40px' }}>
                            <div className="col-md-4 ">
                                <label>Rating (as per comment section):</label>
                            </div>
                            <div className="col-md-8 checkbox-group" style={{ display: 'flex', justifyContent: 'center' }}>
                                <label>
                                    <input
                                        type="checkbox"
                                    />
                                    Poor
                                </label>
                                <label style={{ marginLeft: '30px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    Average
                                </label>
                                <label style={{ marginLeft: '30px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    Good
                                </label>
                                <label style={{ marginLeft: '30px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    Very Good
                                </label>
                                <label style={{ marginLeft: '30px' }}>
                                    <input
                                        type="checkbox"
                                    />
                                    Excellent
                                </label>
                            </div>
                        </div>
                        <table className="leave-table">
                            <tr >
                                <td style={{ padding: '20px' }}>
                                    <label style={{ fontWeight: 'bolder' }}>RECOMMEND:</label>
                                    <input type="checkbox" style={{ marginLeft: '80px', border: '3px solid', width: '10%' }} />
                                </td>
                                <td style={{ padding: '20px' }}>
                                    <label style={{ fontWeight: 'bolder' }}>DO NOT RECOMMEND:</label>
                                    <input type="checkbox" style={{ marginLeft: '80px', border: '3px solid', width: '10%' }} />
                                </td>
                            </tr>
                        </table>
                        <div className='row' style={{ marginTop: '50px', marginBottom: '100px' }}>
                            <div className='manager-sign col-md-4' >
                                <div style={{ width: '250px' }}>
                                    <br></br>
                                    <hr className='manager-hr' style={{ width: '100%' }}></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>SUPERVISOR</h4>
                                </div>
                            </div>
                            <div className='manager-sign col-md-4'>
                                <div style={{ width: '250px' }}>
                                    <br></br>
                                    <hr className='manager-hr'></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>HUMAN RESOURCE</h4>
                                </div>
                            </div>
                            <div className='manager-sign col-md-4'>
                                <div style={{ width: '250px' }}>
                                    <br></br>
                                    <hr className='manager-hr'></hr>
                                    <h4 style={{ textAlign: 'center', fontWeight: 'bolder' }}>GENERAL MANAGER/CEO</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-group">
                    <button className="download-button" onClick={handleDownload}>
                        Download
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EmployeeEvaluation;
