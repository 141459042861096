import { message } from "antd";

export const fetchAndDisplayLogo = async () => {
    const companyId = await fetchCompany();
    const logoUrl = await fetchLogo(companyId);
  
    if (logoUrl) {
      return (
        <img
          src={logoUrl}
          alt="Company Logo"
          style={{ maxWidth: '100px', maxHeight: '80px' }}
        />
      );
    }
  
    return null;
  };
  
  const fetchCompany = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompanyAtt', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      const data = await response.json();
      if (response.ok) {
        if (data.company && data.company._id) {
          return data.company._id;
        } else {
          console.error('Company ID not found in the response');
        }
      } else {
        console.error(data.error || 'Failed to fetch company');
      }
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to fetch company');
    }
  };
  
  const fetchLogo = async (companyId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://hrms-5u7j.onrender.com/admin/${companyId}/fetchCompanyLogo`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
  
      // // console.log(response)
      if (response.ok) {
        const logoBlob = await response.blob();
        const logoUrl = URL.createObjectURL(logoBlob);
        return logoUrl;
      } else {
        console.error('Failed to fetch logo: Response not OK');
      }
    } catch (error) {
      console.error('Failed to fetch logo', error);
    }
  };
  