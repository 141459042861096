import React, { useState, useEffect } from 'react';
import { Form, Input, Select, DatePicker, Row, Col, Tooltip, FloatButton, Spin } from 'antd';
import { message } from 'antd';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { SaveOutlined, DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';
import Navbar from '../components/Navbar';

const { Option } = Select;
const { TextArea } = Input;

const SickLeaveForm = () => {
	const { ecode } = useParams();
	// Initial state for the form fields
	const [formData, setFormData] = useState({
		employeeName: '',
		eCode: ecode,
		position: '',
		companyName: '',
		leaveType: '',
		leaveDurationStart: '',
		leaveDurationEnd: '',
		daysOfLeave: '',
		leaveReason: '',
		contactNumber: '',
		dateOfJoining: '',
		totalLocalLeaveConsumed: '',
	});
	const [companyName, setCompanyName] = useState(null);
	const [companyId, setCompanyId] = useState(null);
	const [leaveDuration, setLeaveDuration] = useState(null);
	const [leaveTo, setLeaveTo] = useState(null);
	const [selectedLeaveType, setSelectedLeaveType] = useState(null);
	const [logoComponent, setLogoComponent] = useState(null);
	const [isButtonVisible, setIsButtonVisible] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [managerEmail, setManagerEmail] = useState(null);

	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);


	// const fetchCompany = async () => {
	// 	const token = localStorage.getItem('token');
	// 	try {
	// 		const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
	// 			headers: {
	// 				Authorization: 'Bearer ' + token,
	// 			},
	// 		});
	// 		const data = await response.json();
	// 		if (response.ok) {
	// 			if (data.company && data.company._id) {
	// 				const companyId = data.company._id;
	// 				setCompanyName( data.company.companyName);
	// 				setCompanyId(companyId);
	// 			} else {
	// 				message.error('Company ID not found in the response');
	// 			}
	// 		} else {
	// 			message.error(data.error || 'Failed to fetch company');
	// 		}
	// 	} catch (error) {
	// 		console.error('Error:', error);
	// 		message.error('Failed to fetch company');
	// 	}
	// };
	// useEffect(() => {
	// 	fetchCompany();
	// }, []);


	useEffect(() => {
		const storedManagerEmail = localStorage.getItem('managerEmail');
		if (storedManagerEmail) {

			const extractedName = storedManagerEmail.split('@')[0];
			const capitalizedName = extractedName.charAt(0).toUpperCase() + extractedName.slice(1);
			setManagerEmail(capitalizedName);

		}
	}, []);
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};


	const fetchEmployeeInfo = async (employeeCode) => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getByECode`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ eCodes: employeeCode }),
			});

			if (!response.ok) {
				message.error('Failed to fetch employee information');
				return
				// throw new Error('Failed to fetch employee information');
			}

			const employeeInfo = await response.json(); // This line extracts the body of the response
			// console.log(employeeInfo)
			return employeeInfo;
		} catch (error) {
			console.error(error);
		}
	};


	const handleSave = async () => {
		if (isLoading)
			return;
		setIsLoading(true);
		if (!leaveDuration || !leaveTo) {
			setIsLoading(false);
			message.error('Please select leave dates');
			return;
		}
		try {
			// await fetchCompany();

			// if (!companyId) {
			// 	message.error('Failed to fetch company');
			// 	return;
			// }
			// Fetch employee information
			const employeeInfo = await fetchEmployeeInfo(formData.eCode);
			const employeeId = employeeInfo[0]._id; // Assuming the response contains _id as the employee id
			const daysLeave = calculateDaysOfLeave();
			const currentDate = moment().format('YYYY-MM-DD');
			const fdol = leaveDuration.format('YYYY-MM-DD');
			const ldol = leaveTo.format('YYYY-MM-DD');
			const action = `Applied For ${selectedLeaveType} Leave on ${currentDate}, Starting: ${fdol} to  ${ldol}`;
			const status = "SickorAccidented";
			// console.log(employeeInfo, employeeId)
			const canvas = await html2canvas(document.querySelector('.employee-sick-leave-form'));
			const imgData = canvas.toDataURL('image/png');

			// Convert base64 image data to blob
			let byteCharacters = atob(imgData.split(',')[1]);
			let byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			let byteArray = new Uint8Array(byteNumbers);
			let blob = new Blob([byteArray], { type: 'image/png' });

			// Create form data and append the necessary values
			const uploadData = new FormData(); // changed the name to uploadData to avoid the conflict
			uploadData.append('file', blob, 'employee-sick-leave-form.png');
			// uploadData.append('companyId', companyId);
			uploadData.append('employeeId', employeeId); // Add employeeId to uploadData
			uploadData.append('managerEmail', managerEmail);
			uploadData.append('action', action);
			uploadData.append('status', status)
			uploadData.append('daysOfLeave', daysLeave);
			if (leaveDuration && leaveTo) {
				uploadData.append('firstDayOfLeave', leaveDuration.format('YYYY-MM-DD'));
				uploadData.append('LastDayOfLeave', leaveTo.format('YYYY-MM-DD'));
				// console.log(leaveDuration, leaveTo)
			}
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/saveFile', {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + token,
				},
				body: uploadData, // use uploadData here
			});

			if (response.ok) {
				// console.log('File saved successfully');
				message.success("File saved successfully");
			} else {
				console.error('Failed to save the file');
				message.error("Error saving file");
			}

			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
			message.error("Error saving file");
		}
	};

	const handleDownload = async () => {
		setIsButtonVisible(false);
		const pdfElement = document.getElementById('section-to-print');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 10,
			filename: 'employee-sick-leave-form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
	};

	const calculateDaysOfLeave = () => {
		if (leaveDuration && leaveTo) {
			const duration = moment.duration(leaveTo.diff(leaveDuration));
			return duration.asDays().toFixed(0);
		}
		return '';
	};
	const handleFetchDetailsClick = async () => {
		if (formData.eCode) {
			try {
				// console.log("here");
				const employeeInfo = await fetchEmployeeInfo(formData.eCode);
				if (employeeInfo && employeeInfo.length > 0) {
					const firstEmployeeInfo = employeeInfo[0]; // Assuming there's only one employee in the response
					const company = companyName;
					// Update the form fields based on employeeInfo
					setFormData((prevFormData) => ({
						...prevFormData,
						employeeName: firstEmployeeInfo.employeeName || '',
						companyName: company || '',
						position: firstEmployeeInfo.position || '',
						// branchDepartment: firstEmployeeInfo.department || '',TODO: set departmentName instead of department_.id

						// Add other form fields here with corresponding values from employeeInfo
					}));
				} else {
					message.error('Employee information not found');
				}
			} catch (error) {
				console.error(error);
				message.error('Failed to fetch employee information');
			}
		} else {
			message.warning('Please enter an Employee Code');
		}
	};

	useEffect(() => {
		if (ecode) {
			handleFetchDetailsClick();
		}
	}, []);

	return (
		<>
			<div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
				<Navbar />
			</div>
			<div className="center-container" style={{ paddingTop: 120 }}>
				{/* <p className='home-header' style={{ position: 'fixed' }}>
				<Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
			</p> */}
				<div className="employee-sick-leave-form">
					<div className='employee-sick-leave-form-container' style={{ minHeight: '296mm', width: '210mm', padding: '50px' }}>
						<div className='form-container' id='section-to-print' style={{ fontFamily: 'Montserrat', border: '1px solid', padding: 10, height: '285mm' }}>
							<div style={{ position: 'absolute' }}>{logoComponent}</div>
							<h3 style={{ fontWeight: 'bolder', border: 'none', marginBottom: '0' }}>Employee Sick Leave Request Form</h3>
							<h4 style={{ fontWeight: 'bolder', textAlign: 'center' }} >HR/Admin Department</h4>
							<hr className='hr-line' style={{ marginBottom: '30px', marginTop: '30px' }}></hr>


							<Form layout="vertical">
								<Row gutter={16} style={{ minHeight: '40px' }}>
									<Col span={12}>
										<Form.Item >
											<div className='form-field'> Employee Name
												<TextArea autoSize name="employeeName" value={formData.employeeName} onChange={handleChange} />
											</div>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item>

											<div className='form-field'>Employee Code
												<Input onKeyDown={(e) => e.key === "Enter" && handleFetchDetailsClick()} name="eCode" value={formData.eCode} onChange={handleChange} />
											</div>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={16}>
									<Col span={12}>
										<Form.Item>
											<div className='form-field'>Position
												<TextArea autoSize name='position' value={formData.position} onChange={handleChange} />
											</div>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Form.Item>
											<div className='form-field'>Company Name

												<TextArea autoSize name="companyName" value={formData.companyName} onChange={handleChange} />
											</div>
										</Form.Item>
									</Col>
								</Row>
							</Form>
							<h4><strong>Section I: Leave Details</strong></h4><br />

							<Form.Item>
								<div className='form-field'>Type of Leave:
									<Select style={{ width: '70%' }} onChange={value => setSelectedLeaveType(value)}>
										<Option value="Sick">Sick leave</Option>
										<Option value="Accident">Accident</Option>
										<Option value="">Other</Option>
									</Select>
								</div>
							</Form.Item>
							<Row gutter={16}>
								<Col span={16}>
									<Form.Item>
										<div className='form-field'>Leave Duration
											<div className='form-field' style={{ width: '60%' }}>From:
												<DatePicker
													style={{ width: '70%' }}
													onChange={date => {
														setLeaveDuration(date); // Update state for leaveDuration
														setFormData(prevFormData => ({ ...prevFormData, leaveDuration: date })); // Update state in formData
													}}
												/>
											</div>
										</div>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item>
										<div className='form-field'>To:
											<DatePicker
												style={{ width: '80%' }}
												onChange={date => {
													setLeaveTo(date); // Update state for leaveTo
													setFormData(prevFormData => ({ ...prevFormData, leaveTo: date })); // Update state in formData
												}}
											/>
										</div>
									</Form.Item>
								</Col>
							</Row>

							<Form.Item>
								<div className='form-field'>Days of Leave:
									<Input style={{ width: '70%' }} value={calculateDaysOfLeave()} readOnly />
								</div>
							</Form.Item>

							<Form.Item>
								<div className='form-field'>Reason of the leave:
									<TextArea autoSize style={{ width: '70%', maxHeight: '100px', fontSize: 'smaller', fontWeight: '400' }} />
								</div>
							</Form.Item>
							<Form.Item >
								<div className='form-field'>Contact Number:
									<Input style={{ width: '70%' }} />
								</div>
							</Form.Item>

							<input style={{ textAlign: 'center' }} />
							<h5>Employee Signature</h5><br /><br />
							<h4><strong>Section II: For HR Use Only</strong></h4><hr style={{ borderTop: '1px solid black' }} />
							<div className="form-field" style={{ paddingRight: '0', fontSize: 'smaller' }}>
								<div className="form-field">
									Date of Joining /Re-joining:
									<input
										type="date"
										id="dateOfJoining"
										name="dateOfJoining"
										value={formData.dateOfJoining}
										onChange={handleChange}
										style={{ width: '80%' }}
										required
									/>
								</div>
								<div className="form-field">
									Total Number of Local Leave Consumed:
									<input
										type="text"
										id="totalLocalLeaveConsumed"
										name="totalLocalLeaveConsumed"
										value={formData.totalLocalLeaveConsumed}
										onChange={handleChange}
										required
									/>
								</div>
							</div><br /><br /><br />
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<input style={{ width: '50%' }} />
								<h4 style={{ fontWeight: 'bolder' }}>Approved and signed by</h4>
								<h4 style={{ fontWeight: 'bolder' }}>HR OPERATIONS MANAGER</h4>
							</div>
						</div>
					</div>
					<FloatButton
						icon={isLoading ? <Spin /> : <SaveOutlined />}
						tooltip="Save"
						onClick={handleSave}
						style={{
							right: 200,
						}}
					/>
					{/* <Tooltip title="Print" placement='right'>
						<FloatButton
							icon={<PrinterOutlined />}
							onClick={() => window.print()}
							style={{
								right: 100,
							}}
						/>
					</Tooltip> */}
					<Tooltip title="Download" placement='top'>
						<FloatButton
							icon={<DownloadOutlined />}
							onClick={handleDownload}
							style={{
								right: 150,
							}}
						/>
					</Tooltip>
				</div>
			</div>
		</>
	);
};

export default SickLeaveForm;
