import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteOutlined, UpSquareOutlined, ExportOutlined, DownSquareOutlined, StopOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row, Tooltip, message } from 'antd';

const FileCard = ({ title, onView, onUpload, type }) => {
	const handleFileSelect = (event) => {
		const files = Array.from(event.target.files);
		event.target.value = null;
		if (files.length > 0)
			onUpload(files, type);
	}

	const downloadFile = (filename) => {
		if (!filename) {
			message.error(title + ' not found');
			return
		}
		const link = document.createElement('a');
		link.href = `https://hrms-5u7j.onrender.com/admin/uploads/${filename}?action=download`;
		link.download = filename; // This will be respected if the server has the correct headers
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Card hoverable>
			<input type="file" id={`file-input-${type}`} onChange={handleFileSelect} style={{ display: 'none' }} multiple={type === 6} />
			<h5>{title}</h5>
			{
				type !== 6 && onView.length === 0 &&
				<StopOutlined style={{position: 'absolute', top: '10px', right: '10px'}}/>
			}
			<div className='file-buttons'>
				<Tooltip title='Upload'>
					<UpSquareOutlined onClick={() => document.getElementById(`file-input-${type}`).click()} style={{ fontSize: 30 }} />
				</Tooltip>
				<Tooltip title='View'>
					{type === 6 ?
						<ExportOutlined onClick={onView} style={{ fontSize: 30 }} />
						:
						<div>
							{onView.length > 0 ?
								<a
									href={`https://hrms-5u7j.onrender.com/admin/uploads/${onView[0]?.filename}`}
									target='_blank'
									style={{ color: 'black' }}
								>
									<ExportOutlined style={{ fontSize: 30 }} />
								</a>
								:
								<ExportOutlined style={{ fontSize: 30 }} />
							}
						</div>
					}
				</Tooltip>
				{type !== 6 &&
					<Tooltip title='Download'>
						<DownSquareOutlined onClick={() => downloadFile(onView[0]?.filename)} style={{ fontSize: 30 }} />
					</Tooltip>
				}
			</div>
		</Card>
	)
}

const EmployeeFiles = () => {
	const [personalFiles, setPersonalFiles] = useState({ 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] });
	const [employeeForms, setEmployeeForms] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [otherModal, setOtherModal] = useState(false);
	const [companyId, setCompanyId] = useState(null);
	const { id } = useParams();
	const employeeId = id;

	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				if (data.company && data.company._id) {
					const companyId = data.company._id;
					setCompanyId(companyId);
				} else {
					message.error('Company ID not found in the response');
				}
			} else {
				message.error(data.error || 'Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};

	useEffect(() => {
		fetchCompany();
		fetchEmployeeFiles();
		fetchEmployeeForms();
	}, [employeeId]);
	const fetchEmployeeFiles = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getEmployeePersonalFiles/${employeeId}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch employee files');
				// throw new Error('Failed to fetch employee files');
				return
			}

			const data = await response.json();
			const options = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] };
			for (const file of data) {
				options[file.type ? file.type : 6].push(file);
			}
			setPersonalFiles(options);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const fetchEmployeeForms = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getEmployeeFormFiles/${employeeId}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch employee forms');
				return;
				// throw new Error('Failed to fetch employee forms');
			}

			const data = await response.json();
			setEmployeeForms(data);
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleFileUpload = async (files, type) => {
		try {
			console.log(files, type);
			if (files.length === 0) {
				return;
			}
			const formData = new FormData();
			message.loading('Uploading files...');
			// formData.append('employeeId', employeeId);

			for (const file of files) {
				formData.append('files', file);
			}
			formData.append('type', type);
			// console.log(employeeId);

			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/saveEmployeePersonalFiles/${employeeId}`, {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + token,
				},
				body: formData,
			});

			if (response.ok) {
				// Successfully saved files, you can perform any necessary actions here
				message.success('Files uploaded successfully');
				// Refresh the personal files section after successful upload
				fetchEmployeeFiles();
			} else {
				console.error('Failed to save files');
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};
	const confirmDeleteFile = (fileId, filename) => {
		Modal.confirm({
			title: 'Are you sure you want to delete this file?',
			content: `This will permanently delete the file.`,
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk() {
				handleDeleteFile(employeeId, fileId, filename); // We're now also passing employeeId to handleDeleteFile
			},
			onCancel() {
				// console.log('Cancelled');
			},
		});
	};


	const handleDeleteFile = async (employeeId, fileId, filename) => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/deleteEmpPersonalFile/${employeeId}/${fileId}`, {
				method: 'DELETE',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (response.ok) {
				message.success(`File ${filename} deleted successfully`);
				fetchEmployeeFiles();
				fetchEmployeeForms();
			} else {
				message.error('Failed to delete the file');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('An error occurred while deleting the file');
		}
	};
	const confirmDeleteForm = (fileId, filename) => {
		Modal.confirm({
			title: 'Are you sure you want to delete this file?',
			content: `Note: This will change the status to active.`,
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk() {
				handleDeleteForm(employeeId, fileId, filename); // We're now also passing employeeId to handleDeleteFile
			},
			onCancel() {
				// console.log('Cancelled');
			},
		});
	};
	const handleDeleteForm = async (employeeId, formId, formname) => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/deleteFormFile`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({
					employeeId,
					formId
				}),
			});

			if (response.ok) {
				message.success(`Form ${formname} deleted successfully`);
				// Refresh the lists after deletion
				fetchEmployeeFiles();
				fetchEmployeeForms();
			} else {
				message.error('Failed to delete the form');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('An error occurred while deleting the form');
		}
	};

	return (
		<div className='p-5' style={{ margin: '0 auto' }}>
			{/* <h2>Employee Files</h2> */}
			<div style={{ display: 'flex', height: '50vh', width: '70vw' }}>
				{/* Left Partition - Personal Files */}
				<div className='personal-files-container'>
					<h3 style={{ padding: '0', paddingBottom: '20px' }}>Personal Files</h3>

					<Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
						<Col span={12}>
							<FileCard title='Updated QID' type={1} onUpload={handleFileUpload} onView={personalFiles[1]} />
						</Col>
						<Col span={12}>
							<FileCard title='Updated Passport' type={2} onUpload={handleFileUpload} onView={personalFiles[2]} />
						</Col>
						<Col span={12}>
							<FileCard title='Updated License' type={3} onUpload={handleFileUpload} onView={personalFiles[3]} />
						</Col>
						<Col span={12}>
							<FileCard title='Updated Health Card' type={4} onUpload={handleFileUpload} onView={personalFiles[4]} />
						</Col>
						<Col span={12}>
							<FileCard title='Updated FHC' type={5} onUpload={handleFileUpload} onView={personalFiles[5]} />
						</Col>
						<Col span={12}>
							<FileCard title='Others' type={6} onUpload={handleFileUpload} onView={() => setOtherModal(true)} />
						</Col>
					</Row>

					<Modal open={otherModal} footer={null} onCancel={() => setOtherModal(false)}>
						{personalFiles[6].length > 0 ? (
							<ul style={{ height: '60%', overflow: 'auto' }}>
								{personalFiles[6].map((file) => (
									<li key={file._id}>
										<a
											href={`https://hrms-5u7j.onrender.com/admin/uploads/${file.filename}`}
											target="_blank"
											rel="noopener noreferrer"
										>
											{file.originalname}
										</a>
										<DeleteOutlined onClick={() => confirmDeleteFile(file._id, file.filename)} style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }} />
									</li>
								))}
							</ul>
						) : (
							<p>No other personal files found</p>
						)}
					</Modal>

					{/* <div style={{bottom:0, position:'absolute', display: 'flex', paddingRight: 20, alignItems: 'center', width: '100%'}}>
						
						<button style={{border: 'none', padding: '10px 20px', borderRadius: '5px'}} onClick={handleFileUpload}>Upload</button>
					</div> */}

				</div>

				{/* Right Partition - Employee Forms */}
				<div style={{ flex: 1, padding: '10px' }}>
					<h3 style={{ padding: '0', paddingBottom: '20px' }}>Employee Forms</h3>
					{Array.isArray(employeeForms) && employeeForms.length > 0 ? (
						<ul style={{ height: '60%', overflow: 'auto' }}>
							{employeeForms.map((form) => (
								<li key={form._id}>
									<a
										href={`https://hrms-5u7j.onrender.com/admin/uploads/${form.filename}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{form.originalname}
									</a>
									<DeleteOutlined onClick={() => confirmDeleteForm(form._id, form.filename)} style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }} />
								</li>
							))}
						</ul>
					) : (
						<p>No employee forms found</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default EmployeeFiles;