import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import { FloatButton, Input, Spin, Tooltip, message } from 'antd';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import './WarningLetter.css';

const { TextArea } = Input;

const WarningLetter = () => {
	const { ecode } = useParams();

	const currentDate = new Date();
	const formattedDate = currentDate.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});
	const [formData, setFormData] = useState({
		name: '',
		eCode: ecode,
		position: '',
		dateOfViolation: '',
		systemName: '',
		riderID: '',
		natureOfOffence: [],
		explanation: '',
		penalty: '',
		hrComments: '',
		hrSignature: '',
		employeeSignature: '',
		date: formattedDate
	});
	const [companyId, setCompanyId] = useState(null);
	const [isButtonVisible, setIsButtonVisible] = useState(true);
	const [managerEmail, setManagerEmail] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [writtenWarning, setWrittenWarning] = useState('Written Warning [1st, 2nd, 3rd]');
	const [salaryDeduction, setSalaryDeduction] = useState('Salary deduction 100 QAR');
	
	// const fetchCompany = async () => {
	// 	const token = localStorage.getItem('token');
	// 	try {
	// 		const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
	// 			headers: {
	// 				Authorization: 'Bearer ' + token,
	// 			},
	// 		});
	// 		const data = await response.json();
	// 		if (response.ok) {
	// 			if (data.company && data.company._id) {
	// 				const companyId = data.company._id;
	// 				setCompanyId(companyId);
	// 			} else {
	// 				message.error('Company ID not found in the response');
	// 			}
	// 		} else {
	// 			message.error(data.error || 'Failed to fetch company');
	// 		}
	// 	} catch (error) {
	// 		console.error('Error:', error);
	// 		message.error('Failed to fetch company');
	// 	}
	// };
	// useEffect(() => {
	// 	fetchCompany();
	// }, []);
	const handleChange = (e) => {
		const { name } = e.target;
		let { value } = e.target;
		if (e.target.tagName === 'SPAN')
			value = e.target.textContent;
		// console.log(value)
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};


	useEffect(() => {
		const storedManagerEmail = localStorage.getItem('managerEmail');
		if (storedManagerEmail) {

			const extractedName = storedManagerEmail.split('@')[0];
			const capitalizedName = extractedName.charAt(0).toUpperCase() + extractedName.slice(1);
			setManagerEmail(capitalizedName);

		}
	}, []);



	const handleNatureOfOffenceChange = (e) => {
		const { value, checked } = e.target;
		const updatedNatureOfOffence = checked
			? [...formData.natureOfOffence, value]
			: formData.natureOfOffence.filter((offence) => offence !== value);
		setFormData((prevFormData) => ({
			...prevFormData,
			natureOfOffence: updatedNatureOfOffence,
		}));
	};

	// Add this function to fetch the employee info
	const fetchEmployeeInfo = async (employeeCode) => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getByECode`, {
				method: 'POST',

				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ eCodes: employeeCode }),
			});

			if (!response.ok) {
				message.error('Failed to fetch employee information');
				return;
				// throw new Error('Failed to fetch employee information');
			}

			const employeeInfo = await response.json(); // This line extracts the body of the response
			// // console.log('companyId:', companyId);
			// console.log('employeeInfo.company:', employeeInfo[0].company);

			// if (companyId != employeeInfo[0].company) {
			//     // console.log('Mismatch detected. Returning null.');
			//     return null;
			// }

			console.log(employeeInfo);
			return employeeInfo;
		} catch (error) {
			console.error(error);
		}
	};
	const handleSave = async () => {
		setIsLoading(true);
		try {
			// await fetchCompany();

			// if (!companyId) {
			// 	message.error('Failed to fetch company');
			// 	return;
			// }
			// Fetch employee information
			const employeeInfo = await fetchEmployeeInfo(formData.eCode);
			const employeeId = employeeInfo[0]._id;  // Assuming the response contains _id as the employee id
			const status = employeeInfo[0].status;
			// console.log(employeeInfo);
			const action = formData.penalty;
			const canvas = await html2canvas(document.querySelector('.disciplinary-action-form'));
			const imgData = canvas.toDataURL('image/png');

			// Convert base64 image data to blob
			let byteCharacters = atob(imgData.split(',')[1]);
			let byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			let byteArray = new Uint8Array(byteNumbers);
			let blob = new Blob([byteArray], { type: 'image/png' });

			// Create form data and append the necessary values
			const uploadData = new FormData(); // changed the name to uploadData to avoid the conflict
			uploadData.append('file', blob, 'disciplinary_action_form.png');
			// uploadData.append('companyId', companyId);
			uploadData.append('employeeId', employeeId); // Add employeeId to uploadData
			uploadData.append('managerEmail', managerEmail);
			uploadData.append('status', status)
			uploadData.append('action', action);
			// uploadData.append('formData', JSON.stringify(formData));
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/saveFile', {
				method: 'POST',
				headers: {

					Authorization: 'Bearer ' + token,
				},
				body: uploadData, // use uploadData here
			});

			if (response.ok) {
				// console.log('File saved successfully');
				message.success("File saved successfully");
			} else {
				console.error('Failed to save the file');
				message.error("Error saving file");
			}
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			message.error("Error saving file");
			setIsLoading(false);
		}
	};

	const handleDownload = async () => {
		setIsButtonVisible(false); // Hide the button
		const pdfElement = document.getElementById('section-to-print');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'warning-letter.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};
		html2pdf().from(pdfElement).set(options).save();
		message.success('Form downloaded!')
	};

	const [logoComponent, setLogoComponent] = useState(null);

	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);


	const handleFetchDetailsClick = async () => {
		if (formData.eCode) {
			try {
				const employeeInfo = await fetchEmployeeInfo(formData.eCode);
				if (employeeInfo && employeeInfo.length > 0) {
					const firstEmployeeInfo = employeeInfo[0]; // Assuming there's only one employee in the response
					const currentDate = moment().format('YYYY-MM-DD'); // Format today's date
					// Update the form fields based on employeeInfo and set "Date of Violation" using moment.js
					setFormData((prevFormData) => ({
						...prevFormData,
						name: firstEmployeeInfo.employeeName || '',
						position: firstEmployeeInfo.position || '',
						dateOfViolation: currentDate, // Set it to today's date
						systemName: firstEmployeeInfo.refName || '', // refename
						riderID: firstEmployeeInfo.refNo || '', // refnum
						status: firstEmployeeInfo.status || '',
						// Add other form fields here with corresponding values from employeeInfo
					}));
				} else {
					message.error('Employee information not found');
				}
			} catch (error) {
				console.error(error);
				message.error('Failed to fetch employee information');
			}
		} else {
			message.warning('Please enter an Employee Code');
		}
	};

	useEffect(() => {
		if (ecode) {
			handleFetchDetailsClick();
		}
	}, []);

	return (
		<>
			<div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
				<Navbar/>
			</div>
			<div className="center-container" style={{paddingTop: 120}}>
				{/* <p className='home-header' style={{ position: 'fixed' }}>
				<Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
			</p> */}
				<div className="disciplinary-action-form" style={{width: '304mm'}}>
					<div className='local-leave-container' id='section-to-print'  style={{border: '1px solid', height: '275mm'}}>
						{logoComponent}
						<h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>Disciplinary Action Form</h4>
						<h6 style={{ border: '1px solid', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>SECTION 1: TO BE COMPLETED BY THE DEPARTMENT HEAD</h6>
						<h6><strong> Date: </strong>{formData.date}</h6>

						<div className="form-fields">
							<table className="form-table">
								<tbody>
									<tr>
										<td> <label>Name:</label> </td>
										<td> <TextArea autoSize name="name" value={formData.name} onChange={handleChange} /> </td>
										<td> <label>Employee Code:</label> </td>
										<td> <Input type="text" onKeyDown={(e) => e.key === "Enter" && handleFetchDetailsClick()} name="eCode" value={formData.eCode} onChange={handleChange} /> </td>
									</tr>
									<tr>
										<td> <label>Position:</label> </td>
										<td> <TextArea autoSize type="text" name="position" value={formData.position} onChange={handleChange} /> </td>
										<td> <label>Date of Violation:</label> </td>
										<td> <TextArea autoSize type="text" name="dateOfViolation" value={formData.dateOfViolation} onChange={handleChange} /> </td>
									</tr>
									<tr>
										<td> <label>System Name:</label> </td>
										<td> <TextArea autoSize type="text" name="systemName" value={formData.systemName} onChange={handleChange} /> </td>
										<td> <label>Rider ID:</label> </td>
										<td> <TextArea autoSize type="text" name="riderID" value={formData.riderID} onChange={handleChange} /> </td>
									</tr>
								</tbody>
							</table>
							<br />
							<label style={{ textDecoration: 'underline' }}>Nature of Offence (please check):</label>
							<br /><br />
							<table className="checkbox-table">
								<tr>
									<td>
										<input
											type="checkbox"
											name="natureOfOffence"
											value="Absence"
											checked={formData.natureOfOffence.includes('Absence')}
											onChange={handleNatureOfOffenceChange}
										/>
										Absence
									</td>
									<td>
										<input
											type="checkbox"
											name="natureOfOffence"
											value="Carelessness"
											checked={formData.natureOfOffence.includes('Carelessness')}
											onChange={handleNatureOfOffenceChange}
										/>
										Carelessness
									</td>
									<td>
										<input
											type="checkbox"
											name="natureOfOffence"
											value="Breaking House Rules"
											checked={formData.natureOfOffence.includes('Breaking House Rules')}
											onChange={handleNatureOfOffenceChange}
										/>
										Breaking House Rules
									</td>
								</tr>
								<tr>
									<td>
										<input
											type="checkbox"
											name="natureOfOffence"
											value="Tardiness"
											checked={formData.natureOfOffence.includes('Tardiness')}
											onChange={handleNatureOfOffenceChange}
										/>
										Tardiness
									</td>
									<td>
										<input
											type="checkbox"
											name="natureOfOffence"
											value="Insubordination"
											checked={formData.natureOfOffence.includes('Insubordination')}
											onChange={handleNatureOfOffenceChange}
										/>
										Insubordination
									</td>
									<td>
										<input
											type="checkbox"
											name="natureOfOffence"
											value="Conduct"
											checked={formData.natureOfOffence.includes('Conduct')}
											onChange={handleNatureOfOffenceChange}
										/>
										Conduct
									</td>
								</tr>
								<tr>
									<td>
										<input
											type="checkbox"
											name="natureOfOffence"
											value="Work Performance"
											checked={formData.natureOfOffence.includes('Work Performance')}
											onChange={handleNatureOfOffenceChange}
										/>
										Work Performance
									</td>
									<td>
										<input
											type="checkbox"
											name="natureOfOffence"
											value="Others"
											checked={formData.natureOfOffence.includes('Others')}
											onChange={handleNatureOfOffenceChange}
										/>
										Others:
									</td>
								</tr>
							</table>
							<br />
							<div className='d-block'>
								Explanation regarding this offense:
								<TextArea autoSize
									name="explanation"
									value={formData.explanation}
									onInput={handleChange}
									style={{ fontSize: 12 ,border: 'none', borderBottom: '1px solid black', borderRadius: 0, marginLeft: 'auto', textAlign: 'left' }}
								/>

							</div>
							<br />
							<div>
								{/* <textarea style={{ width: '480px', resize: 'none', border: 'none' }}>
									This warning letter is issued for using COD for personal matter.
									Note: This will be your last warning letter.
								</textarea> */}
								<p style={{ float: 'right' }}>Head Signature</p>
							</div>
							<br />
						</div>

						<hr />
						{/* <span style={{fontSize:'1.8rem', letterSpacing:'0.4vw'}}>*********************************************************************************</span> */}
						<h6 style={{ border: '1px solid', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>SECTION II: FOR THE USE OF HUMAN RESOURCES DEPARTMENT ONLY</h6>

						<div className="form-fields">
							<div>
								<p>1. As per the investigation on the matter, you have committed the above offence and you are hereby penalized with:</p>
								<table className="radio-table">
									<tr>
										<td>
											<input
												type="radio"
												name="penalty"
												value="Verbal Warning"
												checked={formData.penalty === 'Verbal Warning'}
												onChange={handleChange}
											/>
											Verbal Warning
										</td>
										<td>
											<input
												type="radio"
												name="penalty"
												value="Termination"
												checked={formData.penalty === 'Termination'}
												onChange={handleChange}
											/>
											Termination
										</td>
										<td className='d-flex'>
											<input
												type="radio"
												name="penalty"
												value={writtenWarning}
												checked={formData.penalty === writtenWarning}
												onChange={handleChange}
											/>
											<Input style={{fontSize: 12, border: 'none', padding: 0}} value={writtenWarning} onChange={(e) => setWrittenWarning(e.target.value)}/>
										</td>
									</tr>
									<tr>
										<td>
											<input
												type="radio"
												name="penalty"
												value="Suspension from Duty"
												checked={formData.penalty === 'Suspension from Duty'}
												onChange={handleChange}
											/>
											Suspension from Duty
										</td>
										<td>
											<input
												type="radio"
												name="penalty"
												value="Final Warning"
												checked={formData.penalty === 'Final Warning'}
												onChange={handleChange}
											/>
											Final Warning
										</td>
										<td className='d-flex'>
											<input
												type="radio"
												name="penalty"
												value={salaryDeduction}
												checked={formData.penalty === salaryDeduction}
												onChange={handleChange}
											/>
											<Input style={{fontSize: 12, border: 'none', padding: 0}} value={salaryDeduction} onChange={(e) => setSalaryDeduction(e.target.value)}/>
										</td>
									</tr>
								</table>
							</div>
							<p className='my-2'>2. Any further violation will be cause for further disciplinary action.</p>
							<div className='d-block'>
								<label>Human Resources Comments:</label>
								<TextArea autoSize
									name="hrComments"
									value={formData.hrComments}
									onInput={handleChange}
									style={{ fontSize: 12 ,border: 'none', borderBottom: '1px solid black', borderRadius: 0, marginLeft: 'auto', textAlign: 'left' }}
								/>
							</div>
							<br />
							{/* <br /><br /> */}

							<div style={{ display: 'flex', justifyContent: 'space-between' }}>
								<div className="form-group">
									<input type="text" name="hrSignature" value={formData.hrSignature} onChange={handleChange} />
									<br />
									<label>Human Resource Signature</label>
								</div>
								<div className="form-group" style={{ float: 'right' }}>
									<input type="text" name="employeeSignature" value={formData.employeeSignature} onChange={handleChange} />
									<br />
									<label>Employee's Signature</label>
								</div>
							</div>
						</div>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<p><span style={{ paddingRight: '3vw' }}>Distribution: </span>General Manager</p>
							<p>Employee's File</p>
						</div>
					</div>
				</div>
			</div>
			<FloatButton
				icon={isLoading ? <Spin/> : <SaveOutlined />}
				tooltip="Save"
				onClick={handleSave}
				style={{
					right: 200,
				}}
			/>
			{/* <Tooltip title="Print" placement='right'>
			<FloatButton
				icon={<PrinterOutlined />}
				onClick={() => window.print()}
				style={{
					right: 100,
				}}
			/> */}
			{/* </Tooltip> */}
			<Tooltip title="Download" placement='top'>
				<FloatButton
					icon={<DownloadOutlined />}
					onClick={handleDownload}
					style={{
						right: 150,
					}}
				/>
			</Tooltip>
		</>
	);
};

export default WarningLetter;
