import { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import { Button, Table, message } from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons"
import { Link } from "react-router-dom";

const ManApprovals = () => {
    const [approvals, setApprovals] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);  // Maintain the current page in the state
    const pageSize = 100;
    const router = {
        "Leave Application": "leave-approval",
        "Employee Termination": "termination-approval",
    }

    const fetchForms = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/unapprovedForms', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                setApprovals(data);
                setDataLoaded(true);
            } else {
                message.error(data.error || 'Failed to fetch forms');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch forms');
        }
    };

    useEffect(() => {
        fetchForms();
    }, []);

    const columns = [
        {
            title: 'SN',
            dataIndex: 'serial',
            key: 'serial',
            // fixed: 'left',
            render: (_, __, index) => (currentPage - 1) * pageSize + index + 1
        },
        {
            title: 'E-CODE',
            dataIndex: 'ecode',
            key: 'ecode',
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee',
            key: 'employee',
            render: (text, record) => (
                <Link to={`/manager/${router[record.name]}/${record.id}`}>{record.employee}</Link>
            ),
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
        },
        {
            title: 'Form',
            dataIndex: 'name',
            key: 'name'
        }
    ];

    return (
        <div>
            <Navbar />
            <div className="pt-5" style={{padding: '0 100px'}}>
                <Table
                    pagination={{
                        pageSize: 100,
                        current: currentPage,
                        onChange: (page) => setCurrentPage(page)
                    }}
                    dataSource={approvals}
                    loading={dataLoaded ? false : !dataLoaded}
                    columns={columns}
                    rowKey="_id"
                    scroll={{
                        x: 'max-content',
                        y: `calc(90vh - 250px)`
                    }}
                // rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                />
            </div>
        </div>
    );
}

export default ManApprovals;