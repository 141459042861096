import React from 'react';
import { Link } from 'react-router-dom';
import './Earnings.css'; // Import the CSS file for custom styling
import { HomeFilled } from '@ant-design/icons';

const ManAnalyisReportingRouter = () => {
  return (
    <div className="analysis-container">
      <div className="analysis-item">
        <Link to="reporting" className="analysis-link button-style">
             Status Analysis
        </Link>
      </div>
      <div className="analysis-item">
        <Link to="date-reports" className="analysis-link button-style">
          Date Reports
        </Link>
      </div>
      <div className="analysis-item">
        <Link to="vacation-reports" className="analysis-link button-style">
          Vacation Reports
        </Link>
      </div>
      <p className='home-header' style={{ top: '40px', left: '20px' }}>
        <Link to='/manager-router' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
        Analysis & Reporting
      </p>
    </div>
  );
};

export default ManAnalyisReportingRouter;
