import {
	LoadingOutlined
} from '@ant-design/icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const AdminLogin = () => {
	// const [form] = Form.useForm();
	const navigate = useNavigate();
	const [AE, SAE] = useState('');
	const [adminEmail, setAdminEmail] = useState('');
	const [loginPressed, setLoginPressed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [adminPassword, setAdminPassword] = useState('');

	const handleLogin = async (event) => {
		event.preventDefault();
		setLoginPressed(true);
        setIsLoading(true);
		try {
			SAE(adminEmail);
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/login', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ adminEmail, adminPassword }),
			});

			const data = await response.json();

			if (response.ok) {
				localStorage.setItem('adminEmail', adminEmail);
				const { token } = data;
				localStorage.setItem('token', token);
				navigate('/admin');
			} else {
				setIsLoading(false);
				setErrorMessage('Username or password is incorrect');
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
			}
		} catch (error) {
			setIsLoading(false);
			setErrorMessage('Login Failed');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000);
		}
	};

	const handleManagerLogin = () => {
        navigate('/');
    };

	return (
		<div className='login-container'>
			<div className='container'>
				<img src='/login.png' alt='Login' className='login-image' />
				<h2 className='heading'>Admin Login</h2>
				<form className='form'>
					<input
						className={`input-field ${(!adminEmail && loginPressed) && 'invalid-input'}`}
						type='text'
						value={adminEmail}
						onChange={e => setAdminEmail(e.target.value)}
						placeholder='username'
						required
					/>
					<input
						className={`input-field ${(!adminPassword && loginPressed) && 'invalid-input'}`}
						type='password'
						value={adminPassword}
						onChange={e => setAdminPassword(e.target.value)}
						placeholder='password'
						required
					/>
					<div className='error-message'>{errorMessage}</div>
					<button className='login-button' onClick={handleLogin}>
						{isLoading ? <LoadingOutlined /> : 'LOGIN'}
					</button>
					<button className='linkButton' onClick={handleManagerLogin}>
						Manager Login
					</button>
				</form>
			</div>
		</div>
	);
};

export default AdminLogin;
