import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Modal, Form, Input, InputNumber, Space, message, Tooltip, Select, Spin, DatePicker, Popconfirm } from 'antd';
import { MinusCircleOutlined, PlusOutlined, DownloadOutlined, PlusCircleOutlined, DeleteOutlined, EditOutlined, MinusCircleFilled, ExclamationCircleOutlined, FileAddOutlined, UserAddOutlined } from '@ant-design/icons';
import Navbar from './components/Navbar';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Papa from 'papaparse';
import { accomodationExportAttributes } from './components/Data';
const AccommodationManagement = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [accommodations, setAccommodations] = useState([]);
    const [filteredAccommodations, setFilteredAccommodations] = useState([]);
    const [form] = Form.useForm();
    const assignSampleEl = useRef()
    const sampleEl = useRef()
    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);
    const [accomodationModal, setAccomodationModal] = useState(false);
    const [selectedAccommodation, setSelectedAccommodation] = useState(null);
    const [currentRoom, setCurrentRoom] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);  // Maintain the current page in the state
    const [searchText, setSearchText] = useState('');
    const pageSize = 100;
    const [exportData, setExportData] = useState([]);
    const [isTimelineModalVisible, setIsTimelineModalVisible] = useState(false);
    const [dateRange, setDateRange] = useState([]);
    const [filteredTimelineData, setFilteredTimelineData] = useState([]);
    const { RangePicker } = DatePicker;
    const fileInputRef = useRef();
    const [importType, setImportType] = useState(null);
    const [exportConfirmed, setExportConfirmed] = useState(false);
    const csvLinkRef = useRef();
    // const url = `${process.env.REACT_APP_API_URI}`;
    // const url = 'https://hrms-5u7j.onrender.com';
    const storedManagerEmail = localStorage.getItem('managerEmail') || '';
    const url = 'https://hrms-5u7j.onrender.com';
    useEffect(() => {
        fetchAccommodations();
        fetchUnAssignedEmployees();
    }, []);
    const token = localStorage.getItem('token');
    const fetchAccommodations = async () => {
        try {
            const response = await fetch(url + '/manager/accommodations', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setAccommodations(data);
                setFilteredAccommodations(data);
                //      setExportData(data.map((accommodation) => {
                //     const totalRooms = accommodation.rooms.length;
                //     const totalOccupancy = accommodation.rooms.reduce((occupancy, room) => {
                //         return occupancy + room.assignedEmployees.length;
                //     }, 0);
                //     const totalVacancies = accommodation.totalCapacity - totalOccupancy;

                //     return {
                //         propertyName: accommodation.propertyName,             // Property Name
                //         rooms: totalRooms,                                     // Number of Rooms
                //         waterMeterNumber: accommodation.waterMeterNumber,      // Water Meter
                //         electricityMeterNumber: accommodation.electricityMeterNumber, // Electricity Meter
                //         totalCapacity: accommodation.totalCapacity,            // Total Capacity
                //         occupancy: totalOccupancy,                             // Occupancy
                //         vacancies: totalVacancies                              // Vacancies
                //     };
                // }));
            } else {
                console.error('Failed to fetch accommodations', response);
                message.error('Failed to fetch accommodations');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch accommodations');
        }
    };

    const fetchUnAssignedEmployees = async () => {
        try {
            const response = await fetch(url + '/manager/unassigned-employees', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setEmployees(data);
            } else {
                console.error('Failed to fetch employees', response);
                message.error('Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleOk = async () => {
        if (isLoading)
            return

        setIsLoading(true);
        try {
            const values = await form.validateFields();
            const { propertyName, rooms, electricityMeterNumber, waterMeterNumber } = values;

            // Calculate total capacity
            const totalCapacity = rooms.reduce((total, room) => total + (room.capacity || 0), 0);

            const payload = { propertyName, rooms, electricityMeterNumber, waterMeterNumber, totalCapacity };

            const response = await fetch(url + '/manager/create-accommodation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                message.success('Property added successfully');
                fetchAccommodations();
                handleCancel();
            } else {
                console.error(response)
                message.error('Failed to add property');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to add property');
        }
        setIsLoading(false);
    };

    const handleExportOk = () => {
        const flattened = filteredAccommodations.flatMap(property =>
            property.rooms.flatMap(room =>
                room.assignedEmployees.map(employee => ({
                    propertyName: property.propertyName,
                    room: room.roomNumber,
                    eCode: employee.eCode,
                    employeeName: employee.employeeName,
                    refNo: employee.refNo,
                    qidNumber: employee.qidNumber,
                    workNumber: employee.companyNumber,
                    nationality: employee.nationality,
                    status: employee.status
                }))
            )
        );
        setExportData(flattened);
        setExportConfirmed(true);

    };

    useEffect(() => {
        if (exportConfirmed && csvLinkRef.current) {
            csvLinkRef.current.link.click();
            setExportConfirmed(false);
        }
    }, [exportConfirmed]);

    const handleDelete = async (id) => {
        try {
            const response = await fetch(url + `/manager/accommodation/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                }
            });
            if (response.ok) {
                message.success('Accommodation deleted successfully');
                fetchAccommodations();  // Refresh the list to show the changes
            } else {
                message.error('Failed to delete accommodation');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to delete accommodation');
        }
    };
    const handlePropertyClick = (record) => {
        form.setFieldsValue({
            propertyName: record.propertyName,
            electricityMeterNumber: record.electricityMeterNumber,
            waterMeterNumber: record.waterMeterNumber,
            rooms: record.rooms.map(room => ({
                roomNumber: room.roomNumber,
                capacity: room.capacity
            }))
        });
        setSelectedAccommodation(record);
        setUpdateModalVisible(true);
    };
    const handleUpdateOk = async () => {
        if (isLoading)
            return

        setIsLoading(true);
        try {
            const values = await form.validateFields();
            // Calculate total capacity for the updated rooms
            const totalCapacity = values.rooms.reduce((total, room) => total + (room.capacity || 0), 0);

            const updatedAccommodation = {
                ...values,
                _id: selectedAccommodation._id, // Use _id as it is typically named in MongoDB documents
                totalCapacity
            };

            const response = await fetch(url + '/manager/update-accommodation', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(updatedAccommodation)
            });

            if (response.ok) {
                message.success('Property updated successfully');
                fetchAccommodations();
                setUpdateModalVisible(false);
            } else {
                const errorResponse = await response.json();
                message.error('Failed to update property: ' + errorResponse.message);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error:', error);
            message.error('Failed to update property: ' + error.message);
        }
    };

    const showAssignModal = (record) => {
        setCurrentRoom(0)
        setSelectedAccommodation(JSON.parse(JSON.stringify(record)));
        setAccomodationModal(true);
    }

    const closeAssignModal = () => {
        setSelectedAccommodation(null);
        setAccomodationModal(false);
    }

    const columns = [
        {
            title: 'Property',
            dataIndex: 'propertyName',
            key: 'propertyName',
            sorter: (a, b) => a.propertyName.localeCompare(b.propertyName), // Sorting by property name
            render: (text, record) => (
                <Link onClick={() => showAssignModal(record)}>{text}
                    {record.rooms.some(room => room.assignedEmployees.some(e => e.status != 'Active')) &&
                        <Tooltip title="Action Required! The property has assigned employees that aren't active.">
                            <ExclamationCircleOutlined style={{ color: 'red', marginLeft: '10px' }} />
                        </Tooltip>
                    }
                </Link>
            ),
        },

        {
            title: 'Rooms',
            dataIndex: 'rooms',
            key: 'rooms',
            width: 100,
            sorter: (a, b) => a.rooms.length - b.rooms.length,
            render: (rooms) => rooms.length,
        },
        {
            title: 'Water Meter',
            dataIndex: 'waterMeterNumber',
            sorter: (a, b) => a.waterMeterNumber.localeCompare(b.waterMeterNumber),
        },
        {
            title: 'Electricity Meter',
            dataIndex: 'electricityMeterNumber',
            width: 200,
            sorter: (a, b) => a.electricityMeterNumber.localeCompare(b.electricityMeterNumber), // Sorting by electricity meter number
        },
        {
            title: 'Total Capacity',
            dataIndex: 'totalCapacity',
            key: 'totalCapacity',
            sorter: (a, b) => a.totalCapacity - b.totalCapacity,
            width: 150,
            // render: (rooms) => rooms.reduce((total, room) => total + (room.capacity || 0), 0),
        },
        {
            title: 'Occupancy',
            dataIndex: 'occupancy',
            key: 'occupancy',
            width: 150,
            sorter: (a, b) => (a.rooms.reduce((total, room) => total + (room.assignedEmployees.length || 0), 0)) -
                (b.rooms.reduce((total, room) => total + (room.assignedEmployees.length || 0), 0)),
            render: (text, record) => record.rooms.reduce((total, room) => total + (room.assignedEmployees.length || 0), 0),
        },
        {
            title: 'Vacancies',
            dataIndex: 'vacancies',
            key: 'vacancies',
            width: 150,
            sorter: (a, b) => (a.totalCapacity - a.rooms.reduce((total, room) => total + (room.assignedEmployees.length || 0), 0)) -
                (b.totalCapacity - b.rooms.reduce((total, room) => total + (room.assignedEmployees.length || 0), 0)),
            render: (text, record) => record.totalCapacity - record.rooms.reduce((total, room) => total + (room.assignedEmployees.length || 0), 0),
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        title='Delete Accomodation'
                        description='Are you sure you want to delete this accomodation?'
                        onConfirm={() => handleDelete(record._id)}  // Pass the _id to the handleDelete function
                        okText='Yes'
                    >
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                        >
                        </Button>
                    </Popconfirm>
                    <Button
                        type="primary"
                        style={{ backgroundColor: 'gray' }}
                        icon={<EditOutlined />}
                        onClick={() => handlePropertyClick(record)}
                    ></Button>
                </Space>
            ),
        }

    ];

    const assignEmployee = (roomIndex, empIndex) => {
        console.log(roomIndex, empIndex);
        const updatedRooms = selectedAccommodation.rooms
        updatedRooms[roomIndex].assignedEmployees.push(employees[empIndex]);
        const updatedEmployees = employees.filter((emp, index) => index !== empIndex);
        console.log(updatedRooms, updatedEmployees);
        setEmployees(updatedEmployees);
        setSelectedAccommodation({ ...selectedAccommodation, rooms: updatedRooms });
    };

    const unassignEmployee = (roomIndex, empIndex) => {
        const employee = selectedAccommodation.rooms[roomIndex].assignedEmployees[empIndex];
        const updatedRooms = selectedAccommodation.rooms
        updatedRooms[roomIndex].assignedEmployees = updatedRooms[roomIndex].assignedEmployees.filter((emp, index) => index !== empIndex);
        setEmployees([...employees, employee]);
        setSelectedAccommodation({ ...selectedAccommodation, rooms: updatedRooms });
    };

    const saveAccomodation = async () => {
        try {
            const response = await fetch(url + '/manager/update-accommodation', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token} `,
                },
                body: JSON.stringify(selectedAccommodation)
            });

            const data = await response.json();
            if (response.ok) {
                message.success('Property updated successfully');
                setAccommodations(data);
                setFilteredAccommodations(data);
                setAccomodationModal(false);
            } else {
                message.error('Failed to update property');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to update property');
        }
    }
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchText(query);

        if (query) {
            // Filter the accommodations based on the search query
            const filtered = accommodations.filter(accommodation => {
                return (
                    accommodation.propertyName.toLowerCase().includes(query) ||
                    accommodation.rooms.some(room => room.roomNumber.toString().includes(query)) ||
                    accommodation.rooms.some(room => room.assignedEmployees.some(employee => employee.employeeName?.toLowerCase().includes(query) 
                    || employee.refNo?.toLowerCase().includes(query) || employee.eCode?.toLowerCase().includes(query) || employee.companyNumber?.toLowerCase().includes(query)
                    || employee.nationality?.toLowerCase().includes(query) || employee.status?.toLowerCase().includes(query)))
                );
            });
            setFilteredAccommodations(filtered);
        } else {
            // Reset to full list if search input is cleared
            setFilteredAccommodations(accommodations);
        }
    };

    const handleSearchEmployee = (e) => {
        const employee = employees[e] // Assuming e is employee id or index
        // console.log('employee => ',employee, e);

        if (!employee) {
            setFilteredAccommodations(accommodations);
            return;
        }      
        const employeeNationality = employee.nationality.toLowerCase();
    
        // Filter accommodations that have rooms with vacancies and employees of the same nationality
        const filtered = accommodations
            .map(accommodation => {
                // For each accommodation, calculate the number of employees of the same nationality
                let sameNationalityCount = 0;
    
                // Also, check if there are any rooms with vacancies
                const roomsWithVacancies = accommodation.rooms.filter(room => {
                    const totalEmployees = room.assignedEmployees.length;
                    const roomVacancy = room.capacity - totalEmployees > 0;
    
                    const sameNationalityInRoom = room.assignedEmployees.filter(emp => emp.nationality?.toLowerCase() === employeeNationality).length;
                    const isRoomValid = sameNationalityInRoom > 0 || totalEmployees === 0;

                    if (roomVacancy && isRoomValid) {
                        sameNationalityCount += sameNationalityInRoom;
                    }

                    return roomVacancy && isRoomValid;
                });
    
                return {
                    ...accommodation,
                    sameNationalityCount, // Total count of same-nationality employees in vacant rooms
                    roomsWithVacancies // Rooms that still have vacancies
                };
            })
            .filter(accommodation => accommodation.roomsWithVacancies.length > 0) // Only keep accommodations with vacant rooms
            .sort((a, b) => b.sameNationalityCount - a.sameNationalityCount); // Sort by most same-nationality employees
        
        setFilteredAccommodations(filtered);
    };

    const handleImportClick = (importType) => {
        if (fileInputRef.current) {
            setImportType(importType); // store the selected import type in the state
            fileInputRef.current.click();
        }
    };


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        event.target.value = null;
        if (file) {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const importedData = results.data;
                    if (importType === 'accommodations') {
                        processAccommodationImport(importedData);
                    } else if (importType === 'assign') {
                        processAssignImport(importedData);
                    }
                },
                error: (error) => {
                    message.error('Error parsing CSV file');
                },
            });
        }
    };

    // Process the imported data and send it to the backend


    const processAccommodationImport = async (importedData) => {
        try {
            // Debugging: Check the data passed to this function
            console.log("Data passed to processAccommodationImport:", importedData);

            // Group data by property name and organize rooms under each property
            const groupedData = importedData.reduce((acc, row) => {
                console.log("Processing row:", row);

                const propertyName = row['Property Name'];
                const electricityMeterNumber = row['Electricity Meter Number'];
                const waterMeterNumber = row['Water Meter Number'];

                // Validate and check if all necessary data is present
                if (!propertyName || !electricityMeterNumber || !waterMeterNumber) {
                    console.error('Row is missing required data:', row);
                    return acc; // Skip this row if required fields are missing
                }

                if (!acc[propertyName]) {
                    acc[propertyName] = {
                        propertyName: propertyName,
                        electricityMeterNumber: electricityMeterNumber,
                        waterMeterNumber: waterMeterNumber,
                        totalCapacity: 0, // Initial capacity will be calculated based on the rooms
                        rooms: []
                    };
                }

                const room = {
                    roomNumber: parseInt(row['Room Number']),
                    capacity: parseInt(row['Capacity']),
                    assignedEmployees: [] // Empty initially, to be populated later as needed
                };

                acc[propertyName].rooms.push(room);
                acc[propertyName].totalCapacity += room.capacity; // Summing up total capacity

                return acc;
            }, {});

            // Create the final payload array from grouped data
            const payload = {
                managerEmail: storedManagerEmail, // Include managerEmail
                accommodations: Object.values(groupedData) // Store grouped accommodations data
            };

            console.log("Final Payload with managerEmail:", payload); // Logging the final payload to check its content

            if (payload.accommodations.length === 0) {
                message.error('No valid data found in the uploaded file.');
                return;
            }

            // Sending the data to the server
            const response = await fetch(url + '/manager/import-accommodations-emp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(Object.values(groupedData)),
            });

            if (response.ok) {
                message.success('Accommodation data imported successfully');
                fetchAccommodations(); // Refresh the accommodations list
            } else {
                const error = await response.json();
                message.error(`Error importing data: ${error.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Error importing data');
        }
    };


    const processAssignImport = async (importedData) => {
        try {
            const data = {};

            for (const row of importedData) {
                const propertyName = row['Property Name'];
                const roomNumber = parseInt(row['Room#']);
                const employeeCode = row['Employee code'];

                // If propertyName doesn't exist in data, initialize it
                if (!data[propertyName]) {
                    data[propertyName] = {};
                }

                // If roomNumber doesn't exist under the propertyName, initialize it
                if (!data[propertyName][roomNumber]) {
                    data[propertyName][roomNumber] = [];
                }

                // Add the employeeCode to the roomNumber list
                data[propertyName][roomNumber].push(employeeCode);
            }

            const response = await fetch(url + '/manager/import-assign-emp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });
            const errors = await response.json();
            if (!response.ok) {
                message.error(`Error importing data: ${errors.message}`);
                return;
            }
            for (const error of errors.errors) {
                message.error(error);
            }
            message.success('Employee assignment imported successfully');
            fetchAccommodations(); // Refresh the accommodations list
        } catch (error) {
            message.error('Error importing data');
        }
    };


    // console.log(filteredAccommodations)

    return (
        <div>

            <Navbar />
            <div className="pt-5" style={{ padding: '0 100px' }}>
                {/* <Button type="primary" onClick={showModal} style={{ margin: '10px' }}>
                    Add Property
                </Button> */}
                <div className="d-flex justify-content-end">

                </div>
                <div className="d-flex justify-content-between w-100 gap-3 mb-3">
                    <div className="d-flex gap-3">
                        <Input.Search
                            placeholder='Search Accommodations'
                            value={searchText}
                            onChange={handleSearchChange}
                            allowClear
                            style={{ width: '500px' }}
                        />
                        <Select
                            showSearch
                            style={{ width: 300 }}
                            placeholder="Smart Assign"
                            optionFilterProp="label"
                            allowClear
                            onChange={handleSearchEmployee}
                            options={employees.map((e, index) => {
                                return { label: `${e.eCode}: ${e.employeeName}`, value: index }
                            })}
                        />
                    </div>
                    <div className="d-flex justify-content-end gap-3">

                        <Popconfirm
                            title="Download Sample File"
                            description="Do you wish to download the sample file?"
                            onConfirm={() => assignSampleEl.current?.link.click()}
                            onCancel={() => handleImportClick('assign')}
                            cancelText="Import"
                            okText="Download"
                        >
                            <Tooltip title='Assign Employees'>
                                <Button type='text' shape='circle' icon={<UserAddOutlined style={{ fontSize: 20 }} />} />
                            </Tooltip>
                        </Popconfirm>
                        <Popconfirm
                            title="Download Sample File"
                            description="Do you wish to download the sample file?"
                            onConfirm={() => sampleEl.current?.link.click()}
                            onCancel={() => handleImportClick('accommodations')}
                            cancelText="Import"
                            okText="Download"
                        >
                            <Tooltip title='Create Accommodations'>
                                <Button type='text' shape='circle' icon={<FileAddOutlined style={{ fontSize: 20 }} />} />
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip title='Export'>
                            <Button type='text' shape='circle' icon={<DownloadOutlined style={{ fontSize: 20 }} />} onClick={handleExportOk} />
                        </Tooltip>
                        <CSVLink
                            style={{ display: 'none' }}
                            id='export-accomodation-csv'
                            data={exportData}
                            headers={[
                                { label: "Property Name", key: "propertyName" },
                                { label: "Room#", key: "room" },
                                { label: "Employee Code", key: "eCode" },
                                { label: "Employee Name", key: "employeeName" },
                                { label: "Ref No", key: "refNo" },
                                { label: "QID Number", key: "qidNumber" },
                                { label: "Work Number", key: "workNumber" },
                                { label: "Nationality", key: "nationality" },
                                { label: "Status", key: "status" },
                            ]}
                            filename={'accomodation.csv'}
                            ref={csvLinkRef}
                        >
                            Download Accomodation CSV
                        </CSVLink>

                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept=".csv"
                            onChange={handleFileUpload}
                        />

                        <Tooltip title='Add Property'>
                            <Button type='text' shape='circle' icon={<PlusCircleOutlined style={{ fontSize: 20 }} />} onClick={showModal} />
                        </Tooltip>
                    </div>

                    <CSVLink
                        style={{ display: 'none' }}
                        data={[]}
                        headers={[
                            { label: "Property Name", key: "Property Name" },
                            { label: "Room#", key: "Room#" },
                            { label: "Employee Code", key: "Employee Code" },
                        ]}
                        filename="assign-accomodations-sample.csv"
                        ref={assignSampleEl}
                    />

                    <CSVLink
                        style={{ display: 'none' }}
                        data={[]}
                        headers={[
                            { label: "Property Name", key: "Property Name" },
                            { label: "Room Number", key: "Room Number" },
                            { label: "Capacity", key: "Capacity" },
                            { label: "Water Meter Number", key: "Water Meter Number" },
                            { label: "Electricity Meter Number", key: "Electricity Meter Number" }
                        ]}
                        filename="create-accomodations-sample.csv"
                        ref={sampleEl}
                    />

                </div>
                <Table
                    pagination={{
                        pageSize: 100,
                        current: currentPage,
                        onChange: (page) => setCurrentPage(page)
                    }}
                    dataSource={filteredAccommodations}
                    // loading={dataLoaded ? false : !dataLoaded}
                    columns={columns}
                    rowKey="_id"
                    scroll={{
                        x: 'max-content',
                        y: `calc(90vh - 250px)`
                    }}
                // rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
                />
            </div>

            {/* <Table columns={columns} dataSource={accommodations} /> */}

            <Modal title="Add Property" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}
                okText={isLoading ? <Spin size='small' style={{ color: 'white' }} /> : 'Save'}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="propertyName"
                        label="Property Name"
                        rules={[{ required: true, message: 'Please input the property name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="electricityMeterNumber"
                        label="Electricity Meter Number"
                        rules={[{ required: true, message: 'Please input the electricity meter number!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="waterMeterNumber"
                        label="Water Meter Number"
                        rules={[{ required: true, message: 'Please input the water meter number!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.List name="rooms" initialValue={[{ roomNumber: 1, capacity: 1 }]}
                        rules={[{ required: true, message: 'Please input room details' }]}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...field }) => (
                                    <div className='d-flex justify-content-between'>
                                        <Form.Item
                                            {...field}
                                            name={[name, 'roomNumber']}
                                            fieldKey={[field.fieldKey, 'roomNumber']}
                                            rules={[{ required: true, message: 'Missing room number' }]}
                                            label="Room Number"
                                            style={{ width: '200px' }}
                                        >
                                            <InputNumber min={1} />
                                        </Form.Item>
                                        <div className='d-flex'>
                                            <Form.Item
                                                {...field}
                                                name={[name, 'capacity']}
                                                fieldKey={[field.fieldKey, 'capacity']}
                                                rules={[{ required: true, message: 'Missing capacity' }]}
                                                label="Capacity"
                                                style={{ width: '100px' }}
                                            >
                                                <InputNumber min={1} />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </div>
                                    </div>
                                ))}
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Another Room
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
            <Modal
                title="Update Property"
                visible={updateModalVisible}
                onOk={handleUpdateOk}
                onCancel={() => { setUpdateModalVisible(false); form.resetFields(); }}
                okText={isLoading ? <Spin size='small' style={{ color: 'white' }} /> : 'Save'}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="propertyName"
                        label="Property Name"
                        rules={[{ required: true, message: 'Please input the property name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="electricityMeterNumber"
                        label="Electricity Meter Number"
                        rules={[{ required: true, message: 'Please input the electricity meter number!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="waterMeterNumber"
                        label="Water Meter Number"
                        rules={[{ required: true, message: 'Please input the water meter number!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.List
                        name="rooms"
                        rules={[{ required: true, message: 'Please input room details' }]}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...field }) => (
                                    <div className='d-flex justify-content-between'>
                                        <Form.Item
                                            {...field}
                                            name={[name, 'roomNumber']}
                                            fieldKey={[field.fieldKey, 'roomNumber']}
                                            rules={[{ required: true, message: 'Missing room number' }]}
                                            label="Room Numbers"
                                            style={{ width: '200px' }}
                                        >
                                            <InputNumber min={1} />
                                        </Form.Item>
                                        <div className='d-flex'>
                                            <Form.Item
                                                {...field}
                                                name={[name, 'capacity']}
                                                fieldKey={[field.fieldKey, 'capacity']}
                                                rules={[{ required: true, message: 'Missing capacity' }]}
                                                label="Capacity"
                                                style={{ width: '100px' }}
                                            >
                                                <InputNumber min={1} />
                                            </Form.Item>
                                            <MinusCircleOutlined className='remove-icon' onClick={() => remove(name)} />
                                        </div>
                                    </div>
                                ))}
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Another Room
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>

            <Modal width='90vw' open={accomodationModal} onCancel={closeAssignModal} onOk={saveAccomodation} okText='Save'>
                <div className='accomodation-container'>
                    {selectedAccommodation?.rooms.map((room, roomIndex) => (
                        <div className='position-relative'>
                            <div className={`hidden-room d-${currentRoom === roomIndex ? 'none' : 'flex'}`}>
                                <h5>Room {room.roomNumber}</h5>
                                <div className='d-flex gap-2'>
                                    <h6>Capacity: {room.capacity}</h6>
                                    <h6>Vacancies: {room.capacity - room.assignedEmployees.length}</h6>
                                    <h6>Occupancy: {room.assignedEmployees.length}</h6>
                                </div>
                                <Button type='primary' onClick={() => setCurrentRoom(roomIndex)}>View</Button>
                            </div>
                            <div className="d-flex justify-content-between">
                                <h5>Room {room.roomNumber}</h5>
                                <p>Vacancies: {room.capacity - room.assignedEmployees.length}</p>
                            </div>
                            <div className='mt-1 headers d-flex gap-1 flex-grow-1'>
                                        <div style={{ width: '50px' }}>
                                            <p>Ecode</p>
                                        </div>
                                        <div style={{ width: '28%' }}>
                                            <p>Employee Name</p>
                                        </div>
                                        <div>
                                            <p>Ref No.</p>
                                        </div>
                                        <div>
                                            <p>Work No.</p>
                                        </div>
                                        <div>
                                            <p>Nationality</p>
                                        </div>
                                        <div>
                                            <p>Status</p>
                                        </div>
                                    <p>Delete</p>
                                    </div>
                                
                            <div className='details'>
                                {room.assignedEmployees.map((employee, index) => (
                                    <div>
                                        <div className="d-flex gap-1 flex-grow-1">
                                            <div style={{ width: '50px' }}>
                                                <p>{employee.eCode}</p>
                                            </div>
                                            <div style={{ width: '28%' }}>
                                                <p>{employee.employeeName}</p>
                                            </div>
                                            <div>
                                                <p>{employee.refNo}</p>
                                            </div>
                                            <div>
                                                <p>{employee.companyNumber}</p>
                                            </div>
                                            <div>
                                                <p>{employee.nationality}</p>
                                            </div>
                                            <div>
                                                <p style={{ color: employee.status === 'Active' ? '#10ca10' : employee.status === 'Terminated/Resigned' ? 'red' : 'orange' }}>{employee.status}</p>
                                            </div>
                                        </div>
                                        <Button onClick={() => unassignEmployee(roomIndex, index)} style={{ color: 'gray', fontSize: '12px' }} type='text' shape='circle' icon={<MinusCircleFilled style={{ fontSize: '12px' }} />}></Button>
                                    </div>
                                ))}
                            </div>
                            {room.assignedEmployees.length < room.capacity && (
                                <Select
                                    showSearch
                                    style={{ width: 200 }}
                                    placeholder="Assign Employee"
                                    optionFilterProp="label"
                                    value={null}
                                    onChange={(value) => assignEmployee(roomIndex, value)}
                                    options={employees.map((e, index) => {
                                        return { label: `${e.eCode}: ${e.employeeName}`, value: index }
                                    })}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </Modal>


        </div>
    );
};

export default AccommodationManagement;
