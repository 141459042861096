import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { message, Button, DatePicker, Select, Radio, Tooltip, Input, FloatButton, Spin, Popover } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { SaveOutlined, DownloadOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import { html2pdf } from "html2pdf.js";
import dayjs from "dayjs";
import AdminNavbar from "../components/AdminNavbar";

const { TextArea } = Input;

const ManTerminationApproval = () => {

	const { id } = useParams();
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false);
	const [rejectLoading, setRejectLoading] = useState(false);

	const [formData, setFormData] = useState({
		employeeName: '',
		eCode: '',
		position: '',
		refNo: '',
		firstName: '',
		date: null,
		text: ''
	});
	const [companyId, setCompanyId] = useState(null);
	const [logoComponent, setLogoComponent] = useState(null);
	const [isButtonVisible, setIsButtonVisible] = useState(true);
	const [managerEmail, setManagerEmail] = useState(null);
    const [reason, setReason] = useState('');

	const fetchFormData = async () => {
		// Immediately set isLoading to true when fetch starts
		setIsLoading(true);

		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/get-approval-form/${id}`, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			console.log('data: ', data)
			if (response.ok) {
				const parsedData = {
					...data,
                    date: data.date ? dayjs(data.date) : null,
				};
				console.log('parsedData: ', parsedData)
				setFormData(parsedData);
				// setFormData(data);
			} else {
				console.error('Error:', data.message || 'Failed to fetch data');
			}
		} catch (error) {
			console.error('Error:', error);
		} finally {
			// Set isLoading to false when fetch is complete
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (id)
			fetchFormData()
	}, [id]);

	const handleChange = (name, value) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleDownload = async () => {
		setIsButtonVisible(false); // Hide the button
		const pdfElement = document.getElementById('section-to-print');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 10,
			filename: 'leave_application_form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
	};

	  const approveForm = async (filename, ecode) => {
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/approve-form', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify({ formId: id, formData: formData }),
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                message.success('Form approved successfully');
                navigate('/manager/approvals');
            } else {
                message.error(data.error || 'Failed to approve form');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to approve form');
        }
    };

	const rejectForm = async (filename, ecode) => {
		setRejectLoading(true);
        const token = localStorage.getItem('token');
        try {
            const response = await fetch('https://hrms-5u7j.onrender.com/manager/reject-form', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify({ formId: id, reason }),
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                message.success('Form rejected successfully');
                navigate('/manager/approvals');
            } else {
                message.error(data.error || 'Failed to reject form');
            }
			setRejectLoading(false);
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to reject form');
			setRejectLoading(false);
        }
    };

console.log(formData)

    return ( 
        <>
			<div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
				<Navbar />
			</div>
			<div className="center-container" style={{ paddingTop: 120 }}>
				{/* <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p> */}
				<div className="disciplinary-action-form">
					<div className='local-leave-container'>
						<div className='form-container termination-letter' id='section-to-print' style={{ border: '1px solid black', padding: '20px', height: '355mm', width: '250mm' }} >
							<div className='row'>
								<div className='col-md-2'>{logoComponent}</div>
								<div className='col-md-8'>
									<h2 style={{ fontWeight: 'bolder', border: 'none' }}><br />Employment Termination Letter</h2>
								</div>
								<div className='col-md-2' >
								</div>
							</div><br />
							<div className="row form-fields">
								<div className="col-md-12 ">
									<label>Ref.</label>
									<TextArea autoSize value={formData.refNo} style={{ width: '40%', textAlign: 'center' }} type="text" />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '15px' }}>
									<label>Date:</label>
									<DatePicker value={formData.date} disabled style={{ width: '40%' }} />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '50px' }}>
									<label>Name:</label>
									<TextArea autoSize style={{ borderRadius: 0, width: '40%' }} value={formData.employeeName} type="text" />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '15px' }}>
									<label>Employee Code:</label>
									<Input value={formData.eCode} style={{ borderRadius: 0, width: '40%', textAlign: 'center' }} type="text" />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '15px' }}>
									<label>Designation:</label>
									<TextArea autoSize style={{ borderRadius: 0, width: '40%' }} value={formData.position} type="text" />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '15px' }}>
									<label>QID Number:</label>
									<TextArea value={formData.qidNumber} autoSize style={{ borderRadius: 0, width: '40%' }} type="text" />
								</div>
							</div>
							<div className="row form-fields" style={{ paddingTop: '80px' }}>
								<div className="col-md-12  ">
									<label style={{ fontWeight: '500' }}>Dear </label>
									<TextArea value={formData.firstName} autoSize style={{ borderRadius: 0, width: '40%' }} type="text" />
									<label style={{ fontWeight: '500' }}>,</label>

								</div>
								<div className="col-md-12  " style={{ paddingTop: '30px' }}>
									<TextArea autoSize style={{ fontWeight: '600', width: '100%', border: 'none', textAlign: 'left', fontSize: 20 }} value={formData.text} onChange={handleChange} name="text"></TextArea>
								</div>
							</div>
							<hr className='signature-hr' style={{ marginTop: '60px' }}></hr>
							<h5 style={{ fontWeight: 'bolder' }}>Manager/Director</h5>
							<div style={{ marginTop: '100px' }}>
								<label style={{ fontWeight: '600' }}>Distribution:    Personnel File</label>
							</div>
							<div>
								<label style={{ float: 'right', fontWeight: '600' }}>Rev. 00</label>
							</div>

						</div>
					</div>
						<FloatButton
							icon={isLoading ? <Spin/> : <CheckOutlined/>}
							tooltip="Approve"
							onClick={approveForm}
							style={{
								right: 200,
							}}
						/>
						<Popover
							trigger={'click'}
							content={
								<TextArea onPressEnter={rejectForm} autoSize placeholder='reason' value={reason} onChange={(e) => setReason(e.target.value)} style={{ width: '200px', fontSize: 12, textAlign: 'left' }} />
							}
						>
							<Tooltip title="Reject" placement='top'>
								<FloatButton
									icon={rejectLoading ? <Spin /> : <CloseOutlined />}
									// onClick={() => window.print()}
									// onClick={rejectForm}
									style={{
										right: 150,
									}}
								/>
							</Tooltip>
						</Popover>
						<Tooltip title="Download" placement='right'>
							<FloatButton
								icon={<DownloadOutlined />}
								onClick={handleDownload}
								style={{
									right: 100,
								}}
							/>
						</Tooltip>
				</div>
			</div>
		</>
     );
}
 
export default ManTerminationApproval;