import React, { createContext, useState } from 'react';

export const DepartmentsContext = createContext();

export const DepartmentsProvider = ({ children }) => {
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  return (
    <DepartmentsContext.Provider value={{ selectedDepartments, setSelectedDepartments }}>
      {children}
    </DepartmentsContext.Provider>
  );
};
