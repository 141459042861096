import React from 'react';
import { Link } from 'react-router-dom';
import './Earnings.css'; // Import the CSS file for custom styling
import { HomeFilled, FormOutlined, DollarOutlined } from '@ant-design/icons';

const Earnings = () => {
  return (
    <div className="earnings-container">
      <div className="earnings-item">
        <Link to="/admin/earnings/salaries" className="earnings-link">
          <FormOutlined style={{ marginRight: '10px' }} />
          Salaries &amp; Payroll
        </Link>
      </div>
      <div className="earnings-item">
        <Link to="/admin/earnings/payroll-setup" className="earnings-link">
          <DollarOutlined style={{ marginRight: '10px' }} />
          Payroll Setup
        </Link>
      </div>
      <p className='home-header' style={{ top: '40px', left: '20px' }}>
        <Link to='/admin' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
        Earnings
      </p>
    </div>
  );
};

export default Earnings;
