import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Modal, message, Checkbox, Input, Select, Space, Tag, Tooltip, Popover, } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PieChart, Pie, Cell } from 'recharts';
import { CSVLink } from 'react-csv';
import { utils, read } from 'xlsx';
import { ShrinkOutlined, ArrowsAltOutlined, MoreOutlined, FileExcelOutlined, DownloadOutlined, FilterOutlined, FilterFilled } from '@ant-design/icons';
import moment from 'moment';

import './EmployeeDataDashboard.css';
import Navbar from './components/Navbar';
import { employeeAttributeDataTypes, employeeAttributeLabels, employeeAttributes } from './components/Data';
import dayjs from 'dayjs';

const ManEmployeeDataDashboard = () => {
	const [employees, setEmployees] = useState([]);
	// const [statusCounts, setStatusCounts] = useState([]);
	// const [file, setFile] = useState(null);
	const [dataLoaded, setDataLoaded] = useState(false);
	// const [searchQuery, setSearchQuery] = useState('');
	// const [selectedDepartments, setSelectedDepartments] = useState([]);
	// const navigate = useNavigate();
	// const [departments, setDepartments] = useState([]);
	// const { Option } = Select;
	const [companyId, setCompanyId] = useState(null);
	// const [adminCompany, setAdminCompany] = useState(null);
	// const [selectedCompany, setSelectedCompany] = useState(null);
	const [companies, setCompanies] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [currentSize, setCurrentSize] = useState(100);
	const [fullDepartments, setFullDepartments] = useState([]);
	// const [positions, setPositions] = useState([]);
	// const [selectedPositions, setSelectedPositions] = useState([]);
	const [searchText, setSearchText] = useState('');
	// const [searchedColumn, setSearchedColumn] = useState('');
	// const searchInput = useRef(null);
	const [openFilter, setOpenFilter] = useState(false);
	const [filters, setFilters] = useState([]);
	const [filterColumn, setFilterColumn] = useState('');
	const [filterValue, setFilterValue] = useState([]);
	const [minimize, setMinimize] = useState(true);

	// const [managerEmail, setManagerEmail]= useState([]);


	// const [selectedAttributes, setSelectedAttributes] = useState([]);
	// const [isModalVisible, setIsModalVisible] = useState(false);
	const [exportConfirmed, setExportConfirmed] = useState(false);

	// useEffect(() => {
	// 	const storedManagerEmail = localStorage.getItem('managerEmail');
	// 	if (storedManagerEmail) {

	// 		setManagerEmail(storedManagerEmail);
	// 		 // console.log(managerEmail);
	// 	}
	// }, []);
	const storedManagerEmail = localStorage.getItem('managerEmail') || '';
	const [managerEmail, setManagerEmail] = useState(storedManagerEmail);
	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/companies', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				if (data.companies && data.companies.length > 0) {
					const dict = data.companies.reduce((acc, item) => {
						acc[item._id] = item.companyName;
						return acc;
					}, {});
					setCompanies(dict); // 
					// const companyId = data.companies[0]._id;
					// const companyName = data.companies[0].companyName;
					// // console.log('data:', data.companies);
					// setAdminCompany(companyName);
					// setCompanyId(companyId);
					// setSelectedCompany(companyId);
					fetchAllDepartments();
					fetchAllEmployees();
				} else {
					message.error('Company ID not found in the response');
				}
			} else {
				message.error(data.error || 'Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};


	const fetchDepartments = async (adminCompany) => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getDepartmentsE/${adminCompany}`, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				// const distinctDepartmentNames = [...new Set(data.map(department => department.departmentName))];
				// setDepartments(distinctDepartmentNames);

			} else {
				console.error('Failed to fetch departments:', data.error);
				// throw new Error(data.error);
			}
		} catch (error) {
			console.error('Error:', error);
			// throw error;
		}
	};

	const getDepartmentNameById = (departmentId) => {
		const department = fullDepartments.find(dep => dep._id === departmentId);
		return department ? department.departmentName : null;
	};

	const fetchAllDepartments = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/getAllDepartments', {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				// Derive distinct department names
				// const distinctDepartmentNames = [...new Set(data.map(department => department.departmentName))];
				// setDepartments(distinctDepartmentNames);
				setFullDepartments(data);

			} else {
				console.error('Failed to fetch departments:', data.error);
				// throw new Error(data.error);
			}
		} catch (error) {
			console.error('Error:', error);
			// throw error;
		}
	};

	// const handleCompanyChange = async (companyId) => {
	// 	if (companyId === null) {
	// 		setSelectedCompany(null);
	// 		setAdminCompany(null);
	// 		setCompanyId(null);
	// 		// Make sure fetchDepartments completes before moving to the next line.
	// 		await fetchAllEmployees();
	// 	} else {
	// 		const selectedCompanyInfo = companies.find((company) => company._id === companyId);
	// 		setEmployees([]);
	// 		setSelectedCompany(companyId);
	// 		setAdminCompany(selectedCompanyInfo.companyName);
	// 		setCompanyId(companyId);
	// 		// fetchDepartments(selectedCompanyInfo.companyName);
	// 		fetchEmployees(companyId);
	// 	}

	// };

	const formatDatesInArray = (arr) => {
		return arr.map(item => {
			let formattedItem = { ...item };
			for (let key in formattedItem) {
				if (formattedItem.hasOwnProperty(key) && key.endsWith('Date')) {
					let value = formattedItem[key];
					if (moment(value, moment.ISO_8601, true).isValid()) {
						formattedItem[key] = moment(value).format('DD-MM-YYYY');
					}
				}
			}
			return formattedItem;
		});
	};


	const fetchEmployees = useCallback(async (companyId) => {
		setDataLoaded(false);
		try {
			// console.log(companyId)
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeesByCompany/${companyId}`,
				{
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + token,
					},
				});
			const data = await response.json();
			if (response.ok) {
				setDataLoaded(true);
				setEmployees(formatDatesInArray(data));
				// const distinctPositions = [...new Set(data.map(employee => employee.position))];
				// setPositions(distinctPositions);
				const statusCounts = data.reduce((acc, curr) => {
					if (curr.status in acc) {
						acc[curr.status]++;
					} else {
						acc[curr.status] = 1;
					}
					return acc;
				}, {});
				// setStatusCounts(Object.entries(statusCounts).map(([status, count]) => ({ name: status, value: count })));
			} else {
				message.error(data.message || 'Failed to fetch employees');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch employees');
		}
	}, [companyId]); // Add fetchDepartments as a dependency



	const fetchAllEmployees = useCallback(async (companyId) => {
		setDataLoaded(false);
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getEmployeesByManager/${managerEmail}`,
				{
					method: 'GET',
					headers: {
						Authorization: 'Bearer ' + token,
					},
				});
			const data = await response.json();
			if (response.ok) {
				setDataLoaded(true);
				setEmployees(formatDatesInArray(data));
				// const distinctPositions = [...new Set(data.map(employee => employee.position))];
				// setPositions(distinctPositions);

				// const statusCounts = data.reduce((acc, curr) => {
				// 	if (curr.status in acc) {
				// 		acc[curr.status]++;
				// 	} else {
				// 		acc[curr.status] = 1;
				// 	}
				// 	return acc;
				// }, {});
				// setStatusCounts(Object.entries(statusCounts).map(([status, count]) => ({ name: status, value: count })));
			} else {
				message.error(data.message || 'Failed to fetch employees');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch employees');
		}
	}, []); // Add fetchDepartments as a dependency

	useEffect(() => {
		fetchCompany();
	}, []);

	useEffect(() => {
		// console.log('company', companyId)
		if (companyId) {
			fetchEmployees(companyId);
		}
	}, [companyId]);


	// const handleSearch = (e) => {
	// 	setSearchQuery(e.target.value);
	// };


	// const handleCheckboxChange = (values) => {
	// 	setSelectedDepartments(values);
	// };

	const confirmFilters = () => {
		// // console.log(filterColumn, filterValue, filters)
		setFilters([...filters, { column: filterColumn, values: filterValue.map((value) => value.trim()) }])
		setFilterColumn('')
		setFilterValue([])
	}


	const addFilterValue = (value) => {
		setFilterValue(value)
	}


	// const showModal = () => {
	// 	setIsModalVisible(true);
	// };

	const handleOk = () => {
		// setIsModalVisible(false);
		setExportConfirmed(true);
		// document.getElementById('export-csv').click();
	};

	useEffect(() => {
		if (exportConfirmed) {
			document.getElementById('export-csv').click();
			setExportConfirmed(false);
		}
	}, [exportConfirmed]);

	// const handleCancel = () => {
	// 	setIsModalVisible(false);
	// 	setExportConfirmed(false);
	// };

	// const onChange = (checkedValues) => {
	// 	setSelectedAttributes(checkedValues);
	// };

	// const handleExportClick = () => {
	// 	showModal();
	// };

	const generateColumns = () => {
		return Object.keys(employeeAttributeLabels).map(key => {
			let column = {
				title: employeeAttributeLabels[key],
				dataIndex: key,
				key: key,
				width: 200,
				ellipsis: true
			};

			if (key === 'companyName') {
				// console.log(key)
				column.render = (_, record) => (
					<p className='m-0'>
						{companies[record.company]}
					</p>
				);
			}

			//   if (key === 'refNo' || key === 'refName')
			// 	column.fixed = 'left';

			if (key === 'employeeName') {
				column.fixed = 'left';
				column.render = (_, record) => (
					<Link className={minimize ? 'cell-cutoff' : ''} to={`/manager/employee-details/${record._id}`} style={{ whiteSpace: 'nowrap', textDecoration: 'none' }}>
						{record.employeeName}
					</Link>
				);
			}

			if (key === 'status') {
				column.render = (_, record) => (
					<p className='m-0'
						style={{ color: record.status === 'Active' ? '#10ca10' : record.status === 'Terminated/Resigned' ? 'red' : 'orange' }}
					>
						{record.status}
					</p>
				);
			}

			if (key === 'eCode') {
				column.render = (_, record) => (
					<div className='d-flex justify-content-between'>
						<div className='d-flex gap-2 align-items-center'>
							{record.personalFiles.length === 0 ? 
							<Tooltip title="Personal files missing">
								<FileExcelOutlined/> 
							</Tooltip>
							: <div style={{width:14}}></div>}
							{record.eCode}
						</div>
						<Popover
							placement='right'
							trigger='click'
							content={
								<div className='employee-shortcuts pe-5'>
									<Link to={`/manager/hr-forms/leave-application/${record.eCode}`}>Apply for leave</Link>
									<Link to={`/manager/hr-forms/employment-termination/${record.eCode}`}>Apply for Termination</Link>
									<Link to={`/manager/hr-forms/rejoining-form/${record.eCode}`}>Apply for rejoining</Link>
									<Link to={`/manager/hr-forms/sick-leave/${record.eCode}`}>Apply for Sick Leave</Link>
									<Link to={`/manager/hr-forms/warning-letter/${record.eCode}`}>Warning Letter</Link>
									<Link to={`/manager/employee-details/${record._id}?tab=2`}>Employee Files</Link>
									<Link to={`/manager/employee-details/${record._id}?tab=1`}>Employee Timeline</Link>
								</div>
							}
						>
							<MoreOutlined />
						</Popover>
					</div>
				);
				column.fixed = 'left';
				column.sorter = (a, b) => {
					// Assuming eCode is a string and we're handling it case-insensitively
					return a.eCode.localeCompare(b.eCode, undefined, { numeric: true, sensitivity: 'base' });
				};
				column.defaultSortOrder = 'ascend';
				column.sortDirections = ['ascend', 'descend', 'ascend']; // cycle through ascending, descending, and then back to ascending
			}

			if (key === 'refName') {
				column.render = (_, record) => (
					<p className={minimize ? 'm-0 cell-cutoff' : 'm-0'} style={{ whiteSpace: 'nowrap' }}>
						{record.refName}
					</p>
				);
			}

			//   if (key.toLowerCase().includes('date')) {
			// 	column.render = date => moment(date).format('YYYY-MM-DD');
			//   }

			const dataType = employeeAttributeDataTypes[key];

			if (dataType === 1) { // Number (String)
				column.sorter = (a, b) => (a[key] ?? '').localeCompare(b[key] ?? '', undefined, { numeric: true });
			} else if (dataType === 2) { // Alphabetic String
				column.sorter = (a, b) => (a[key] ?? '').localeCompare(b[key] ?? '');
			} else if (dataType === 3) { // Date
				column.sorter = (a, b) => {
					const dateA = dayjs(a[key], 'DD-MM-YYYY');
					const dateB = dayjs(b[key], 'DD-MM-YYYY');

					if (!dateA.isValid() && dateB.isValid()) return -1;
					if (dateA.isValid() && !dateB.isValid()) return 1;
					if (!dateA.isValid() && !dateB.isValid()) return 0;

					return dateA.diff(dateB);
				};
			}

			return column;
		});
	};

	const columns = generateColumns();

	// const searchEmployee = (e) => {
	// 	setSearchText(e.target.value.toLowerCase());
	// };

	// // console.log(filters)
	const filteredEmployees = employees.filter((employee) => {
		if (searchText) {
			return Object.values(employee).join('').toLowerCase().includes(searchText.toLowerCase());
		}
		return filters.every((filter) => {
			return filter.values.some(value => {
				if (filter.column === 'companyName') {
					return companies[employee.company]?.toLowerCase().startsWith(value.toLowerCase());
				}
				return employee[filter.column]?.toLowerCase().startsWith(value.toLowerCase());
			});
		});
	});

	// useEffect(() => {
	// 	// console.log(filters)
	// }, [filters]);

	const removeFilter = (index) => {
		setFilters(filters.filter((_, i) => i !== index));
	}

	return (
		<>
			<Navbar />
			<div className='dashboard mt-3'>
				<div>
					<div className='d-flex justify-content-between my-2'>
						<Input.Search
							placeholder='Search employees'
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
							allowClear
							style={{ width: '500px' }}
						/>
						<div className='d-flex gap-4'>
							{!minimize ?
								<Tooltip title='Minimize'>
									<Button type='text' shape='circle'><ShrinkOutlined style={{ fontSize: 20 }} onClick={() => setMinimize(true)} /></Button>
								</Tooltip> :
								<Tooltip title='Maximize'>
									<Button type='text' shape='circle'><ArrowsAltOutlined style={{ fontSize: 20 }} onClick={() => setMinimize(false)} /></Button>
								</Tooltip>
							}
							<Tooltip title='Export'>
								<Button type='text' shape='circle' icon={<DownloadOutlined style={{ fontSize: 20 }} />} onClick={handleOk} />
							</Tooltip>
							<Tooltip title='Filter'>
								{openFilter ?
									<Button type='text' shape='circle' icon={<FilterFilled style={{ fontSize: 20 }} />} onClick={() => setOpenFilter(!openFilter)} />
									:
									<Button type='text' shape='circle' icon={<FilterOutlined style={{ fontSize: 20 }} />} onClick={() => setOpenFilter(!openFilter)} />
								}
							</Tooltip>
						</div>
					</div>
					{/* <Modal title="Select Attributes to Export" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
						<Checkbox.Group options={employeeAttributes} onChange={onChange}>
						</Checkbox.Group>
					</Modal> */}
					{exportConfirmed && (
						<CSVLink
							style={{ display: 'none' }}
							id='export-csv'
							data={companyId
								? filteredEmployees.map((employee) =>
									employeeAttributes.reduce((obj, attr) => {
										if (attr === 'companyName') {
											return { ...obj, [attr]: companies[employee.company] };
										} else {
											return { ...obj, [attr]: employee[attr] };
										}
									}, {})
								)
								: filteredEmployees.reduce((allData, employee) => {
									const employeeData = employeeAttributes.reduce((obj, attr) => {
										if (attr === 'companyName') {
											return { ...obj, [attr]: companies[employee.company] };
										} else {
											return { ...obj, [attr]: employee[attr] };
										}
									}, {});
									allData.push(employeeData);
									return allData;
								}, [])
							}
							headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
							filename={'employees.csv'}
						>
							Download CSV
						</CSVLink>
					)}

				</div>
				<div className={`table-wrapper${openFilter ? '-open' : ''} d-flex justify-content-between gap-1`}>
					<div className='table'>
						{/* {!minimize ? */}
						<Table
							dataSource={filteredEmployees}
							// style={{ width: '100%' }}, Apply for Termination/Resignation, Employee Files ,Employee Timeline
							columns={columns}
							rowKey="_id"
							loading={dataLoaded ? false : !dataLoaded}
							className="table-class 1"
							// rowClassName={(record, index) => index % 2 === 0 ? 'even-row' : 'odd-row'}
							pagination={{
								current: currentPage,
								pageSize: currentSize,
								total: filteredEmployees.length,
								onChange: (page) => setCurrentPage(page),
								onShowSizeChange: (current, size) => setCurrentSize(size),
							}}
							scroll={{
								x: 'max-content',
								y: `calc(90vh - 250px)`
							}}
						/>

					</div>
					<div className={`filter-container ${openFilter ? 'open' : ''}`}>
						<div>
							{filters.map(({ column, values }, index) => (
								<div key={index}>
									<div className='d-flex justify-content-between'>
										<p>{employeeAttributeLabels[column]}</p>
										<Button shape='circle' type='text' onClick={() => removeFilter(index)}>x</Button>
									</div>
									{values.map((value) => (
										<Tag key={value} >
											{value}
										</Tag>
									))}
									<br /><br />
								</div>
							))}
							<p>Filter by</p>
							<Select
								showSearch
								onChange={(value) => setFilterColumn(value)}
								value={filterColumn}
								style={{
									width: '100%',
									marginBottom: 10
								}}
								placeholder="Search to Select"
								optionFilterProp="children"
								filterOption={(input, option) => (option?.label.toLowerCase() ?? '').startsWith(input.toLowerCase())}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
								}
								options={Object.entries(employeeAttributeLabels).map(([key, value]) => ({
									value: key,
									label: value
								}))}
							/>
							<Select
								mode="tags"
								style={{
									width: '100%',
									marginBottom: 10
								}}
								placeholder="value"
								notFoundContent={null}
								value={filterValue}
								onChange={addFilterValue}
							/>
							<Button onClick={confirmFilters} style={{ float: 'right' }}>Confirm</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ManEmployeeDataDashboard;
