import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeFilled } from '@ant-design/icons';
import { Card, Row, Col, Tooltip, Pagination } from 'antd';
import './HumanResourceForms.css';
import Navbar from './components/Navbar';

const HumanResourceForms = () => {
  const formNames = {
    "warning-letter": "Warning Letter",
    "leave-application": "Leave Application",
    "rejoining-form": "Rejoining Form",
    "sick-leave": "Sick Leave",
    // "Contract Residential",
    // "Employee Evaluation",
    // "Annual Training",
    // "Training Need Assessment",
    // "Employee Probationary Evaluation",
    // "Travel Agency",
    // "Training Requirement",
    // "Training Attendance",
    // "Exit Interview",
    "employment-termination": "Employment Termination",
    "loan-request" : "Loan Request",
    "employee-data" : "Employee Data",
    // Add more form names if needed
  }

  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 6; // Display 6 items per page

  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = formNames.slice(indexOfFirstItem, indexOfLastItem);

  // const handlePageChange = page => {
  //   setCurrentPage(page);
  // };

  return (
    <div>
      <Navbar />
      {/* <p className='home-header'>
      <Link to='/manager-router' style={{ color: '#11686D', paddingRight: '3vw' }}>
          <HomeFilled />
        </Link>
        HR Forms
      </p> */}
      <div className='hr-forms p-5'>
        <Row gutter={[16, 16]}>
          {Object.entries(formNames).map(([filename, formName], index) => (
            <Col xs={24} sm={12} md={12} lg={8} xl={6} key={index}>
              {/* <Tooltip title={formName}> */}
              <Link to={`/manager/hr-forms/${filename}`} className="form-link">
                <Card
                  style={{ width: '21vw' }}
                  hoverable
                  className={`card green-shadow`}
                  cover={<img style={{ maxHeight: '150px', objectFit: 'cover', objectPosition: 'top' }} alt="example" src={`/${filename}.png`} />}
                >
                  <p className='form-link'>{formName}</p>
                </Card>
              </Link>
              {/* </Tooltip> */}
            </Col>
          ))}
        </Row>
        {/* <Pagination
          className="pagination-container"
          current={currentPage}
          total={formNames.length}
          pageSize={itemsPerPage}
          onChange={handlePageChange}
          showSizeChanger={false}
        /> */}
      </div>
    </div>
  );
};

export default HumanResourceForms;
