import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Statistic, Table, Tooltip, message } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { read, utils, write } from 'xlsx';
import { DepartmentsContext } from './DepartmentsContext';
import { FileAddOutlined, DownloadOutlined } from '@ant-design/icons';
import './Salaries&Payroll.css';
import { CSVLink } from 'react-csv';
import AdminNavbar from './components/AdminNavbar';
import { payrollLabels } from './components/Data';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const { MonthPicker } = DatePicker;
const { Option } = Select;


const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
	const parseValue = (dataIndex, value) => {
		if (dataIndex === 'pickups' || dataIndex === 'deliveries') {
			return parseInt(value);
		}
		return parseFloat(value);
	}
	return (
		<td {...restProps}>
			{editable ? (
				<Form.Item style={{ margin: 0 }}>
					<Input
						style={{ border: 'none', backgroundColor: 'transparent' }}
						type='number'
						defaultValue={record[dataIndex]}
						onChange={(e) => handleSave({ ...record, [dataIndex]: parseValue(dataIndex, e.target.value) })}
						onMouseOver={(e) => e.target.style.backgroundColor = '#f0f0f0'}
						onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
					/>
				</Form.Item>
			) : (
				children
			)}
		</td>
	);
};

const SalariesAndPayroll = ({ selectedEmployees }) => {
	const [file, setFile] = useState(null);
	const [employees, setEmployees] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);  // Maintain the current page in the state
	const pageSize = 100;
	const [fileImported, setFileImported] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [payrollSetup, setPayrollSetup] = useState({ 'ppp': [], 'ppd': [] });
	const [companyId, setCompanyId] = useState(null);
	const [totalOrders, setTotalOrders] = useState(0);
	const [totalSickLeaves, setTotalSickLeaves] = useState(0);
	const [totalDeductions, setTotalDeductions] = useState(0);
	const [totalPayables, setTotalPayables] = useState(0);
	const [totalCompanyDeductionCollection, setTotalCompanyDeductionCollection] = useState(0);
	const [totalClicked, setTotalClicked] = useState(false);
	const [payrollType, setPayrollType] = useState('PPO');
	const { selectedDepartments } = useContext(DepartmentsContext);
	const fileInputRef = useRef(null);
	const tableContainerRef = useRef(null);
	const [editingKey, setEditingKey] = useState('');
	const [form] = Form.useForm();

	const handleSave = (row) => {
		const newData = [...employees];
		const index = newData.findIndex((item) => row.eCode === item.eCode);
		const item = newData[index];
		newData.splice(index, 1, { ...item, ...row });

		setEmployees((prevEmployees) => {
			const updatedEmployees = [...newData];
			const employee = newData[index];

			// if (columnName === 'pickups' || columnName === 'deliveries' || columnName === 'sickLeave' || columnName === 'overtimeAdditions') {
			// 	employee[columnName] = parsedValue;
			// } else {
			// 	employee[columnName] = value;
			// }

			const deliveries = employee.deliveries || 0;
			const ppd = employee.ppd || 0;
			const pickups = employee.pickups || 0;
			const ppp = employee.ppp || 0;
			const mobile = employee.mobile || 0;
			const settlement = employee.settlement || 0;
			const carRent = employee.carRent || 0;
			const accomodation = employee.accomodation || 0;
			const cod = employee.cod || 0;
			const companyDeduction = employee.companyDeduction || 0;
			const overtimeAdditions = employee.overtimeAdditions || 0;
			const sickLeave = employee.sickLeave || 0;
			employee.totalBenefits = ((ppp * pickups) + (ppd * deliveries) + (overtimeAdditions || 0) + (sickLeave || 0)).toFixed(2);
			employee.pickupBenefits = (ppp * pickups).toFixed(2);
			employee.deliveryBenefits = (ppd * deliveries).toFixed(2);

			const lateLoginDeduction = employee.lateLoginDeduction || 0;
			const noShow = employee.noShow || 0;
			const penalties = employee.penalties || 0;

			employee.totalDeductions =
				((carRent || 0) +
					(accomodation || 0) +
					(mobile || 0) +
					(settlement || 0) +
					(lateLoginDeduction || 0) +
					(noShow || 0) +
					(penalties || 0) +
					(cod || 0) +
					(companyDeduction || 0)).toFixed(2);
			employee.totalPayables = employee.totalBenefits - employee.totalDeductions;

			updatedEmployees[index] = employee;
			return updatedEmployees;
		});
		setEmployees(newData);
	};
	// // console.log('Selected Departments:', selectedDepartments);

	const [isFixedPayroll, setIsFixedPayroll] = useState(false);
	// const filteredEmployees = employees.filter((employee) =>
	// 	selectedDepartments.includes(employee.department)
	// );

	const handlePayrollTypeChange = (value) => {
		if (value === 'Fixed') {
			setIsFixedPayroll(true);
			const selectedDepartmentIds = selectedDepartments.map((department) => department.value);

			fetchEmployees(selectedDepartmentIds);
		} else {
			setIsFixedPayroll(false);
			setEmployees([]);
		}
		setPayrollType(value);
	};

	useEffect(() => {
		if (payrollType === 'Fixed') {
			fetchEmployees(selectedDepartments.map((department) => department.value));
		}
	}, [selectedDepartments, payrollType]);

	const fetchEmployees = async (selectedDepartmentIds) => {
		try {
			const token = localStorage.getItem('token');

			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getEmployeesByDepartments', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ selectedDepartmentIds }),
			});

			if (!response.ok) {
				message.error('Failed to fetch employees');
				return
				// throw new Error('Failed to fetch employees');
			}

			const data = await response.json();
			return data; // Return the fetched employees
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch employees');
		}
	};

	useEffect(() => {
		if (payrollType === 'Fixed') {
			const selectedDepartmentIds = selectedDepartments.map((department) => department.value);
			fetchEmployees(selectedDepartmentIds).then((data) => {
				setEmployees(data); // Set employees outside of the fetchEmployees function
			});
		}
	}, [selectedDepartments, payrollType]);

	const payrollColumns = {
		PPO: [
			{
				title: 'S.No',
				dataIndex: 'sNo',
				key: 'sNo',
				ellipsis: true,
				fixed: 'left',
				width: 75,
				render: (_, __, index) => (currentPage - 1) * pageSize + index + 1
			},
			{
				title: 'eCode',
				dataIndex: 'eCode',
				key: 'eCode',
				width: 100,
				ellipsis: true,
				fixed: 'left'
			},
			{
				title: 'Company Number',
				ellipsis: true,
				dataIndex: 'companyNumber',
				key: 'companyNumber',
				width: 200
			},
			{
				title: 'Talabat Id',
				ellipsis: true,
				dataIndex: 'talabatId',
				key: 'talabatId',
				width: 150
			},
			{
				title: 'Talabat Name',
				dataIndex: 'talabatName',
				ellipsis: true,
				key: 'talabatId',
				width: 200
			},
			{
				title: 'Riders Name',
				ellipsis: true,
				dataIndex: 'employeeName',
				key: 'employeeName',
				width: 400
			},
			{
				title: 'EMPL Status',
				ellipsis: true,
				dataIndex: 'status',
				key: 'status',
				width: 150
			},
			{
				title: 'QID Number',
				ellipsis: true,
				dataIndex: 'qidNumber',
				key: 'qidNumber',
				width: 175
			},
			{
				title: 'VISA',
				ellipsis: true,
				dataIndex: 'visaNumber',
				key: 'visaNumber',
				width: 200
			},
			{
				title: 'IBAN Number',
				ellipsis: true,
				dataIndex: 'bankAccountIBAN',
				key: 'bankAccountIBAN',
				width: 350
			},
			{
				title: 'Position',
				dataIndex: 'position',
				ellipsis: true,
				key: 'position',
				width: 100
			},
			{
				title: 'Contract',
				ellipsis: true,
				dataIndex: 'contract',
				key: 'contract',
				width: 100
			},
			{
				title: 'PPP',
				dataIndex: 'ppp',
				key: 'ppp',
				ellipsis: true,
				editable: true,
				width: 100
			},
			{
				title: 'Pickups',
				dataIndex: 'pickups',
				key: 'pickups',
				ellipsis: true,
				editable: true,
				width: 100
			},
			{
				title: 'Pickup Benefits',
				dataIndex: 'pickupBenefits',
				key: 'pickupBenefits',
				ellipsis: true,
				width: 150
			},
			{
				title: 'PPD',
				dataIndex: 'ppd',
				key: 'ppd',
				ellipsis: true,
				editable: true,
				width: 100
			},
			{
				title: 'Drop Offs',
				dataIndex: 'deliveries',
				key: 'deliveries',
				editable: true,
				ellipsis: true,
				width: 120
			},
			/* {
				title: 'Dropoffs Rate',
				dataIndex: 'ppd',
				key: 'ppd',
				editable: true,
				width: '150px'
			}, */
			{
				title: 'Drop Off Benefits',
				dataIndex: 'deliveryBenefits',
				ellipsis: true,
				key: 'deliveryBenefits',
				width: 200
			},
			{
				title: 'Distance Pay',
				dataIndex: 'distancePay',
				key: 'distancePay',
				ellipsis: true,
				editable: true,
				width: 150
			},
			{
				title: 'Total Talabat Income',
				dataIndex: 'totalTalabatIncome',
				key: 'totalTalabatIncome',
				ellipsis: true,
				width: 200
			},
			{
				title: 'FA',
				dataIndex: 'fa',
				key: 'fa',
				ellipsis: true,
				editable: true,
				width: 100
			},
			{
				title: 'Sick Leave',
				dataIndex: 'sickLeave',
				key: 'sickLeave',
				ellipsis: true,
				editable: true,
				width: 150
			},
			{
				title: 'OT/Addition',
				dataIndex: 'overtimeAdditions',
				key: 'overtimeAdditions',
				ellipsis: true,
				editable: true,
				width: 150
			},
			{
				title: 'Other',
				dataIndex: 'others',
				key: 'others',
				ellipsis: true,
				editable: true,
				width: 100
			},
			{
				title: 'Car Rental',
				dataIndex: 'carRent',
				key: 'carRent',
				ellipsis: true,
				editable: true,
				width: 150
			},
			{
				title: 'Accomodation',
				dataIndex: 'accomodation',
				key: 'accomodation',
				ellipsis: true,
				editable: true,
				width: 150
			},
			{
				title: 'Mobile',
				dataIndex: 'mobile',
				key: 'mobile',
				ellipsis: true,
				editable: true,
				width: 100
			},
			{
				title: 'Settlement',
				dataIndex: 'settlement',
				key: 'settlement',
				ellipsis: true,
				editable: true,
				width: 150
			},
			{
				title: 'LLD',
				dataIndex: 'lateLoginDeduction',
				key: 'lateLoginDeduction',
				editable: true,
				ellipsis: true,
				width: 75
			},
			{
				title: 'No Show',
				dataIndex: 'noShow',
				key: 'noShow',
				ellipsis: true,
				editable: true,
				width: 100
			},
			{
				title: 'Penalties',
				dataIndex: 'penalties',
				key: 'penalties',
				ellipsis: true,
				editable: true,
				width: 150
			},
			{
				title: 'company C/D',
				dataIndex: 'talabatInvoice',
				key: 'talabatInvoice',
				ellipsis: true,
				editable: true,
				width: 150
			},
			{
				title: 'ALTM',
				dataIndex: 'advancesLoansTrafficMobile',
				key: 'advancesLoansTrafficMobile',
				ellipsis: true,
				editable: true,
				width: 100
			},
			{
				title: 'Total Benefits',
				dataIndex: 'totalBenefits',
				key: 'totalBenefits',
				ellipsis: true,
				width: 150
			},
			{
				title: 'COD',
				dataIndex: 'cod',
				ellipsis: true,
				key: 'cod',
				editable: true,
				width: 100
			},
			{
				title: 'Company Deduction',
				dataIndex: 'companyDeduction',
				key: 'companyDeduction',
				ellipsis: true,
				editable: true,
				width: 200
			},
			{
				title: 'Total Deductions',
				dataIndex: 'totalDeductions',
				key: 'totalDeductions',
				ellipsis: true,
				width: 200
			},
			{
				title: 'Total Payables',
				dataIndex: 'totalPayables',
				key: 'totalPayables',
				ellipsis: true,
				width: 200
			},
		],
		PPH: [
			// Add columns for PPH payroll type here
		],
		PPD: [
			// Add columns for PPD payroll type here
		],
		Fixed: [
			{
				title: 'S.No',
				dataIndex: 'sNo',
				key: 'sNo',
				width: '75px'
			},
			{
				title: 'Emp Code',
				dataIndex: 'eCode',
				key: 'eCode',
				width: '100px'
			},
			{
				title: 'Name',
				dataIndex: 'employeeName',
				key: 'employeeName',
				width: '400px'
			},
			{
				title: 'Custom Attributes',
				dataIndex: 'customAttributes',
				key: 'customAttributes',
				render: customAttributes => (
					<div>
						{customAttributes.map((attr, index) => (
							<p key={index}>
								{attr.value}
							</p>
						))}
					</div>
				),
				width: '300px'
			},

			{
				title: 'Days Worked',
				dataIndex: 'daysWorked',
				key: 'daysWorked',
				width: '150px'
			},
			{
				title: 'Basic Salary',
				dataIndex: 'basicSalary',
				key: 'basicSalary',
				width: '100px'
			},
			{
				title: 'Additional / Bonus',
				dataIndex: 'additionalBonus',
				key: 'additionalBonus',
				width: '150px'
			},
			{
				title: 'Company Deduction',
				dataIndex: 'companyDeduction',
				key: 'companyDeduction',
				width: '150px'
			},
			{
				title: 'Net Salary',
				dataIndex: 'netSalary',
				key: 'netSalary',
				width: '150px'
			},
		],
	};

	useEffect(() => {
		fetchCompany();
	}, []);

	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				if (data.company && data.company._id) {
					const companyId = data.company._id;
					setCompanyId(companyId);
				} else {
					message.error('Company ID not found in the response');
				}
			} else {
				message.error(data.error || 'Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};

	const mergedColumns = payrollColumns[payrollType].map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				inputType: 'number',
				handleSave
			}),
		};
	});

	useEffect(() => {
		if (companyId) {
			fetchPayrollSetup();
		}
	}, [companyId]);

	const fetchPayrollSetup = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getPayRoll/${companyId}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch payroll setup');
				return
				// throw new Error('Failed to fetch payroll setup');
			}

			const data = await response.json();
			// console.log('Payroll Setup:', data);
			if (!data.payrollSetup || data.payrollSetup.length === 0) {
				message.error('Payroll setups are empty');
				return
				// throw new Error('Payroll setups are empty');
			}
			const payrollDict = data.payrollSetup.reduce((acc, { payrollType, ranges }) => {
				acc[payrollType] = ranges; // Set type as key and ranges as value
				return acc;
			}, {});
			setPayrollSetup(payrollDict);
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch payroll setup');
		}
	};

	const handleFileChange = (event) => {
		setFile(event.target.files[0]);
		event.target.value = null;
	};

	useEffect(() => {
		if (file) {
			importFile();
		}
	}, [file]);

	const importFile = () => {

		if (payrollType === 'Fixed') {
			message.error('Only valid for PPO');
			return;
		}
		if (!file) {
			message.error('Please select a file to import');
			return;
		}
		// if (!Array.isArray(payrollSetup)) {
		// 	message.error('Payroll setup is not available');
		// 	return;
		// }
		try {
			const reader = new FileReader();

			reader.onload = async (evt) => {
				const bstr = evt.target.result;
				const wb = XLSX.read(bstr, { type: 'array' });
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				const data = utils.sheet_to_json(ws, { header: 1 });

				let eCodes = data.map((row) => row[0]);
				//let eCodes = data.map((row) => row[0]);
				const token = localStorage.getItem('token');
				const response = await fetch('https://hrms-5u7j.onrender.com/admin/getByECode', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + token,

					},
					body: JSON.stringify({ eCodes }),
				});

				if (!response.ok) {
					message.error('Failed to fetch employees');
					return
					// throw new Error('Failed to fetch employees');
				}

				let fetchedEmployees = await response.json();
				const employeeMap = fetchedEmployees.reduce((map, employee) => {
					map[employee.eCode] = employee;
					return map;
				}, {});

				console.log('ecode', eCodes);
				eCodes = eCodes.filter((eCode) => employeeMap.hasOwnProperty(eCode));

				console.log('ecode', eCodes);

				const mergedEmployees = eCodes.map((eCode, idx) => {
					const employee = employeeMap[eCode];
					const fileData = data.find((row) => row[0] === eCode);
					const pickups = fileData[1];
					const deliveries = fileData[2];
					const distancePay = fileData[3];
					const fa = fileData[4];
					const sickLeave = fileData[5];
					const overtimeAdditions = fileData[6];
					const others = fileData[7];
					const carRent = fileData[8];
					const accomodation = fileData[9];
					const simFee = fileData[10];
					const lateLoginDeduction = fileData[11];
					const noShow = fileData[12];
					const penalties = fileData[13];
					const cod = fileData[15];
					const companyDeduction = fileData[16];
					const settlement = employee?.contract ? 200 : 0;
					// console.log('employee: ', employee);
					// let ppp, ppd;
					// if (employee.position === 'Bike Rider') {
					// 	ppp 
					// }
					// else if (employee.position === 'Car Driver') {

					// }
					const ch = employee.position === 'Bike Rider' ? 'b' : 'c';
					console.log('ppp' + ch, payrollSetup['ppp' + ch]);
					console.log('ppd' + ch, payrollSetup['ppd' + ch]);

					const rangePPP = payrollSetup['ppp' + ch]?.find(({ start, end, contract }) => pickups >= start && pickups <= end);
					const ppp = rangePPP ? rangePPP.rate : 0;
					const rangePPD = payrollSetup['ppd' + ch]?.find(({ start, end, contract }) => (ch == 'b' || employee.contract == contract) && deliveries >= start && deliveries <= end);
					const ppd = rangePPD ? rangePPD.rate : 0;
					const pickupBenefits = ppp * pickups;
					const deliveryBenefits = ppd * deliveries;

					const totalBenefits = (ppp * pickups) + (ppd * deliveries) + (overtimeAdditions || 0) + (sickLeave || 0);
					const totalTalabatIncome = (distancePay || 0) + (pickupBenefits || 0) + (deliveryBenefits || 0);
					const totalDeductions =
						(carRent || 0) +
						(accomodation || 0) +
						(simFee || 0) +
						(settlement || 0) +
						(lateLoginDeduction || 0) +
						(noShow || 0) +
						(penalties || 0) +
						(cod || 0) +
						(companyDeduction || 0);
					const totalPayables = totalBenefits - totalDeductions;

					return {
						sNo: idx + 1,
						companyNumber: employee.refNo,
						eCode: eCode,
						employeeName: employee.employeeName,
						status: employee.status,
						qidNumber: employee.qidNumber,
						visaNumber: employee.visaNumber,
						bankAccountIBAN: employee.bankAccountIBAN,
						talabatId: employee.refNo,
						talabatName: employee.refName,
						position: employee.position,
						contract: employee.contract,
						accomodation: accomodation,
						ppp,
						companyDeduction,
						cod,
						mobile: simFee,
						settlement,
						fa,
						pickups,
						pickupBenefits,
						ppd,
						deliveries,
						deliveryBenefits,
						distancePay,
						totalTalabatIncome,
						sickLeave,
						overtimeAdditions,
						lateLoginDeduction,
						noShow,
						penalties,
						carRent,
						others,
						totalBenefits,
						totalDeductions,
						totalPayables,
					};

				});
				console.log('mergedEmployees', mergedEmployees);
				setEmployees(mergedEmployees);
				setFileImported(true);

				message.success('Employee details retrieved successfully!');
			};
			reader.readAsArrayBuffer(file);
			//reader.readAsBinaryString(file);
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to import employee details');
		}
	};

	const handleImportSalaryClick = async () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleMonthChange = (date) => {
		setSelectedMonth(date);
	};

	const generateFileName = () => {
		if (selectedMonth) {
			const month = selectedMonth.month() + 1; // Month is zero-based
			const year = selectedMonth.year();
			return `${month}-${year}`;
		}
		return '';
	};

	const handleSaveClick = async () => {
		if (!selectedMonth) {
			message.error('Please select a month');
			return;
		}

		const fileName = generateFileName();
		// // console.log('fileName:', fileName);
		if (!fileName) {
			message.error('Invalid month selection');
			return;
		}

		if (!companyId) {
			message.error('Company ID is not available');
			return;
		}

		try {
			const ws = utils.json_to_sheet(employees);
			// // console.log('Worksheet data:', ws);

			const wb = utils.book_new();
			utils.book_append_sheet(wb, ws, 'Sheet1');
			const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
			// // console.log('Workbook binary data:', wbout);

			const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
			// // console.log("bob", blob);
			const formData = new FormData();
			formData.append('file', blob, `${fileName}.xlsx`);
			// formData.append('companyName', fileName);
			formData.append('companyId', companyId);

			// // console.log('formData:', formData);
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFileSalaries', {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + token,
				},
				body: formData,
			});

			if (!response.ok) {
				message.error('Failed to save the file');
				return;
				// throw new Error('Failed to save the file');
			}

			message.success('File saved successfully!');
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to save the file');
		}
	};
	function s2ab(s) {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
		return buf;
	}

	const handleInputChange = (event, index, columnName) => {
		const { value } = event.target;
		const parsedValue = parseInt(value, 10);

		setEmployees((prevEmployees) => {
			const updatedEmployees = [...prevEmployees];
			const employee = { ...updatedEmployees[index] };

			if (columnName === 'pickups' || columnName === 'deliveries' || columnName === 'sickLeave' || columnName === 'overtimeAdditions') {
				employee[columnName] = parsedValue;
			} else {
				employee[columnName] = value;
			}

			const deliveries = employee.deliveries || 0;
			const ppd = employee.ppd || 0;
			const pickups = employee.pickups || 0;
			const ppp = employee.ppp || 0;
			const mobile = employee.mobile || 0;
			const settlement = employee.settlement || 0;
			const carRent = employee.carRent || 0;
			const accomodation = employee.accomodation || 0;
			const cod = employee.cod || 0;
			const companyDeduction = employee.companyDeduction || 0;
			const overtimeAdditions = employee.overtimeAdditions || 0;
			const sickLeave = employee.sickLeave || 0;
			employee.totalBenefits = (ppp * pickups) + (ppd * deliveries) + (overtimeAdditions || 0) + (sickLeave || 0);
			employee.pickupBenefits = ppp * pickups;
			employee.deliveryBenefits = ppd * deliveries;

			const lateLoginDeduction = parseInt(employee.lateLoginDeduction, 10) || 0;
			const noShow = parseInt(employee.noShow, 10) || 0;
			const penalties = parseInt(employee.penalties, 10) || 0;

			employee.totalDeductions =
				(carRent || 0) +
				(accomodation || 0) +
				(mobile || 0) +
				(settlement || 0) +
				(lateLoginDeduction || 0) +
				(noShow || 0) +
				(penalties || 0) +
				(cod || 0) +
				(companyDeduction || 0);;
			employee.totalPayables = employee.totalBenefits - employee.totalDeductions;

			updatedEmployees[index] = employee;
			return updatedEmployees;
		});
	};

	useEffect(() => {
		calculateTotals();
	}, [employees]);

	const calculateTotals = () => {
		let totalOrders = 0;
		let totalSickLeaves = 0;
		let totalDeductions = 0;
		let totalPayables = 0;
		let totalCompanyDeductionCollection = 0;

		employees?.forEach((employee) => {
			totalOrders += parseInt(employee.pickups) + parseInt(employee.deliveries);
			totalSickLeaves += parseInt(employee.sickLeave) || 0;
			totalDeductions += parseInt(employee.totalDeductions) || 0;
			totalPayables += parseInt(employee.totalPayables) || 0;
			totalCompanyDeductionCollection += parseInt(employee.talabatInvoice) || 0;
		});

		setTotalOrders(totalOrders);
		setTotalSickLeaves(totalSickLeaves);
		setTotalDeductions(totalDeductions);
		setTotalPayables(totalPayables);
		setTotalCompanyDeductionCollection(totalCompanyDeductionCollection);
	};

	const handleTotalClick = () => {
		setTotalClicked(!totalClicked);
	};

	const Tablescroll = () => {
		const container = tableContainerRef.current;
		if (container) {
			const table = container.querySelector('table');
			const element = document.getElementById('blur');
			if (table) {
				if (container.scrollLeft + container.clientWidth >= table.clientWidth - 50) {
					element.className = '';
				} else {
					element.className = 'blur-right';
				}
			}
		}
	};

	const downloadCSV = () => {
		const link = document.getElementById('payroll-file');
		link.click();
	};

	const exportToExcel = () => {
		if (employees.length === 0) {
			message.error('No employees found');
			return;
		}

		// Employee Data Preparation
		const employeeData = employees.map((employee, index) => ({
			slNo: index + 1,
			talabatId: employee.talabatId,
			eCode: employee.eCode,
			talabatName: employee.talabatName,
			ridersName: employee.employeeName,
			qid: employee.qidNumber,
			iban: employee.bankAccountIBAN,
			status: employee.status,
			position: employee.position,
			contract: employee.contract,
			pickups: employee.pickups,
			pickupIncome: { t: 'n', f: `K${index + 2}*${employee.ppp}` },
			dropoffs: employee.deliveries,
			dropoffsRate: employee.ppd,
			dropoffsIncome: { t: 'n', f: `M${index + 2}*N${index + 2}` }, // Formula column
			distancePay: employee.distancePay,
			totalTalabatIncome: { t: 'n', f: `L${index + 2}+O${index + 2}+P${index + 2}` },
			fa: employee.fa,
			sickLeave: employee.sickLeave,
			otAddition: employee.overtimeAdditions,
			other: employee.others,
			totalBenefits: { t: 'n', f: `Q${index + 2}+R${index + 2}+S${index + 2}+T${index + 2}+U${index + 2}` },
			carRental: employee.carRent,
			accommodation: employee.accomodation,
			mobile: employee.mobile,
			settlement: employee.settlement,
			lateLogin: employee.lateLoginDeduction,
			noShow: employee.noShow,
			penalties: employee.penalties,
			cod: employee.cod,
			companyDeduction: employee.companyDeduction,
			totalDeduction: { t: 'n', f: `SUM(W${index + 2}:AE${index + 2})` },
			totalPayables: { t: 'n', f: `V${index + 2}-AF${index + 2}` },
		}));

		// Convert JSON to worksheet
		const ws = XLSX.utils.json_to_sheet(employeeData, {
			header: Object.keys(payrollLabels),
		});

		// Add column headers
		XLSX.utils.sheet_add_aoa(ws, [Object.values(payrollLabels)], { origin: 'A1' });

		// Set column width automatically based on content
		const columnWidths = Object.keys(payrollLabels).map(key => {
			const maxLength = employeeData.reduce((max, employee) => {
				return Math.max(max, employee[key] ? employee[key].toString().length : 0);
			}, key.length);
			return { wch: maxLength + 2 }; // Add extra padding
		});
		ws['!cols'] = columnWidths;

		// Add green header styling
		const headerStyle = {
			font: { bold: true },
			fill: {
				fgColor: { rgb: '00FF00' }, // Green background
			},
		};
		Object.keys(payrollLabels).forEach((key, index) => {
			const cellRef = XLSX.utils.encode_cell({ c: index, r: 0 }); // Cell reference
			ws[cellRef].s = headerStyle; // Apply the style
		});

		// Create a new workbook and append the worksheet
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

		// Write the workbook
		const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

		// Convert the binary string to a Blob and save it
		const buf = new ArrayBuffer(wbout.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i !== wbout.length; ++i) {
			view[i] = wbout.charCodeAt(i) & 0xff;
		}
		const blob = new Blob([buf], { type: 'application/octet-stream' });
		saveAs(blob, `${generateFileName()}-payroll.xlsx`);
	};

	console.log('EMPLOYEE DATA:', employees);

	return (
		<>
			<AdminNavbar />
			<div className='salaries-payroll mt-3'>
				{/* <p className='home-header' style={{ top: '40px', left: '20px' }}>
					<Link to='/admin' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
					Earnings Dashboard
				</p> */}

				{/* {(fileImported || (payrollType === 'Fixed' && isFixedPayroll)) && ( */}
					<div className={`total-section ${totalClicked ? 'clicked' : ''}`} onClick={handleTotalClick}>
						<br /><br />
						<Row gutter={16} style={{ margin: '0' }}>
							<Col span={4}>
								<Card hoverable>
									<Statistic
										title='Total Orders'
										value={totalOrders}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
							<Col span={5}>
								<Card hoverable>
									<Statistic
										title='Total Sick Leaves'
										value={totalSickLeaves}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
							<Col span={5}>
								<Card hoverable>
									<Statistic
										title='Total Deductions'
										value={totalDeductions}
										precision={2}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
							<Col span={5}>
								<Card hoverable>
									<Statistic
										title='Total Payables'
										value={totalPayables}
										precision={2}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
							<Col span={5}>
								<Card hoverable>
									<Statistic
										title='Total Company Deductions'
										value={totalCompanyDeductionCollection}
										precision={2}
										style={{ fontFamily: 'Montserrat' }}
									/>
								</Card>
							</Col>
						</Row>

					</div>
				{/* )} */}
				<div className='d-flex justify-content-between mt-5 mb-3'>
					<input ref={fileInputRef} style={{ display: 'none' }} type="file" onChange={handleFileChange} />


					<CSVLink
						style={{ display: 'none' }}
						id='payroll-file'
						data={[]}
						headers={Object.values(payrollLabels).map((attr) => ({ label: attr, key: attr }))}
						filename={`payroll-${generateFileName()}.csv`}
					></CSVLink>
					<div>
						<MonthPicker
							placeholder="Select a month"
							onChange={handleMonthChange}
							style={{ marginRight: '16px', width: 200 }}
							allowClear={false}
						/>
						<Button onClick={handleSaveClick} disabled={!selectedMonth}>
							Save
						</Button>
						<Select value={payrollType} onChange={handlePayrollTypeChange} style={{ marginLeft: '16px', width: '120px' }}>
							<Option value="PPO">PPO</Option>
							<Option value="PPH">PPH</Option>
							<Option value="PPD">PPD</Option>
							<Option value="Fixed">Fixed</Option>
						</Select>
					</div>
					<div>
						<Tooltip title="Import Salary">
						<Button shape='circle' type="text" icon={<FileAddOutlined style={{ fontSize: '20px' }} />} onClick={handleImportSalaryClick}></Button>
						</Tooltip>
						<Tooltip title="Export to Excel">
						<Button shape='circle' type="text" icon={<DownloadOutlined style={{ fontSize: '20px' }} />} onClick={exportToExcel}></Button>
						</Tooltip>
					</div>
				</div>
				<div style={{ position: 'relative' }}>
							<div className="table-container" ref={tableContainerRef} onScroll={Tablescroll}>
								{/* <table>
									<thead>
										<tr>
											{payrollColumns[payrollType].map((column) => (
												<th key={column.dataIndex}>{column.title}</th>
											))}
										</tr>
									</thead>
									<tbody>
										{filteredEmployees.length === 0 && payrollType === 'Fixed' && (
											<tr>
												{payrollColumns[payrollType].map((column) => (
													<td key={column.dataIndex} style={{ width: column.width }}>
														<Input style={{ width: column.width }} readOnly />
													</td>
												))}
											</tr>
										)}

										{employees.length === 0 && payrollType != 'Fixed' && (
											<tr>
												{payrollColumns[payrollType].map((column) => (
													<td key={column.dataIndex} style={{ width: column.width }}>
														<Input style={{ width: column.width }} readOnly />
													</td>
												))}
											</tr>
										)}


										{payrollType === 'Fixed'
											? filteredEmployees.map((employee, index) => (
												<tr key={index}>
													{payrollColumns[payrollType].map((column) => (
														<td key={column.dataIndex} style={{ width: column.width }}>
															{column.dataIndex === 'customAttributes' ? (
																employee[column.dataIndex].map((attr, attrIndex) => (
																	<div key={attrIndex}>
																		{attr.value}
																	</div>
																))
															) : (
																<Input
																	value={employee[column.dataIndex]}
																	onChange={(event) =>
																		handleInputChange(event, index, column.dataIndex)
																	}
																	style={{ width: column.width }}
																/>
															)}
														</td>
													))}
												</tr>
											))
											: employees.map((employee, index) => (
												<tr key={index}>
													{payrollColumns[payrollType].map((column) => (
														<td key={column.dataIndex} style={{ width: column.width }}>
															{column.dataIndex === 'customAttributes' ? (
																employee[column.dataIndex].map((attr, attrIndex) => (
																	<div key={attrIndex}>
																		{attr.value}
																	</div>
																))
															) : (
																<Input
																	value={
																		(column.dataIndex === "totalPayables" || column.dataIndex === "totalDeductions") &&
																			employee[column.dataIndex] % 1 !== 0
																			? parseFloat(employee[column.dataIndex]).toFixed(2)
																			: employee[column.dataIndex]
																	}
																	onChange={(event) =>
																		handleInputChange(event, index, column.dataIndex)
																	}
																	style={{ width: column.width }}
																/>
															)}
														</td>
													))}
												</tr>
											))}


									</tbody>

								</table> */}
								<Table
									pagination={{
										pageSize: 100,
										current: currentPage,
										onChange: (page) => setCurrentPage(page)
									}}
									dataSource={employees}
									// loading={employees.length === 0 ? true : false}
									// columns={payrollColumns[payrollType]}
									columns={mergedColumns}
									// rowKey="key"
									components={{
										body: {
											cell: EditableCell,
										},
									}}
									rowKey="_id"
									scroll={{
										x: 'max-content',
										y: `calc(80vh - 250px)`
									}}
								/>
								<div id='blur' className=""></div>
							</div>
						</div>
			</div>
		</>
	);
};

export default SalariesAndPayroll;
