import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';
import { PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';
import { DatePicker, FloatButton, Input, Tooltip } from 'antd';
import Navbar from '../components/Navbar';
const { TextArea } = Input;

const EmployeeData = () => {
    const [logoComponent, setLogoComponent] = useState(null);

    // useEffect(() => {
    // 	fetchAndDisplayLogo().then((logo) => {
    // 		setLogoComponent(logo);
    // 	});
    // }, []);

    const handleDownload = async () => {
        const pdfElement = document.getElementById('section-to-print');

        if (!pdfElement) {
            console.error('PDF element not found.');
            return;
        }

        const options = {
            margin: 0,
            filename: 'employee-data.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(pdfElement).set(options).save();
    };

    return (
        <>
            <div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
                <Navbar />
            </div>
            <div className="center-container" style={{paddingTop: 120}}>
                <div className="disciplinary-action-form">
                    <div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
                        <div className='form-container employee-data-form' id='section-to-print' style={{ border: '1px solid black', padding: '10px' }}>
                            <table className="section-header">
                                <td style={{ width: '20%' }}>{logoComponent}</td>
                                <td style={{ width: '60%' }}><h3 style={{ border: 'none', marginBottom: '0' }}>Employee Data Form</h3></td>
                                <td style={{ width: '20%' }}></td>
                            </table>
                            <hr className='hr-line' style={{ marginTop: '20px', marginBottom: '0px' }}></hr>
                            <div className="form-fields salary-fields">
                                <div>
                                    <label>Employee Name:</label>
                                    <TextArea autoSize style={{ marginLeft: '20px', width: '50%' }} type="text" />
                                </div>
                                <div className="">
                                    <label>Employee Code:</label>
                                    <Input style={{ marginLeft: '28px', width: '50%', borderRadius: 0, textAlign: 'center' }} type="text" />
                                </div>
                                <div className="">
                                    <label>Department:</label>
                                    <TextArea autoSize style={{ marginLeft: '50px', width: '50%' }} type="text" />
                                </div>
                                <div className="">
                                    <label>Position:</label>
                                    <TextArea autoSize style={{ marginLeft: '85px', width: '50%' }} type="text" />
                                </div>
                            </div>
                            <h6 style={{ fontWeight: 'bolder', marginTop: '20px' }}>Section I: Personal Details</h6>
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                            <div className="row form-fields" style={{ display: 'flex' , flexWrap: 'wrap', rowGap: 10}}>
                                <div className="w-50">
                                    <label>Date of Birth:</label>
                                    <DatePicker style={{ marginLeft: '20px', width: '50%' }} />
                                </div>
                                <div className="w-50" style={{ display: 'flex' }}>
                                    <label>Gender:</label>
                                    <div className="checkbox-group d-flex align-items-center">
                                        <label style={{ paddingLeft: '30px' }}>
                                            <input
                                                className='me-2'
                                                type="checkbox"
                                            />
                                            Male
                                        </label>
                                        <label style={{ marginLeft: '20px' }}>
                                            <input
                                                className='me-2'
                                                type="checkbox"
                                            />
                                            Female
                                        </label>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }} className="w-50">
                                    <label>Marital Status:</label>
                                    <div style={{ fontSize: 'small', marginLeft: '10px' }} className="checkbox-group">
                                        <label>
                                            <input
                                                className='me-1'
                                                type="checkbox"
                                            />
                                            Married
                                        </label>
                                        <label style={{ marginLeft: '5px' }}>
                                            <input
                                                className='me-1'
                                                type="checkbox"
                                            />
                                            Single
                                        </label>
                                        <label style={{ marginLeft: '5px' }}>
                                            <input
                                                className='me-1'
                                                type="checkbox"
                                            />
                                            Others
                                        </label>
                                    </div>
                                </div>
                                <div className="w-50">
                                    <label>Nationality:</label>
                                    <TextArea autoSize style={{ marginLeft: '20px', width: '63%' }} type="text" />
                                </div>
                                <div className="w-50">
                                    <label>Blood Group:</label>
                                    <Input style={{ marginLeft: '20px', width: '70%', borderRadius: 0, textAlign: 'center' }} type="text" />
                                </div>
                                <div className="w-50">
                                    <label>Religion:</label>
                                    <TextArea autoSize style={{ marginLeft: '42px', width: '65%' }} type="text" />
                                </div>
                                <div className="">
                                    <label>Home Country Address:</label>
                                    <TextArea autoSize style={{ marginLeft: '20px', width: '69%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex">
                                    <label>Home Country Phone Number:</label>
                                    <TextArea autoSize style={{ marginLeft: '5px', width: '85%' }} type="text" />
                                </div>
                                <div className="w-50">
                                    <label>Mobile Number:</label>
                                    <TextArea autoSize style={{ marginLeft: '10px', width: '56%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Passport Number:</label>
                                    <TextArea autoSize style={{ marginLeft: '18px', width: '60%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Passport Expiry Date:</label>
                                    <DatePicker style={{ marginLeft: '10px', width: '43%' }} />
                                </div>
                                <div className="col-md-12  ">
                                    <label>Personal Email ID:</label>
                                    <TextArea autoSize style={{ marginLeft: '23px', width: '75%' }} type="text" />
                                </div>
                            </div>
                            <br></br>
                            <h6 style={{ fontWeight: 'bolder' }}>Section II: Emergency Contact Details</h6>
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                            <div className="row form-fields" style={{ display: 'flex' , flexWrap: 'wrap', rowGap: 5}}>
                                <div className="w-50 d-flex align-items-center">
                                    <label style={{ width: '50%' }} >Contact Person in case of Emergency:</label>
                                    <TextArea autoSize style={{ width: '50%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Relationship with Employee:</label>
                                    <TextArea autoSize style={{ marginLeft: '10px', width: '80%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Emergency Address:</label>
                                    <TextArea autoSize style={{ marginLeft: '35px', width: '100%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Emergency Contact Number:</label>
                                    <TextArea autoSize style={{ marginLeft: '10px', width: '70%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Beneficiary Name:</label>
                                    <TextArea autoSize style={{ marginLeft: '50px'}} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Beneficiary Relation with Employee:</label>
                                    <TextArea autoSize style={{ marginLeft: '0px', width: '85%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Beneficiary Address:</label>
                                    <TextArea autoSize style={{ marginLeft: '35px'}} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Beneficiary Contact Number:</label>
                                    <TextArea autoSize style={{ marginLeft: '10px', width: '85%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Beneficiary Bank Account Number:</label>
                                    <TextArea autoSize style={{ marginLeft: '0px', width: '85%' }} type="text" />
                                </div>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Beneficiary Bank Branch:</label>
                                    <TextArea autoSize style={{ marginLeft: '10px' }} type="text" />
                                </div>
                            </div>
                            <br></br>
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                            <h6 style={{ fontWeight: 'bolder' }}>Section III: For HR use only</h6>
                            <hr className='hr-line' style={{ marginTop: '10px', marginBottom: '10px' }}></hr>
                            <div className="row form-fields" style={{ display: 'flex' }}>
                                <div className="w-50 d-flex align-items-center">
                                    <label>Joining Date:</label>
                                    <DatePicker style={{ marginLeft: '20px', width: '62%' }} />
                                </div>
                                <div className="w-50 d-flex align-items-center" style={{ display: 'flex' }}>
                                    <label>Passport Received:</label>
                                    <div className="checkbox-group">
                                        <label style={{ paddingLeft: '30px' }}>
                                            <input
                                                type="checkbox"
                                            />
                                            Yes
                                        </label>
                                        <label style={{ marginLeft: '20px' }}>
                                            <input
                                                type="checkbox"
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='manager-sign' style={{ marginTop: '20px', marginBottom: '30px' }}>
                                <div>
                                    <br></br>
                                    <hr className='manager-hr'></hr>
                                    <h6 style={{ textAlign: 'center', fontWeight: 'bolder' }}>Employee Signature</h6>

                                </div>
                            </div>
                            <div className='note' style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <h6 className='p-0'><strong>Note:</strong> By signing the above form, you certify that the information provided is True and under your full responsibility.</h6>
                            </div>

                        </div>
                        {/* <h4 style={{ fontWeight: 'bolder' }}>HR Form No: 05</h4> */}
                    </div>
                    {/* <Tooltip title="Print" placement='right'>
                        <FloatButton
                            icon={<PrinterOutlined />}
                            onClick={() => window.print()}
                            style={{
                                right: 100,
                            }}
                        />
                    </Tooltip> */}
                    <Tooltip title="Download" placement='top'>
                        <FloatButton
                            icon={<DownloadOutlined />}
                            onClick={handleDownload}
                            style={{
                                right: 150,
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
        </>
    );
};

export default EmployeeData;
