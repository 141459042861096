import React, {useEffect, useState} from 'react';
import html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';

const AnnualTraining = () => {
    const numRows = 6;
    const [logoComponent, setLogoComponent] = useState(null);

	useEffect(() => {
		fetchAndDisplayLogo().then((logo) => {
			setLogoComponent(logo);
		});
	}, []);

    const handleDownload = async () => {
        const pdfElement = document.querySelector('.local-leave-container');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'annual-training-form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
    };

    return (
        <div className="center-container">
            <p className='home-header' style={{position:'fixed'}}>
                <Link to='/admin/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p>
            <div className="disciplinary-action-form">
                <div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
                    <div style={{ fontSize: 'small' }}>
                        <table className="section-header">
                            <tr>
                                <td style={{ width: '15%' }}>{logoComponent}</td>
                                <td style={{ width: '39%', textAlign: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#F5F5F5', paddingTop: '15px' }}><label style={{ fontWeight: '700' }}>SWIFT DELIVERY</label></div>

                                    <td style={{ display: 'flex', justifyContent: 'center', border: 'none', borderTop: '1px solid ', paddingBottom: '15px' }}><label style={{ fontWeight: '700' }}>ANNUAL TRAINING PLAN</label></td>

                                </td>
                                <td>
                                    <table className="leave-table">
                                        <tr>
                                            <td style={{ borderCollapse: 'collapse' }}>
                                                <table className="leave-table">
                                                    <tr>
                                                        <td style={{ borderCollapse: 'collapse' }} >
                                                            <label >Document No: SD-HR-14</label>
                                                        </td>
                                                        <td style={{ borderCollapse: 'collapse' }}>
                                                            <label>page 1 of 1</label>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <table className="leave-table">
                                                <tr>
                                                    <td style={{ width: '35%', borderCollapse: 'collapse' }} >
                                                        <label>Issue No. 00</label>
                                                    </td>
                                                    <td style={{ borderCollapse: 'collapse' }} >
                                                        <label>Rev No. 00 </label>
                                                        <label>Dated:</label>
                                                        <input style={{ width: '20%' }} type="text" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                        </table>
                        <br></br>
                        <table className="leave-table" style={{ fontSize: 'x-small' }} >
                            <tr style={{border:'none'}}>
                                <td style={{ backgroundColor: '#F5F5F5' }} rowSpan={2}>
                                    <input  style={{ backgroundColor: '#F5F5F5' }}  readOnly value='Sr.No.'></input>
                                </td>
                                <td style={{ backgroundColor: '#F5F5F5' }} rowSpan={2}>
                                    <input style={{ backgroundColor: '#F5F5F5' }} value='Training Topic' readOnly/>
                                </td>
                                <td style={{ backgroundColor: '#F5F5F5' }} rowSpan={2}>
                                    <textarea style={{ backgroundColor: '#F5F5F5', border:'none', width:'90px', resize:'none', overflow:'hidden' }} value='Training Plan/ Status' readOnly/>
                                </td>
                                <td colSpan={12} style={{ background: '#a6ffc8', textAlign: 'center' }}><label >For the Year:</label></td>
                            </tr>
                            <tr>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >Jan</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >Feb</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >Mar</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >Apr</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >May</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >Jun</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >Jul</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >Aug</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >Sep</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label>Oct</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label>Nov</label>
                                </td>
                                <td style={{ backgroundColor: '#b1ffe3' }}>
                                    <label >Dec</label>
                                </td>
                            </tr>
                            {Array.from({ length: numRows }).map((_, index) => (
                                <React.Fragment key={index}>
                                    <tr className='training-row'>
                                        <td rowSpan={2}>
                                            <input style={{width:'30px'}} readOnly value={index + 1}></input>
                                        </td>
                                        <td rowSpan={2}>
                                            <textarea />
                                        </td>
                                        <td >
                                            <label >Plan(P)</label>
                                        </td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                    </tr>
                                    <tr className='training-row'>
                                        <td>
                                            <label >Complete(C)</label>
                                        </td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                        <td ><input type="text" /></td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </table>
                        Approved By:
                        <br/><br/>
                        <input type="text" style={{border:'none', borderBottom:'1px solid black'}}/>
                        <br/>
                        (HR Manager)
                        <br/><br/><br/>
                        <p style={{fontSize:'7px', fontWeight:'500'}}>Note:</p>
                        <div style={{display:'flex', justifyContent:'space-between', fontSize:'7px', fontWeight:'500'}}>
                            <div>
                                1. MINIMUM NOMINATIONS WILL BE REQUIRED FOR CERTAIN COURSES TO BEGIN.<br/>
                                3. CUSTOMIZED PROGRAMS TO MEET REQUIRTMENTS ARE ALSO OFFERED.
                            </div>
                            <div>
                                2. THE DATES FOR THE TRAINING PROGRAMS ARE TENTATIVE AND COULD BE CHANGED.<br/>
                                4. PRODUCT TRAINING PLANS ARE TENTATIVE, THE TRAININGS ARE DONE AS PER PRINCIPALS AVAILABILITY
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-group">
                    <button className="download-button" onClick={handleDownload}>
                        Download
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AnnualTraining;
