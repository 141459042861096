import React, { useEffect, useState } from 'react';
import './SickLeave.css';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';

const TravelAgency = () => {
    const [logoComponent, setLogoComponent] = useState(null);

	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);

    const handleDownload = async () => {
        const pdfElement = document.querySelector('.local-leave-container');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'travel-agency-form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
    };

    return (
        <div className="center-container">
            <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p>
            <div className="disciplinary-action-form">
                <div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
                    <div className='form-container' style={{ padding: '0' }}>

                        <table className="leave-table">
                            <tr>
                                <td style={{ width: '55%', paddingLeft:'20px' }}>
                                    {logoComponent}
                                    <h4>TEL: 4001 0192</h4>
                                    <h4>P.O BOX 35070</h4>
                                    <h4>Doha, Qatar</h4>
                                </td>
                                <td>
                                    <table className="leave-table">
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                <label>PURCHASE ORDER No.</label>
                                                <input style={{ width: '20%', border: 'none' }} type="text" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                <label>Please state this PO. No. on all bills, invoices and correspondences</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                <label>QUOTATION NO.</label>
                                                <input style={{ width: '20%', border: 'none' }} type="text" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px' }}>
                                                <label>PURCHASE DATE</label>
                                                <input style={{ width: '20%', border: 'none' }} type="text" />
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                        </table>
                        <table className="leave-table">
                            <tr>
                                <td style={{ padding: '10px' }}>
                                    <label>
                                        SUPPLIER M/S: Regency Travels
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <table className="leave-table" style={{ border: 'none' }}>
                                    <tr>
                                        <td>
                                            <label>NO.</label>
                                        </td>
                                        <td>
                                            <label>QTY</label>
                                        </td>
                                        <td>
                                            <label>UNIT</label>

                                        </td>
                                        <td>
                                            <label>DESCRIPTION</label>

                                        </td>
                                        <td>
                                            <label>
                                                UNIT PRICE
                                            </label>

                                        </td>
                                        <td>
                                            <label>AMOUNT</label>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: '5%' }}>
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                        <td style={{ width: '5%' }} >
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                        <td style={{ width: '15%' }} >
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                        <td>
                                            <h6>
                                                Booking for:
                                            </h6>
                                            <input style={{ border: 'none' }} type="text" />
                                            <h6>
                                                Flight details:
                                            </h6>
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                        <td style={{ width: '20%' }} >
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                        <td style={{ width: '20%' }} >
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td style={{ border: 'none' }}></td>
                                        <td><lable>NET:</lable></td>
                                        <td><input style={{ border: 'none' }} type="text" /></td>
                                    </tr>
                                </table>
                            </tr>
                        </table>
                        <table className="leave-table">

                            <tr>
                                <td style={{ padding: '10px' }}>
                                    <label>SPECIAL INSTRUCTIONS:</label>
                                </td>
                            </tr>
                            <tr>
                                <table className="leave-table" style={{ border: 'none' }}>
                                    <tr>
                                        <td style={{ padding: '10px' }}>
                                            <label>Prepared By:</label>
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                        <td style={{ padding: '10px' }}>
                                            <label>Authorized Signatory:</label>
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                        <td style={{ padding: '10px' }}>
                                            <label>Finance/Accounts:</label>
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                        <td style={{ padding: '10px' }}>
                                            <label>Director:</label>
                                            <input style={{ border: 'none' }} type="text" />
                                        </td>
                                    </tr>
                                </table>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="button-group">
                    <button className="download-button" onClick={handleDownload}>
                        Download
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TravelAgency;
