
import { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Divider, Dropdown, Layout, Menu, Modal, Row, Statistic, message } from 'antd';
import { BarChart } from '@mui/x-charts/BarChart';
import './ManagerRouter.css'; // Import the CSS file
import CountUp from 'react-countup';
import WorldMap from "react-svg-worldmap";
import Confetti from "react-confetti";
import { PlusOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import AdminNavbar from './components/AdminNavbar';
import { IsoCodes, employeeAttributes } from './components/Data';
import { CSVLink } from 'react-csv';
import moment from 'moment';

const formatter = (value) => <CountUp end={value} separator="," />;


const StatCard = ({ title, value, icon, route, iconType, children }) => {
	return (

		<Link className='stat-card' to={route}>
			<div>
				<h3>{value}</h3>
				<h4>{title}</h4>
			</div>
			<div className='icon-container'>
				{children}
				{icon &&
					<img src={icon} alt="" />
				}
			</div>
		</Link>
	);
}

const ClientRouter = () => {
	const [adminEmail, setAdminEmail] = useState('');
	const [open, setOpen] = useState(false);
	const [barOpen, setBarOpen] = useState(false);
	const [companyName, setCompanyName] = useState('');
	const [nullCount, setNullCount] = useState(0);
	const [vacations, setVacations] = useState(0);
	const [employees, setEmployees] = useState([]);
	const [arrivals, setArrivals] = useState(0);
	const [statusCounts, setStatusCounts] = useState({});
	const [positions, setPositions] = useState([]);
	const [expiredDocs, setExpiredDocs] = useState(0);
	const [emptyFiles, setEmptyFiles] = useState(0);
	const [nationalities, setNationalities] = useState([]);
	const [companyId, setCompanyId] = useState(null);
	const [darkMode, setDarkMode] = useState(false);
	const [expiryCount, setExpiryCount] = useState(0);
	const showAnnouncement = sessionStorage.getItem('showAnnouncement') !== 'true';
	const [showAnnouncementModal, setShowAnnouncementModal] = useState(showAnnouncement);
	const [positionCounts, setPositionCounts] = useState([]);
	const [positionNames, setPositionNames] = useState([]);

	const formatDatesInArray = (arr) => {
		return arr.map(item => {
			let formattedItem = { ...item };
			formattedItem['companyName'] = companyName;
			for (let key in formattedItem) {
				if (formattedItem.hasOwnProperty(key) && key.endsWith('Date')) {
					let value = formattedItem[key];
					if (moment(value, moment.ISO_8601, true).isValid()) {
						formattedItem[key] = moment(value).format('DD-MM-YYYY');
					}
				}
			}
			return formattedItem;
		});
	};

	const items = [
		{
			key: '0',
			label: (
				<a onClick={() => document.getElementById('all-employees')?.click()}>
					All
				</a>
			),
		},
		{
			key: '1',
			label: (
				<a onClick={() => document.getElementById('active-employees')?.click()}>
					Active
				</a>
			),
		},
		{
			key: '2',
			label: (
				<a onClick={() => document.getElementById('sick-employees')?.click()}>
					Sick/Accidented
				</a>
			),
		},
		{
			key: '3',
			label: (
				<a onClick={() => document.getElementById('inactive-employees')?.click()}>
					In Active
				</a>
			),
		},

		{
			key: '4',
			label: (
				<a onClick={() => document.getElementById('terminated-employees')?.click()}>
					Terminated/Resigned
				</a>
			),
		},
		{
			key: '5',
			label: (
				<a onClick={() => document.getElementById('vacation-employees')?.click()}>
					Vacation
				</a>
			),
		},

	];


	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				if (data.company && data.company._id) {
					const companyId = data.company._id;
					const companyName = data.company.companyName;
					setCompanyName(companyName);
					setCompanyId(companyId);
					// fetchDepartments(companyName)
				} else {
					message.error('Company ID not found in the response');
				}
			} else {
				message.error(data.error || 'Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};
	useEffect(() => {
		fetchCompany();
	}, []);

	useEffect(() => {
		const storedAdminEmail = localStorage.getItem('adminEmail');
		if (storedAdminEmail) {
			const extractedName = storedAdminEmail.split('@')[0];
			const capitalizedName = extractedName.charAt(0).toUpperCase() + extractedName.slice(1);
			setAdminEmail(capitalizedName);
		}
	}, []);


	const currentDate = new Date();
	const formattedDate = currentDate.toLocaleDateString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});

	const handleDarkMode = () => {
		const element = document.querySelector('.App');

		if (darkMode)
			element.classList.remove('dark-mode');
		else
			element.classList.add('dark-mode');
		setDarkMode(!darkMode);
	}

	const fetchEmployees = useCallback(async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getEmployeesByCompany/${companyId}`, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			if (response.ok) {
				const emps = formatDatesInArray(data)
				setEmployees(emps);

				const active = data.filter(employee => employee.status !== 'Terminated/Resigned');
				const nullemp = active.filter(employee => {
					return Object.entries(employee).some(([key, value]) => {
						return key !== 'workTenure' && (value === null || value === undefined || value === '');
					});
				})
				setNullCount(nullemp.length)
				// // console.log(nullemp)

				const empties = active.filter(employee => {
					return employee.personalFiles.length === 0;
				});
				setEmptyFiles(empties.length);

				const distinctPositions = [...new Set(data.map(employee => employee.position))];
				setPositions(distinctPositions);

				const positionCounts = active.reduce((acc, curr) => {
					if (curr.position in acc) {
						acc[curr.position]++;
					} else {
						acc[curr.position] = 1;
					}
					return acc;
				}, {});
				console.log('Position counts:', positionCounts);
				const posData = Object.keys(positionCounts).map(pos => ({
					name: pos,
					count: positionCounts[pos]
				}));
				setPositions(posData)
				console.log('Names:', Object.keys(positionCounts));
				console.log('Counts:', Object.values(positionCounts));
				setPositionNames(Object.keys(positionCounts))
				setPositionCounts(Object.values(positionCounts))
				const nationalityCounts = active.reduce((acc, curr) => {
					const nat = curr.nationality?.toLowerCase().trim()
					const iso = nat in IsoCodes ? IsoCodes[nat] : null;

					if (iso in acc) {
						acc[iso]++;
					} else {
						acc[iso] = 1;
					}
					return acc;
				}, {});

				const nationalityData = Object.keys(nationalityCounts).map(iso => ({
					country: iso,
					value: nationalityCounts[iso]
				}));
				setNationalities(nationalityData)

				const today = new Date();
				const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

				let count = 0, arrivals = 0, vacations = 0;

				active.forEach(doc => {
					if (doc.healthCardExpiryDate && new Date(doc.healthCardExpiryDate) < today) count++;
					if (doc.healthCertificateExpiryDate && new Date(doc.healthCertificateExpiryDate) < today) count++;
					if (doc.passportExpiryDate && new Date(doc.passportExpiryDate) < today) count++;
					if (doc.qidExpiryDate && new Date(doc.qidExpiryDate) < today) count++;
					if (doc.visaExpiryDate && new Date(doc.visaExpiryDate) < today) count++;

					const hasUpcomingVacation = doc.vacations.some(vacation => {
						const vacationStartDate = new Date(vacation.startDate);
						return vacationStartDate >= today && vacationStartDate < endOfMonth;
					});

					const upcomingArrival = doc.vacations.some(vacation => {
						const vacationEndDate = new Date(vacation.endDate);
						return vacationEndDate >= today && vacationEndDate < endOfMonth;
					});
					if (hasUpcomingVacation) {
						vacations++;
					}

					if (upcomingArrival) {
						arrivals++;
					}
				});

				setExpiryCount(count);
				setArrivals(arrivals);
				setVacations(vacations);

				setExpiredDocs(count);

				const groupEmployeesByStatus = emps.reduce((acc, employee) => {
					const status = employee.status;
					if (!acc[status]) {
						acc[status] = [];
					}
					acc[status].push(employee);
					return acc;
				}, {});
				setStatusCounts(groupEmployeesByStatus);
			} else {
				message.error(data.message || 'Failed to fetch employees');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch employees');
		}
	}, [companyId]); // Add fetchDepartments as a dependency

	useEffect(() => {
		if (companyId) {
			fetchEmployees(); // Call it from here only if companyId is not null
		}
	}, [fetchEmployees, companyId]);

	const closeAnnouncement = () => {
		setShowAnnouncementModal(false);
		sessionStorage.setItem('showAnnouncement', 'true');
	}

	const [time, setTime] = useState(new Date());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime(new Date());
		}, 1000);

		// Cleanup the interval on component unmount
		return () => clearInterval(intervalId);
	}, []);

	const getOrdinalSuffix = (day) => {
		if (day > 3 && day < 21) return 'th'; // handles 11th to 19th
		switch (day % 10) {
			case 1: return 'st';
			case 2: return 'nd';
			case 3: return 'rd';
			default: return 'th';
		}
	};

	function getGreeting() {
		const currentHour = new Date().getHours();

		if (currentHour >= 0 && currentHour < 12) {
			return 'Good Morning';
		} else if (currentHour >= 12 && currentHour < 17) {
			return 'Good Afternoon';
		} else {
			return 'Good Evening';
		}
	}

	const maxValue = Math.max(...positionCounts);
	const colors = positionCounts.map(value => value !== maxValue ? '#DAEEF0' : '#0F6972');

	return (
		<Layout className='manager-container manager-grid flex-column'>
			<AdminNavbar />
			{/* {showAnnouncement && 
				<Confetti width={window.innerWidth} height={window.innerHeight} recycle={false} />
			}
			<Modal open={showAnnouncementModal} onCancel={closeAnnouncement} width={480} footer={null} className='announcement-modal'>
				<img src="HRMSLUNCHPOPUP.png" alt="" width={400}/>
			</Modal> */}

			<div className="stats" style={{ flex: 1, backgroundColor: '#EEF5F2', display: 'table' }}>
				<div className='home-container' style={{ transform: 'scale(0.7)', transformOrigin: 'left', marginLeft: '6vw', width: '125%' }}>
					<div style={{ position: 'relative' }}>
						<div className='overlay'>
							<img draggable={false} src="/bulboff.png" alt="" />
						</div>
						<div className='p-5 h-100' style={{ zIndex: 2, position: 'relative' }}>
							<h5>{time.toLocaleTimeString()}</h5>
							<h4>
								{`${time.getDate()}${getOrdinalSuffix(time.getDate())} `}
								{time.toLocaleDateString("en-GB", { month: 'long', year: 'numeric' })}
							</h4>

							<h2>{getGreeting()}, {adminEmail}</h2>
						</div>


					</div>
					<div className='card-container'>
						<StatCard value={employees.length} title="Total Employees" icon="stat1.png" />
						<StatCard value={expiryCount} formatter={formatter} title="Expired Documents">
							<ClockCircleOutlined style={{ fontSize: '24px', color: '#131DFF' }} />
						</StatCard>
						<StatCard value={nullCount} formatter={formatter} title="Missing Data">
							<ExclamationCircleOutlined style={{ fontSize: '24px', color: '#F50000' }} />
						</StatCard>
						<StatCard value={emptyFiles} formatter={formatter} title="Without Files" icon="stat4.png" />
						<StatCard value={arrivals} formatter={formatter} route='/admin/analysis/vacation-reports' title="Arrivals" icon="stat5.png" />
						<StatCard value={vacations} formatter={formatter} route='/admin/analysis/vacation-reports' title="Vacations" icon="stat6.png" />
					</div>
				</div>

				<div className='graph-container' style={{ marginTop: '0', transformOrigin: 'left top', marginLeft: '6vw', marginRight: '7vw' }}>
					<div style={{ flex: 0 }}>
						<WorldMap
							color="#63d9a4"
							value-suffix="Employees"
							size="lg"
							data={nationalities}
						/>
					</div>
					<div className='p-4 pe-0'>
						<h3 className='ps-4'>Employee Overview</h3>
						<BarChart
							xAxis={[{
								scaleType: 'band',
								data: positionNames,
								tickPlacement: 'middle',
								tickLabelPlacement: 'tick',
								categoryGapRatio: 0.4,
								colorMap: {
									type: 'ordinal',
									colors: colors
								}
							}]}
							grid={{ horizontal: true }}
							series={[{ data: positionCounts }]}
							width={400}
							bottomAxis={null}
							height={300}
							borderRadius={10}
						/>
					</div>
					<div className='p-4'>
						<h3>Shortcuts</h3>
						<div className='shortcuts'>
							<div>
								<span><PlusOutlined /></span>
								<Link to='/admin/hr-forms/leave-application'>Apply for Vacation</Link>
							</div>
							<div>
								<span><PlusOutlined /></span>
								<Link to='/admin/hr-forms/employment-termination'>Apply for Termination</Link>
							</div>
							<div>
								<span><PlusOutlined /></span>
								<Link to='/admin/hr-forms/warning-letter'>Issue Warning Letter</Link>
							</div>
							<div>
								<span><PlusOutlined /></span>
								<Link to='/admin/hr-forms/rejoining-form'>Issue Rejoining Form</Link>
							</div>
							<div>
								<span><PlusOutlined /></span>
								<Dropdown
									menu={{ items }}
									placement='bottom'
								>
									<Link>View/Download Excel Reports</Link>
								</Dropdown>
							</div>
						</div>
					</div>
				</div>
			</div>

			{statusCounts.Active && <CSVLink
				style={{ display: 'none' }}
				id='active-employees'
				data={statusCounts.Active}
				headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
				filename='active-employees.csv'
			></CSVLink>}
			{statusCounts['Sick/Accidented'] &&
				<CSVLink
					style={{ display: 'none' }}
					id='sick-employees'
					data={statusCounts['Sick/Accidented']}
					headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
					filename='sick/accidented-employees.csv'
				></CSVLink>
			}
			{statusCounts['In-Active'] &&
				<CSVLink
					style={{ display: 'none' }}
					id='inactive-employees'
					data={statusCounts['In-Active']}
					headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
					filename='In-Active-employees.csv'
				></CSVLink>
			}
			{statusCounts['Terminated/Resigned'] &&
				<CSVLink
					style={{ display: 'none' }}
					id='terminated-employees'
					data={statusCounts['Terminated/Resigned']}
					headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
					filename='Terminated/Resigned-employees.csv'
				></CSVLink>
			}
			{statusCounts['Vacation'] &&
				<CSVLink
					style={{ display: 'none' }}
					id='vacation-employees'
					data={statusCounts['Vacation']}
					headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
					filename='Vacation-employees.csv'
				></CSVLink>
			}
			{employees.length > 0 &&
				<CSVLink
					style={{ display: 'none' }}
					id='all-employees'
					data={employees}
					headers={employeeAttributes.map((attr) => ({ label: attr, key: attr }))}
					filename='all-employees.csv'
				></CSVLink>
			}
		</Layout>
	);
};

export default ClientRouter;
