import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddEmployee.css';
import AdminNavbar from './components/AdminNavbar';

const AddEmployee = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [adminCompany, setAdminCompany] = useState(null);
	const [customAttributes, setCustomAttributes] = useState([]);
	const [cn, scn] = useState(null);
	const fetchAdminCompany = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			// console.log('Admin Company Data:', data);

			if (response.ok) {
				const companyName = data.company.companyName;
				setAdminCompany(companyName);
				scn(cn);//setting company name to be used in add employee
				// console.log(companyName);
				return companyName; // Return the company name directly
			} else {
				console.error('Failed to fetch admin company:', data.error);
				return Promise.reject(data.error);
			}
		} catch (error) {
			console.error('Error:', error);
			// throw error;
		}
	};


	const fetchCustomAttributes = async (companyId) => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getCustomAttributes/${companyId}`, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
				},

			});
			const data = await response.json();
			// console.log('Custom Attributes Data:', data);
			if (response.ok) {
				if (Array.isArray(data)) {
					return data;
				} else {
					console.error('Expected an array for custom attributes, but got:', data);
					return [];
				}

			} else {
				console.error('Failed to fetch custom attributes:', data.error);
				// throw new Error(data.error);
			}
		} catch (error) {
			console.error('Error:', error);
			// throw error;
		}
	};




	useEffect(() => {
		fetchAdminCompany()
			.then((companyName) => {
				setAdminCompany(companyName);
			})
			.catch((error) => {
				console.error('Error:', error);
				// Handle the error
			});
	}, []);

	useEffect(() => {
		if (adminCompany) {
			fetchCustomAttributes(adminCompany) // Pass the adminCompany value here
				.then((customAttrs) => {
					setCustomAttributes(customAttrs);
					// console.log('Custom Attributes:', customAttrs);
				})
				.catch((error) => {
					console.error('Error:', error);
					// Handle the error
				});
		}
	}, [adminCompany]);


	const handleAddEmployee = async (values) => {
		try {
			const token = localStorage.getItem('token');
			let payload = { ...values };

			// Create the customAttributes array based on the form values
			const customAttributesData = customAttributes.map((attr) => ({
				customAttrId: attr._id,
				name: attr.name,
				value: values[attr._id], // Use the custom attribute ID as the form field name
			}));

			// Assign customAttributes to payload.customAttributes field
			payload.customAttributes = customAttributesData;

			const response = await fetch('https://hrms-5u7j.onrender.com/admin/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ ...payload, companyName: adminCompany }),
			});
			
			if (response.ok) {
				const data = await response.json();
				message.success(data.message);
				navigate('/admin/employeeDataDashboard');
			} else {
				console.error('Response status:', response.status);
				const data = await response.json();
				console.error('Server error message:', data.error);
				message.error(data.error || 'Failed to add employee');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to add employee');
		}
	};


	return (
		<div>
			<AdminNavbar />
			<div className='px-5 pt-5'>
				<Form form={form} onFinish={handleAddEmployee} layout="vertical" className='employee-form'>
					{/* <h2 style={{fontFamily:'Montserrat'}}>Add Employee</h2><br/> */}
					<Row gutter={16}>
						<Col span={6}>
							{adminCompany !== null && (
								<Form.Item name="company" initialValue={adminCompany}>
									<Input placeholder="Company" disabled  size='large'/>
								</Form.Item>
							)}
						</Col>
						<Col span={6}>
							<Form.Item
								name="eCode"
								rules={[{ required: true, message: 'Please enter the E-Code' }]}
							>
								<Input placeholder="E-Code" size='large'/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="refNo">
								<Input placeholder="Reference Number"  size='large'/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="refName">
								<Input placeholder="Reference Name" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="employeeName">
								<Input placeholder="Employee Name" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="personalNumber">
								<Input placeholder="Personal Number" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="status">
							<Select placeholder="Status" size='large' >
								<Select.Option value="Active">Active</Select.Option>
								<Select.Option value="Sick/Accidented">Sick/Accidented</Select.Option>
								<Select.Option value="Vacation"> Vacation</Select.Option>
								<Select.Option value="In-Active">In-Active</Select.Option>
								<Select.Option value="Terminated/Resigned">Terminated/Resigned</Select.Option>
									</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="hiringDate">
								<DatePicker placeholder="Hiring Date" style={{ width: '100%' }}  size='large'/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="startingDate">
								<DatePicker placeholder="Job Starting Date" style={{ width: '100%' }} size='large' />
							</Form.Item>
						</Col>
					 	<Col span={6}>
								<Form.Item name="nationality"  >
								<Input placeholder="Nationality" size='large' />
								</Form.Item>
							</Col>
						
						
							<Col span={6}>
								<Form.Item name="qidNumber"  >
								<Input placeholder="QID Number" size='large' />
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name="qidExpiryDate"  >
								<DatePicker placeholder="QID Expiry Date" style={{ width: '100%' }} size='large' />
								</Form.Item>
							</Col> 
						<Col span={6}>
							<Form.Item name="driverLicenseType">
								<Input placeholder="Driver License Type" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="driverLicenseExpiry">
								<DatePicker placeholder="Driver License Expiry" size='large' style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="passportNumber">
								<Input placeholder="Passport Number" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="passportExpiryDate">
								<DatePicker placeholder="Passport Expiry Date" size='large' style={{ width: '100%' }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="visaNumber">
								<Input placeholder="Visa Number" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="visaExpiryDate">
								<DatePicker placeholder="Visa Expiry Date" style={{ width: '100%' }} size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="contract">
								<Input placeholder="Contract" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="bankAccountIBAN">
								<Input placeholder="Bank Account IBAN" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="lifeInsurance">
								<InputNumber placeholder="Life Insurance" style={{ width: '100%' }} size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="basicSalary">
								<InputNumber placeholder="Basic Salary" style={{ width: '100%' }} size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="recruitmentSource">
								<Input placeholder="Recruitment Source" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="dateOfBirth">
								<DatePicker placeholder="Date of Birth" style={{ width: '100%' }} size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="religion">
								<Input placeholder="Religion" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="maritalStatus">
								<Input placeholder="Marital Status" size='large' />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item name="workTenure">
								<Input placeholder="Work Tenure" size='large' />
							</Form.Item>
						</Col>
						{/* <Col span={6}>
							<Form.Item name="department">
								<Select placeholder="Department" size='large'>
									{departments.map((item) => (
										<Option key={item._id} value={item._id}>
											{item.departmentName}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col> */}
						
						<Col span={6}>
						<Form.Item name="position">
								<Select placeholder="Position" size='large'>
									<Select.Option value="None">None</Select.Option>
									<Select.Option value="Chief Executive Officer (CEO)">Chief Executive Officer (CEO)</Select.Option>
									<Select.Option value="Chief Operating Officer (COO)">Chief Operating Officer (COO)</Select.Option>
									<Select.Option value="Chief Financial Officer (CFO)">Chief Financial Officer (CFO)</Select.Option>
									<Select.Option value="Chief Technology Officer (CTO)">Chief Technology Officer (CTO)</Select.Option>
									<Select.Option value="Chief Marketing Officer (CMO)">Chief Marketing Officer (CMO)</Select.Option>
									<Select.Option value="Chief Human Resources Officer (CHRO)">Chief Human Resources Officer (CHRO)</Select.Option>
									<Select.Option value="President">President</Select.Option>
									<Select.Option value="Vice President">Vice President</Select.Option>
									<Select.Option value="Director">Director</Select.Option>
									<Select.Option value="Manager">Manager</Select.Option>
									<Select.Option value="Supervisor">Supervisor</Select.Option>
									<Select.Option value="Team Leader">Team Leader</Select.Option>
									<Select.Option value="Administrative Assistant">Administrative Assistant</Select.Option>
									<Select.Option value="Executive Assistant">Executive Assistant</Select.Option>
									<Select.Option value="Office Manager">Office Manager</Select.Option>
									<Select.Option value="Receptionist">Receptionist</Select.Option>
									<Select.Option value="Clerk">Clerk</Select.Option>
									<Select.Option value="Sales Manager">Sales Manager</Select.Option>
									<Select.Option value="Sales Representative">Sales Representative</Select.Option>
									<Select.Option value="Account Manager">Account Manager</Select.Option>
									<Select.Option value="Marketing Manager">Marketing Manager</Select.Option>
									<Select.Option value="Marketing Coordinator">Marketing Coordinator</Select.Option>
									<Select.Option value="Digital Marketing Specialist">Digital Marketing Specialist</Select.Option>
									<Select.Option value="Content Creator">Content Creator</Select.Option>
									<Select.Option value="Social Media Manager">Social Media Manager</Select.Option>
									<Select.Option value="Customer Service Representative">Customer Service Representative</Select.Option>
									<Select.Option value="Business Development Manager">Business Development Manager</Select.Option>
									<Select.Option value="Accountant">Accountant</Select.Option>
									<Select.Option value="Accounting Manager">Accounting Manager</Select.Option>
									<Select.Option value="Controller">Controller</Select.Option>
									<Select.Option value="Financial Analyst">Financial Analyst</Select.Option>
									<Select.Option value="Payroll Specialist">Payroll Specialist</Select.Option>
									<Select.Option value="Auditor">Auditor</Select.Option>
									<Select.Option value="Bookkeeper">Bookkeeper</Select.Option>
									<Select.Option value="Tax Specialist">Tax Specialist</Select.Option>
									<Select.Option value="Human Resources Manager">Human Resources Manager</Select.Option>
									<Select.Option value="HR Coordinator">HR Coordinator</Select.Option>
									<Select.Option value="Recruiter">Recruiter</Select.Option>
									<Select.Option value="Training and Development Specialist">Training and Development Specialist</Select.Option>
									<Select.Option value="Payroll Manager">Payroll Manager</Select.Option>
									<Select.Option value="Benefits Coordinator">Benefits Coordinator</Select.Option>
									<Select.Option value="IT Manager">IT Manager</Select.Option>
									<Select.Option value="Network Administrator">Network Administrator</Select.Option>
									<Select.Option value="Software Developer">Software Developer</Select.Option>
									<Select.Option value="Systems Analyst">Systems Analyst</Select.Option>
									<Select.Option value="Database Administrator">Database Administrator</Select.Option>
									<Select.Option value="Help Desk Technician">Help Desk Technician</Select.Option>
									<Select.Option value="Cybersecurity Specialist">Cybersecurity Specialist</Select.Option>
									<Select.Option value="Data Scientist">Data Scientist</Select.Option>
									<Select.Option value="Operations Manager">Operations Manager</Select.Option>
									<Select.Option value="Project Manager">Project Manager</Select.Option>
									<Select.Option value="Logistics Coordinator">Logistics Coordinator</Select.Option>
									<Select.Option value="Production Manager">Production Manager</Select.Option>
									<Select.Option value="Quality Assurance Manager">Quality Assurance Manager</Select.Option>
									<Select.Option value="Inventory Manager">Inventory Manager</Select.Option>
									<Select.Option value="Procurement Specialist">Procurement Specialist</Select.Option>
									<Select.Option value="Research Scientist">Research Scientist</Select.Option>
									<Select.Option value="Product Manager">Product Manager</Select.Option>
									<Select.Option value="R&D Engineer">R&D Engineer</Select.Option>
									<Select.Option value="Lab Technician">Lab Technician</Select.Option>
									<Select.Option value="Mechanical Engineer">Mechanical Engineer</Select.Option>
									<Select.Option value="Electrical Engineer">Electrical Engineer</Select.Option>
									<Select.Option value="Civil Engineer">Civil Engineer</Select.Option>
									<Select.Option value="Software Engineer">Software Engineer</Select.Option>
									<Select.Option value="Chemical Engineer">Chemical Engineer</Select.Option>
									<Select.Option value="Production Worker">Production Worker</Select.Option>
									<Select.Option value="Machine Operator">Machine Operator</Select.Option>
									<Select.Option value="Assembly Line Worker">Assembly Line Worker</Select.Option>
									<Select.Option value="Quality Control Inspector">Quality Control Inspector</Select.Option>
									<Select.Option value="Plant Manager">Plant Manager</Select.Option>
									<Select.Option value="General Counsel">General Counsel</Select.Option>
									<Select.Option value="Attorney">Attorney</Select.Option>
									<Select.Option value="Paralegal">Paralegal</Select.Option>
									<Select.Option value="Legal Assistant">Legal Assistant</Select.Option>
									<Select.Option value="Compliance Officer">Compliance Officer</Select.Option>
									<Select.Option value="Trainer">Trainer</Select.Option>
									<Select.Option value="Instructional Designer">Instructional Designer</Select.Option>
									<Select.Option value="Teacher/Instructor">Teacher/Instructor</Select.Option>
									<Select.Option value="Safety Officer">Safety Officer</Select.Option>
									<Select.Option value="Occupational Health Nurse">Occupational Health Nurse</Select.Option>
									<Select.Option value="Environmental Health Officer">Environmental Health Officer</Select.Option>
									<Select.Option value="Customer Support Representative">Customer Support Representative</Select.Option>
									<Select.Option value="Technical Support Specialist">Technical Support Specialist</Select.Option>
									<Select.Option value="Graphic Designer">Graphic Designer</Select.Option>
									<Select.Option value="Web Designer">Web Designer</Select.Option>
									<Select.Option value="Copywriter">Copywriter</Select.Option>
									<Select.Option value="Art Director">Art Director</Select.Option>
									<Select.Option value="Mechanic">Mechanic</Select.Option>
									<Select.Option value="Helper Mechanic">Helper Mechanic</Select.Option>
									<Select.Option value="Bike Rider">Bike Rider</Select.Option>
									<Select.Option value="Car Driver">Car Driver</Select.Option>
									<Select.Option value="Camp Boss">Camp Boss</Select.Option>
									<Select.Option value="Cleaner">Cleaner</Select.Option>
									<Select.Option value="Plumber">Plumber</Select.Option>
									<Select.Option value="Electrician">Electrician</Select.Option>
								</Select>
							</Form.Item>		
							</Col>
							
						{customAttributes.map((attr) => (
							<Col span={6} key={attr._id}>
								<Form.Item name={attr._id}>
									<Input placeholder={attr.name} size='large'/>
								</Form.Item>
							</Col>
						))}

						<Col span={6}>
					<Button type="primary" htmlType="submit" className='submit-button' >
						Submit Details
					</Button>
						</Col>
						
					</Row>

					{/* <p className='home-header'>
						<Link to='/admin/employeeDataDashboard' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
						
					</p> */}
				</Form>
			</div>
		</div>
	);
};

export default AddEmployee;
