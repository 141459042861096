import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { message, Button, DatePicker, Select, Radio, Tooltip, Input, FloatButton, Spin } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { SaveOutlined, DownloadOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import moment from "moment";
import html2pdf from "html2pdf.js";
import dayjs from "dayjs";
import AdminNavbar from "../components/AdminNavbar";
import html2canvas from "html2canvas";

const { TextArea } = Input;

const LeaveApproval = () => {

	const { id } = useParams();
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false);

	const [formData, setFormData] = useState({
		leaveType: '',
		surname: '',
		firstName: '',
		branchDepartment: '',
		employeePayrollNo: '',
		jobTitle: '',
		reasonForLeave: '',
		firstDayOfLeave: null,
		lastDayOfLeave: null,
		dateReturnedToWork: null,
		numberOfLeaveDaysTaken: '',
		leaveEntitlement: '',
		dateJoined: null,
		dateLastLeaveTaken: null,
		totalDaysRemainingAfterPreviousLeave: '',
		currentYearLeaveEntitlement: '',
		additionalLeaveDaysRequested: '',
		ticket: '',
		contactDetailsOnHoliday: '',
		otherRequests: '',
		passportNo: '',
		nationality: '',
		passportExpiryDate: null,
		residenceVisaExpiry: null,
		travelDateAndTime: null,
		returnDateAndTime: null,
		flightNo: '',
		departureAirport: '',
		destinationAirport: '',
		employeeSignature: '',
		employeeSignatureDate: null,
		managerName: '',
		managerSignature: '',
		managerDept: '',
		managerSignatureDate: null,
		department: '',
		chairmanSignature: '',
		chairmanSignatureDate: null,
		eCode: '', // Add the employeeCode field
	});
	const [companyId, setCompanyId] = useState(null);
	const [logoComponent, setLogoComponent] = useState(null);
	const [isButtonVisible, setIsButtonVisible] = useState(true);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [adminEmail, setAdminEmail] = useState(null);
	const [manager, setManager] = useState('');


	const fetchFormData = async () => {
		// Immediately set isLoading to true when fetch starts
		setIsLoading(true);

		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/get-approval-form/${id}`, {
				method: 'GET',
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const allData = await response.json();
			console.log('data: ', allData)
			if (response.ok) {
				const data = allData['formData']
				const man = allData['formManager']
				setManager(man.split('@')[0])

				const parsedData = {
					...data,
					hiringDate: data.hiringDate ? dayjs(data.hiringDate) : null,
					firstDayOfLeave: data.firstDayOfLeave ? dayjs(data.firstDayOfLeave, 'YYYY-MM-DD') : null,
					lastDayOfLeave: data.lastDayOfLeave ? dayjs(data.lastDayOfLeave) : null,
					dateReturnedToWork: data.dateReturnedToWork ? dayjs(data.dateReturnedToWork) : null,
					dateJoined: data.dateJoined ? dayjs(data.dateJoined) : null,
					dateLastLeaveTaken: data.dateLastLeaveTaken ? dayjs(data.dateLastLeaveTaken) : null,
					passportExpiryDate: data.passportExpiryDate ? dayjs(data.passportExpiryDate) : null,
					residenceVisaExpiry: data.residenceVisaExpiry ? dayjs(data.residenceVisaExpiry) : null,
					travelDateAndTime: data.travelDateAndTime ? dayjs(data.travelDateAndTime) : null,
					returnDateAndTime: data.returnDateAndTime ? dayjs(data.returnDateAndTime) : null,
					employeeSignatureDate: data.employeeSignatureDate ? dayjs(data.employeeSignatureDate) : null,
					managerSignatureDate: data.managerSignatureDate ? dayjs(data.managerSignatureDate) : null,
					chairmanSignatureDate: data.chairmanSignatureDate ? dayjs(data.chairmanSignatureDate) : null,
				};
				console.log('parsedData: ', parsedData)
				setFormData(parsedData);
				// setFormData(data);
			} else {
				console.error('Error:', allData.message || 'Failed to fetch data');
			}
		} catch (error) {
			console.error('Error:', error);
		} finally {
			// Set isLoading to false when fetch is complete
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (id)
			fetchFormData()
	}, [id]);

	const handleChange = (name, value) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	const handleDownload = async () => {
		setIsButtonVisible(false); // Hide the button
		const pdfElement = document.getElementById('section-to-print');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: [5, 10],
			filename: 'leave_application_form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 3 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};
		try {
			html2pdf().from(pdfElement).set(options).save();
			message.success('PDF downloaded successfully!');
		}
		catch (error) {
			console.error('Error:', error);
		}

	};

	const calculateDaysOfLeave = () => {
		if (formData.firstDayOfLeave && formData.lastDayOfLeave) {
			const firstDay = dayjs(formData.firstDayOfLeave, 'YYYY-MM-DD');
			const lastDay = dayjs(formData.lastDayOfLeave, 'YYYY-MM-DD');
			const daysOfLeave = lastDay.diff(firstDay, 'days') + 1; // +1 to include the first day in the count
			return daysOfLeave;
		}
		return '';
	};

	const fetchEmployeeInfo = async (employeeCode) => {
		try {

			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getByECode`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ eCodes: employeeCode }),
			});

			if (!response.ok) {
				message.error('Failed to fetch employee information');
				return
				// throw new Error('Failed to fetch employee information');
			}

			const employeeInfo = await response.json(); // This line extracts the body of the response
			console.log(employeeInfo)
			return employeeInfo;
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const storedAdminEmail = localStorage.getItem('adminEmail');
		if (storedAdminEmail) {

			const extractedName = storedAdminEmail.split('@')[0];
			const capitalizedName = extractedName.charAt(0).toUpperCase() + extractedName.slice(1);
			setAdminEmail(capitalizedName);
			// // console.log('adminEmail',adminEmail);
		}
	}, []);

	const saveFile = async () => {
		const options = {
			margin: [5, 10],
			filename: 'leave_application_form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 3 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		const pdfElement = document.getElementById('section-to-print');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		try {
			const employeeInfo = await fetchEmployeeInfo(formData.eCode);
			const employeeId = employeeInfo[0]._id;
			const token = localStorage.getItem('token');

			html2pdf().from(pdfElement).set(options).outputPdf('blob').then(async (pdfBlob) => {
				const uploadData = new FormData(); // changed the name to uploadData to avoid the conflict
				uploadData.append('file', pdfBlob, 'leave-application-form.pdf');
				uploadData.append('employeeId', employeeId); // Add employeeId to uploadData
				uploadData.append('type', 'vacation'); // Add employeeId to uploadData

				const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFileData', {
					method: 'POST',
					headers: {
						Authorization: 'Bearer ' + token,
					},
					body: uploadData, // use uploadData here
				});

				const data = await response.json();
				console.log(data);
			});

		} catch (error) {
			console.error('Error:', error);
		}
	}

	const handleSave = async () => {
		if (isLoading)
			return;
		setIsLoading(true);

		if (!formData.firstDayOfLeave || !formData.lastDayOfLeave) {
			setIsLoading(false);

			// Display an error message or take appropriate action when the fields are empty.
			message.error('Please fill in both First Day and Last Day of Leave.');
			return;
		};
		try {
			const firstDayOfLeaveFormatted = dayjs(formData.firstDayOfLeave).format('YYYY-MM-DD');
			const lastDayOfLeaveFormatted = dayjs(formData.lastDayOfLeave).format('YYYY-MM-DD');
			const action = "On Leave Application Submitted for " + firstDayOfLeaveFormatted;

			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/saveFile', {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + token,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					eCode: formData.eCode,
					adminEmail: adminEmail,
					action: action,
					formId: id,
					firstDayOfLeaveFormatted: firstDayOfLeaveFormatted,
					lastDayOfLeaveFormatted: lastDayOfLeaveFormatted
				}), // use uploadData here
			});

			const data = await response.json();
			console.log(data);
			if (response.ok) {
				message.success("File saved successfully");
				saveFile();
				navigate("/admin/approvals");
			} else {
				message.error("Error saving file");
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			message.error("Error saving file");
		}
	};

	const handleDelete = async () => {
		if (isDeleteLoading) {
			return;
		}
		setIsDeleteLoading(true);
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/deleteApprovalForm', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ formId: id }),
			});
			if (response.ok) {
				message.success('Form deleted successfully');
				navigate('/admin/approvals');
			} else {
				console.error('Failed to delete the form');
				message.error('Error deleting form');
			}
		}

		catch (error) {
			console.error('Error in handleDelete:', error);
			message.error('Failed to delete the form');
		} finally {
			setIsDeleteLoading(false);
		}
	}

	return (
		<>
			<div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
				<AdminNavbar />
			</div>
			<div className="center-container" style={{ paddingTop: 120 }}>
				<div className="leave-application-form">
					<div className='local-leave-container' style={{ minHeight: '297mm', width: '210mm' }}>
						<div className='leave-application' id='section-to-print' style={{ fontSize: 'smaller' }}>
							<table className="section-header">
								<td style={{ width: '20%' }}>{logoComponent}</td>
								<td style={{ width: '60%' }}><h3>Leave Application Form</h3></td>
								<td style={{ width: '20%' }}><h5></h5></td>
							</table>

							<table className="leave-table">
								<tr >
									<td colSpan={4} className='header-row text-center' >
										<h7 style={{ textAlign: 'center', margin: '5px 0px' }}>Please ensure that this application is approved prior to confirming your holiday arrangements.</h7>
									</td>
								</tr>
								<tr>
									<td colSpan={4}>
										<Radio.Group value={formData.leaveType} name="leaveType" style={{ display: 'flex', justifyContent: 'space-between', padding: '3px 55px', fontSize: 'smaller' }}>
											<Radio value="Annual Leave">Annual Leave</Radio>
											<Radio value="Local Leave">Local Leave</Radio>
											<Radio value="Emergency Leave">Emergency Leave</Radio>
											<Radio value="Other">Other</Radio>
										</Radio.Group>

									</td>
								</tr>
								<tr>
									<td style={{ width: '26%' }}><label>First Name</label></td>
									<td colSpan={3}>
										<TextArea autoSize value={formData.firstName} disabled />
									</td>
								</tr>
								<tr>
									<td>
										<label>Position</label>
									</td>
									<td>
										<TextArea autoSize value={formData.position} disabled />
									</td>
									<td>
										<label>Employee Code</label>
									</td>
									<td>
										<input disabled type="text" value={formData.eCode} />
									</td>
								</tr>
								<tr>
									<td>
										<label>QID Number</label>
									</td>
									<td>
										<TextArea autoSize value={formData.qidNumber} disabled />
									</td>
									<td>
										<label>Reference Number</label>
									</td>
									<td>
										<TextArea autoSize value={formData.refNo} disabled />
									</td>
								</tr>
								{/* <tr>
									<td>
										<label>Job Title</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="jobTitle" value={formData.jobTitle} onChange={(e) => handleChange('jobTitle', e.target.value)} />
									</td>
								</tr> */}
								<tr>
									<td colSpan={4} className='header-row' style={{ margin: '5px 0px' }}><h5>Reason for leave:</h5></td>
								</tr>
								<tr>
									<td colSpan={4}>
										<TextArea autoSize name="reasonForLeave" value={formData.reasonForLeave} disabled />
									</td>
								</tr>
								<tr className='date-fields'>
									<td>First Day of Leave</td>
									<td>Last Day of Leave</td>
									<td>Date returned to work</td>
									<td>No. of leave days taken</td>
								</tr>
								<tr>
									<td>
										<DatePicker
											style={{ width: '100%', height: '25px' }}
											name="firstDayOfLeave"
											value={formData.firstDayOfLeave}
											disabled
										/>
									</td>
									<td>
										<DatePicker
											name="lastDayOfLeave"
											style={{ width: '100%', height: '25px' }}
											value={formData.lastDayOfLeave}
											disabled
										/>
									</td>
									<td>
										<DatePicker style={{ width: '100%', height: '25px' }} name="dateReturnedToWork" value={formData.dateReturnedToWork} disabled />
									</td>
									<td>
										<input style={{ height: '25px' }} type="number" name="numberOfLeaveDaysTaken" value={calculateDaysOfLeave()} readOnly />
									</td>
								</tr>
								<tr>
									<td colSpan={4} className='header-row'><h5 >Leave Entitlement</h5></td>
								</tr>
							</table>
							<table className='leave-table5'>
								<tr style={{ fontSize: 'small' }}>
									<td>Date Joined</td>
									<td>Date last leave taken</td>
									<td style={{ padding: '10px 0' }}>Total days remaining after previous leave</td>
									<td>Current year leave entitlement</td>
									<td>Additional leave days requested</td>
								</tr>
								<tr>
									<td>
										<DatePicker style={{ width: '100%', height: '25px' }} name="dateJoined" value={formData.hiringDate} disabled />
									</td>
									<td>
										<DatePicker style={{ width: '100%', height: '25px' }} name="dateLastLeaveTaken" value={formData.dateLastLeaveTaken} disabled />
									</td>
									<td>
										<input
											type="text"
											name="totalDaysRemainingAfterPreviousLeave"
											value={formData.totalDaysRemainingAfterPreviousLeave}
											disabled
										/>
									</td>
									<td>
										<input
											type="text"
											name="currentYearLeaveEntitlement"
											value={formData.currentYearLeaveEntitlement}
											disabled
										/>
									</td>
									<td>
										<input
											type="text"
											name="additionalLeaveDaysRequested"
											value={formData.additionalLeaveDaysRequested}
											disabled
										/>
									</td>
								</tr>
								<tr>
									<td>Ticket</td>
									<td colSpan={4}>
										<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
											<span>
												<input type="radio" name="ticket" value="Paid by Company" checked={formData.ticket === 'Paid by Company'} />
												Paid by Company
											</span>
											<span>
												<input type="radio" name="ticket" value="Own ticket" checked={formData.ticket === 'Own ticket'} />
												Own ticket
											</span>
										</div>
									</td>
								</tr>
								<tr>
									<td>Contact details whilst on holiday (Tel / Fax No. if applicable)</td>
									<td colSpan={4}><TextArea autoSize name="contactDetailsOnHoliday" value={formData.contactDetailsOnHoliday} disabled /></td>
								</tr>
								<tr>
									<td>Other Requests</td>
									<td colSpan={4}><TextArea autoSize name="otherRequests" style={{ height: '25px' }} value={formData.otherRequests} disabled /></td>
								</tr>
							</table>
							<table className='leave-table'>
								<tr><td colSpan={12} className='header-row'><h5>Details of Flight Booking:</h5></td></tr>
								<tr>
									<td colSpan={3}>
										<label>Passport No</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize value={formData.passportNo} disabled />
									</td>
									<td colSpan={3}>
										<label>Nationality</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize value={formData.nationality} disabled />
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<label>Passport Expiry Date</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} value={formData.passportExpiryDate} disabled />
									</td>
									<td colSpan={3}>
										<label>Residence Visa Expiry</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} value={formData.residenceVisaExpiry} disabled />
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<label>Travel Date & Time (am/pm)</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="travelDateAndTime" value={formData.travelDateAndTime} disabled showTime format="YYYY-MM-DD HH:mm:ss" />
									</td>
									<td colSpan={3}>
										<label>Return Date & Time (am/pm)</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="returnDateAndTime" value={formData.returnDateAndTime} disabled showTime format="YYYY-MM-DD HH:mm:ss" />
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<label>Flight No.</label>
									</td>
									<td colSpan={2}>
										<TextArea autoSize name="flightNo" value={formData.flightNo} disabled />
									</td>
									<td colSpan={2}>
										<label>Departure (Airport)</label>
									</td>
									<td colSpan={2}>
										<TextArea autoSize name="departureAirport" value={formData.departureAirport} disabled />
									</td>
									<td colSpan={2}>
										<label>Destination (Airport)</label>
									</td>
									<td colSpan={2}>
										<TextArea autoSize name="destinationAirport" value={formData.destinationAirport} disabled />
									</td>
								</tr>
								<tr><td colSpan={12} className='header-row' style={{ textAlign: 'center' }}><h5>Please attach details as above for others due to travel with you under your Contract</h5></td></tr>
								<tr>
									<td colSpan={3}>
										<label>Employee’s Signature</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="employeeSignature" value={formData.employeeSignature} disabled />
									</td>
									<td colSpan={3}>
										<label>Date</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="employeeSignatureDate" value={formData.employeeSignatureDate} disabled />
									</td>
								</tr>
								<tr><td colSpan={12} className='header-row'><h5>Authorisation Details: I have approved the holiday dates requested above:</h5></td></tr>
								<tr>
									<td colSpan={3}>
										<label>Manager’s Name</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="managerName" value={manager} disabled />
									</td>
									<td colSpan={3}>
										<label>Signature</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="managerSignature" style={{ fontStyle: 'italic' }} value={'Approved by ' + manager} disabled />
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<label>Department</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="managerDept" value={formData.managerDept} disabled />
									</td>
									<td colSpan={3}>
										<label>Date</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="managerSignatureDate" value={formData.managerSignatureDate} disabled />
									</td>
								</tr>
								<tr>
									<td colSpan={3}><label><strong>Chairman's Signature</strong></label></td>
									<td colSpan={3}><TextArea autoSize name="chairmanSignature" value={formData.chairmanSignature} disabled /></td>
									<td colSpan={3}><label>Date</label></td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="chairmanSignatureDate" value={formData.chairmanSignatureDate} disabled />
									</td>
								</tr>
							</table>

							{/* <br /> */}
							<div className='leave-checkbox mt-2'>
								<label><input type='checkbox' checked={formData.hrDept == 1} />HR Dept</label><br />
								<label><input type='checkbox' checked={formData.accDept == 1} />Accounts Department</label><br />
								<label><input type='checkbox' checked={formData.passCheck == 1} />Passport checked for Visa &amp; Expiry Dates</label><br />
								<label><input type='checkbox' checked={formData.ticketBooked == 1} />Ticket Booked</label><br />
								<label><input type='checkbox' checked={formData.passRetr == 1} />Passport Retrieval Request Form</label><br />
							</div>
						</div>
						<FloatButton
							icon={isLoading ? <Spin /> : <SaveOutlined />}
							tooltip="Save"
							onClick={handleSave}
							style={{
								right: 200,
							}}
						/>
						<Tooltip title="Delete" placement='top'>
							<FloatButton
								icon={isDeleteLoading ? <Spin /> : <DeleteOutlined />}
								onClick={handleDelete}
								style={{
									right: 150,
								}}
							/>
						</Tooltip>
						<Tooltip title="Download" placement='right'>
							<FloatButton
								icon={<DownloadOutlined />}
								onClick={handleDownload}
								style={{
									right: 100,
								}}
							/>
						</Tooltip>
					</div>
				</div>
			</div>
		</>
	);
}

export default LeaveApproval;