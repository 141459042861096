import React, { useEffect, useState } from 'react';
import './SickLeave.css';
import { DatePicker, FloatButton, Input, Popover, Spin, Tooltip, message } from 'antd';
import html2canvas from 'html2canvas';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DownloadOutlined, SaveOutlined, SendOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';
import AdminNavbar from '../components/AdminNavbar';
import dayjs from 'dayjs';
const { TextArea } = Input;



const EmploymentTermination = () => {
	const navigate = useNavigate()
	const [logoComponent, setLogoComponent] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [adminEmail, setAdminEmail] = useState(null);
	const text = 'With reference to the outcome of Probation Period Performance Evaluation we regret to advise that your employment is now terminated with immediate effect as your performance has not met the standard required.'
	const [managers, setManagers] = useState([]);

	const { ecode } = useParams();
	const [formData, setFormData] = useState({
		employeeName: '',
		eCode: ecode,
		position: '',
		refNo: '',
		firstName: '',
		date: dayjs(),
		text: text
	});

	useEffect(() => {
		if (ecode) {
			fetchEmployeeInfo(ecode)
		}
		fetchAndDisplayLogo().then((logo) => {
			setLogoComponent(logo);
		});
		fetchManagers();
	}, []);

	const fetchManagers = async () => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompanyManagers', {
				method: 'GET',
				headers: { Authorization: `Bearer ${token}` },
			});
			const data = await response.json();
			if (!response.ok) {
				message.error('Failed to fetch managers');
			} 
			else {
				console.log(data);
				setManagers(data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleDownload = async () => {
		const pdfElement = document.querySelector('section-to-print');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'employee-termination.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
	};

	const fetchEmployeeInfo = async (employeeCode) => {
		// console.log(employeeCode)
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getByECode`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ eCodes: employeeCode }),
			});

			if (!response.ok) {
				message.error('Failed to fetch employee information');
				return;
				// throw new Error('Failed to fetch employee information');
			}

			const employeeInfo = await response.json(); // This line extracts the body of the response
			// // console.log(employeeInfo)
			if (employeeInfo.length > 0)
				setFormData({
					...formData,
					employeeName: employeeInfo[0].employeeName,
					position: employeeInfo[0].position,
					refNo: employeeInfo[0].refNo,
					qidNumber: employeeInfo[0].qidNumber, 
					firstName: employeeInfo[0].employeeName.split(' ')[0]
				});
			return employeeInfo;
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const storedAdminEmail = localStorage.getItem('adminEmail');
		if (storedAdminEmail) {

			const extractedName = storedAdminEmail.split('@')[0];
			const capitalizedName = extractedName.charAt(0).toUpperCase() + extractedName.slice(1);
			setAdminEmail(capitalizedName);
			// //console.log('adminEmail',adminEmail);
		}
	}, []);

	

	const handleSave = async (managerId) => {
		if (isLoading)
			return;
		setIsLoading(true);

		try {
			const uploadData = new FormData(); // changed the name to uploadData to avoid the conflict
			uploadData.append('name', 'Leave Application');
			uploadData.append('ecode', formData.eCode); // Add employeeId to uploadData
			uploadData.append('formData', JSON.stringify(formData)); // Add companyId to uploadData
			const token = localStorage.getItem('token');

			const response = await fetch('https://hrms-5u7j.onrender.com/admin/submit-approval', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({
					name: 'Employee Termination',
					ecode: formData.eCode,
					formData,
					managerId
				}), // use uploadData here
			});

			const data = await response.json();
			console.log(data)

			if (response.ok) {
				// console.log('File saved successfully');
				message.success("File sent for approval");
				navigate('/admin/hr-forms/');
			} else {
				message.error('Error occurred while sending the file');
			}

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			message.error('Error occurred while sending the file');
		}
	};


	return (
		<>
			<div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
				<AdminNavbar />
			</div>
			<div className="center-container" style={{ paddingTop: 120 }}>
				{/* <p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p> */}
				<div className="disciplinary-action-form">
					<div className='local-leave-container'>
						<div className='form-container termination-letter' id='section-to-print' style={{ border: '1px solid black', padding: '20px', height: '355mm', width: '250mm' }} >
							<div className='row'>
								<div className='col-md-2'>{logoComponent}</div>
								<div className='col-md-8'>
									<h2 style={{ fontWeight: 'bolder', border: 'none' }}><br />Employment Termination Letter</h2>
								</div>
								<div className='col-md-2' >
								</div>
							</div><br />
							<div className="row form-fields">
								<div className="col-md-12 ">
									<label>Ref.</label>
									<TextArea autoSize value={formData.refNo} name='refNo' onChange={handleChange} style={{ width: '40%', textAlign: 'center' }} type="text" />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '15px' }}>
									<label>Date:</label>
									<DatePicker value={formData.date} onChange={(e) => setFormData({ ...formData, date: e })} style={{ width: '40%' }} />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '50px' }}>
									<label>Name:</label>
									<TextArea autoSize style={{ borderRadius: 0, width: '40%' }} onChange={handleChange} name="employeeName" value={formData.employeeName} type="text" />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '15px' }}>
									<label>Employee Code:</label>
									<Input onKeyDown={(e) => e.key === "Enter" && fetchEmployeeInfo(formData.eCode)} onChange={handleChange} name="eCode" value={formData.eCode} style={{ borderRadius: 0, width: '40%', textAlign: 'center' }} type="text" />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '15px' }}>
									<label>Designation:</label>
									<TextArea autoSize style={{ borderRadius: 0, width: '40%' }} onChange={handleChange} name="position" value={formData.position} type="text" />
								</div>
								<div className="col-md-12  " style={{ paddingTop: '15px' }}>
									<label>QID Number:</label>
									<TextArea value={formData.qidNumber} name='qidNumber' onChange={handleChange} autoSize style={{ borderRadius: 0, width: '40%' }} type="text" />
								</div>
							</div>
							<div className="row form-fields" style={{ paddingTop: '80px' }}>
								<div className="col-md-12  ">
									<label style={{ fontWeight: '500' }}>Dear </label>
									<TextArea value={formData.firstName} name='firstName' onChange={handleChange} autoSize style={{ borderRadius: 0, width: '40%' }} type="text" />
									<label style={{ fontWeight: '500' }}>,</label>

								</div>
								<div className="col-md-12  " style={{ paddingTop: '30px' }}>
									<TextArea autoSize style={{ fontWeight: '600', width: '100%', border: 'none', textAlign: 'left', fontSize: 20 }} value={formData.text} onChange={handleChange} name="text"></TextArea>
								</div>
							</div>
							<hr className='signature-hr' style={{ marginTop: '60px' }}></hr>
							<h5 style={{ fontWeight: 'bolder' }}>Manager/Director</h5>
							<div style={{ marginTop: '100px' }}>
								<label style={{ fontWeight: '600' }}>Distribution:    Personnel File</label>
							</div>
							<div>
								<label style={{ float: 'right', fontWeight: '600' }}>Rev. 00</label>
							</div>

						</div>
					</div>
					{/* <Tooltip title="Print" placement='right'>
			<FloatButton
				icon={<PrinterOutlined />}
				onClick={() => window.print()}
				style={{
					right: 100,
				}}
			/>
			</Tooltip> */}
					<Popover
					trigger={'click'}
					content={
						<div className='manager-list'>
							{managers.map((manager) => (
								<p onClick={() => handleSave(manager._id)}>
									{manager.managerEmail}
								</p>
							))}
						</div>
					}
					>
						<FloatButton
							icon={isLoading ? <Spin /> : <SendOutlined />}
							tooltip="Send for Approval"
							// onClick={handleSave}
							style={{
								right: 200,
							}}
						/>
					</Popover>
					<Tooltip title="Download" placement='right'>
						<FloatButton
							icon={<DownloadOutlined />}
							onClick={handleDownload}
							style={{
								right: 150,
							}}
						/>
					</Tooltip>
				</div>
			</div>
		</>
	);
};

export default EmploymentTermination;
