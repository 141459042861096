import { useState } from 'react';
import { BankOutlined, TeamOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './EmployeePage.css'
import AdminNavbar from './components/AdminNavbar';
import Navbar from './components/Navbar';
import ManagerCompanies from './ManagerCompanies';
import ManagerAdminList from './ManagerAdminList';

const ManagerPage = () => {
    const [activeTab, setActiveTab] = useState(0);

    return ( 
        <div className='d-flex flex-column' style={{ minHeight: '100vh' }}>
			<div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
				<Navbar/>
			</div>
			<div className='d-flex gap-5' style={{ flex : 1, paddingTop: 90}}>
				<div className='employee-sidebar'>
					<Tooltip title="Companies" placement='right'>
						<div className={activeTab == 0 ? 'active' : ''} onClick={() => setActiveTab(0)}>
							<BankOutlined />
						</div>
					</Tooltip>
					<Tooltip title="Users" placement='right'>
						<div className={activeTab == 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
							<TeamOutlined />
						</div>
					</Tooltip>
				</div>

				{activeTab == 0 && <ManagerCompanies/>}
				{activeTab == 1 && <ManagerAdminList />}
			</div>
		</div>
     );
}
 
export default ManagerPage;