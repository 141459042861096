import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import CountUp from 'react-countup';
import { Button, Card, Col, Divider, Form, Input, Row, Statistic, message } from 'antd';
const formatter = (value) => <CountUp end={value} separator="," />;


const ManagerSettings = () => {
    const [storageData, setStorageData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [companyId, setCompanyId] = useState(null);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showPasswordFields, setShowPasswordFields] = useState(false);

    const handleFileChange = (e) => {
        setLogoFile(e.target.files[0]);
    };

    const managerEmail = localStorage.getItem('managerEmail') || '';
    // // console.log('managerEmail:',managerEmail);
    const collectionName = 'hrms'; // Example collection name

    const bytesToMB = (bytes) => {
        return (bytes / (1024 * 1024)).toFixed(2); // Converts bytes to MB and rounds to 2 decimal places
    };

    // const handleUploadLogo = async () => {
    //     try {
    //         const token = localStorage.getItem('token');

    //         let currentCompanyId = companyId;

    //         if (!currentCompanyId) {
    //             const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompanyAtt', {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });
    //             const data = await response.json();
    //             currentCompanyId = data.company?._id;

    //             if (currentCompanyId) {
    //                 setCompanyId(currentCompanyId);
    //             } else {
    //                 message.error('Failed to fetch company ID');
    //                 return;
    //             }
    //         }

    //         const formData = new FormData();
    //         formData.append('logo', logoFile);

    //         const response = await fetch(`https://hrms-5u7j.onrender.com/admin/${currentCompanyId}/addCompanyLogo`, {
    //             method: 'POST',
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             body: formData,
    //         });

    //         if (response.ok) {
    //             message.success('Logo uploaded successfully');
    //         } else {
    //             message.error('Failed to upload logo');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //         message.error('Failed to upload logo');
    //     }
    // };

    const handleUpdatePassword = async () => {
        if (!currentPassword || !newPassword || !confirmNewPassword) {
            message.error('Please fill in all password fields');
            return;
        }

        if (newPassword !== confirmNewPassword) {
            message.error('New passwords do not match');
            return;
        }

        try {
            const token = localStorage.getItem('token');
           
            managerEmail=localStorage.getItem('managerEmail');
            const requestData = { managerEmail, currentPassword, newPassword };

            // Log the data being sent
            // // console.log('Data being sent:', requestData);

            const response = await fetch('https://hrms-5u7j.onrender.com/manager/update-password', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                message.success('Password updated successfully');
                setShowPasswordFields(false);
            } else {
                const data = await response.json();
                message.error(`Failed to update password: ${data.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to update password');
        }
    };

    const fetchStorageData = async (collectionName, managerEmail) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            const url = `https://hrms-5u7j.onrender.com/manager/getStorageForManager/${managerEmail}`;
            // // console.log(url);

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (response.ok) {
                // // console.log("data", data);
                setStorageData({
                    ...data,
                    totalCollectionSize: bytesToMB(data.totalCollectionSize),
                    totalStorageSize: bytesToMB(data.totalStorageSize),
                    totalIndexSize: bytesToMB(data.totalIndexSize),
                });
                setIsLoading(false);
            } else {
                // // console.log("data", data);
                console.error('Error fetching storage data:', data.message);
                setIsLoading(false);
            }
        } catch (error) {

            console.error('Error:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchStorageData(collectionName, managerEmail);
    }, [collectionName, managerEmail]);

    // if (isLoading) {
    //     return <div>Loading storage data...</div>;
    // }

    // if (!storageData) {
    //     return <div>No storage data available.</div>;
    // }

    return (
        <div>
            <Navbar />
            <div className='px-5 pt-4'>
                <Divider orientation="left" style={{ borderBottomColor: '#ff0000' }}><h3>Storage Details for {collectionName}</h3></Divider>
                <Row gutter={16}>
                    <Col span={8}>
                        <Card style={{ backgroundColor: '#B1DDC9' }} hoverable bordered={false}>
                            <Statistic title="Collection Size (MB)" value={storageData?.totalCollectionSize} formatter={formatter} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ backgroundColor: '#B1DDC9' }} hoverable bordered={false}>
                            <Statistic title="Storage Size (MB)" value={storageData?.totalStorageSize} formatter={formatter} />
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ backgroundColor: '#B1DDC9' }} hoverable bordered={false}>
                            <Statistic title="Index Size (MB)" value={storageData?.totalIndexSize} formatter={formatter} />
                        </Card>
                    </Col>
                </Row>
                <br />
                <Form layout="vertical">
                    <Form.Item label="Upload Company Logo">
                        <div className="d-flex">
                            <Input type="file" onChange={handleFileChange} style={{ width: '40%', backgroundColor: '#EEEE' }} />
                            {/* <Button className='create-button w-25 ms-4 mt-0' size='large' type="primary" onClick={handleUploadLogo}>
                                Update Logo
                            </Button> */}
                        </div>
                    </Form.Item>
                </Form>
                <Form layout="vertical">
                    <Form.Item label="Current Password">
                        <Input.Password style={{ width: '40%', backgroundColor: '#EEEE' }} value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="New Password">
                        <Input.Password style={{ width: '40%', backgroundColor: '#EEEE' }} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Confirm New Password">
                        <Input.Password style={{ width: '40%', backgroundColor: '#EEEE' }} value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                        <Button className='create-button w-25 ms-4 mt-0' size='large' type="primary" onClick={handleUpdatePassword}>
                            Update Password
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            {/* Additional UI elements to represent data visually */}
        </div>
    );
};

export default ManagerSettings;
