import React, { useState, useEffect, useCallback } from 'react';
import { Table, Select, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import { HomeFilled } from '@ant-design/icons';
import moment from 'moment';
import Papa from 'papaparse';
import './ManDateReports.css'; // Please create a new CSS file for this module.
import AdminNavbar from './components/AdminNavbar';
const ManDateReports = () => {
    const [employees, setEmployees] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const { Option } = Select;
    const [filterType, setFilterType] = useState('withinAMonth'); // default to "expiry within a month"
    const [dataLoaded, setDataLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);  // Maintain the current page in the state
    const pageSize = 100;  // As per your current setting
    const [selectedExpiryColumns, setSelectedExpiryColumns] = useState([]); // State for selected expiry columns


    const fetchCompany = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setCompanyId(data.company._id);  // Set the companyId in the state
            } else {
                console.error('Failed to fetch admin company:', data.error);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchEmployees = useCallback(async () => {
        // Ensure companyId is set before making the fetch request
        if (!companyId) return;

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`https://hrms-5u7j.onrender.com/admin/getEmployeesByCompany/${companyId}`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setEmployees(data);
                setDataLoaded(true);
            } else {
                message.error(data.message || 'Failed to fetch employees');
            }
        } catch (error) {
            console.error('Error:', error);
            message.error('Failed to fetch employees');
        }
    }, [companyId]);

    useEffect(() => {
        fetchCompany();   // Fetch the company first
    }, []);

    useEffect(() => {
        if (companyId) {
            fetchEmployees(); // Only fetch employees if companyId is not null
        }
    }, [companyId, fetchEmployees]);

    const isExpiringWithinNextMonth = (dateString) => {
        const currentDate = moment();
        const expiryDate = moment(dateString);
        const differenceInDays = expiryDate.diff(currentDate, 'days');
        return differenceInDays >= 0 && differenceInDays <= 30;
    };

    const isExpired = (dateString) => {
        const currentDate = moment();
        const expiryDate = moment(dateString);
        return expiryDate.isBefore(currentDate);
    };

    const filterEmployees = () => {
        // First, filter out terminated or resigned employees
        const activeEmployees = employees.filter(employee =>
            !["terminated", "resigned"].some(status =>
                employee.status?.toLowerCase().includes(status)
            )
        );

        if (filterType === 'withinAMonth') {
            return activeEmployees.filter(employee =>
                expiryColumns.some(column =>
                    employee[column.dataIndex] && isExpiringWithinNextMonth(employee[column.dataIndex])
                )
            );
        } else {
            return activeEmployees.filter(employee =>
                expiryColumns.some(column =>
                    employee[column.dataIndex] && isExpired(employee[column.dataIndex])
                )
            );
        }
    };
    const checkDateValidity = (dateString) => {
        if (filterType === 'withinAMonth') {
            return isExpiringWithinNextMonth(dateString);
        } else {
            return isExpired(dateString);
        }
    };
    const expiryColumns = [

        {
            title: 'QID Expiry',
            dataIndex: 'qidExpiryDate',
            key: 'qidExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Health Card Expiry',
            dataIndex: 'healthCardExpiryDate',
            key: 'healthCardExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Health Certificate Expiry',
            dataIndex: 'healthCertificateExpiryDate',
            key: 'healthCertificateExpiryDate',
            // fixed: 'left',
            width: 250,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Driver License Expiry',
            dataIndex: 'driverLicenseExpiryDate',
            key: 'driverLicenseExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Passport Expiry',
            dataIndex: 'passportExpiryDate',
            key: 'passportExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        },
        {
            title: 'Visa Expiry',
            dataIndex: 'visaExpiryDate',
            key: 'visaExpiryDate',
            // fixed: 'left',
            width: 200,
            render: (date) => checkDateValidity(date) ? moment(date).format('YYYY-MM-DD') : ""
        }
    ];
    const generateColumns = () => {
        const validColumns = expiryColumns.filter(column =>
            selectedExpiryColumns.length === 0 || selectedExpiryColumns.includes(column.dataIndex)
        );
    
        return [
            {
                title: 'SN',
                dataIndex: 'serial',
                key: 'serial',
                render: (_, __, index) => (currentPage - 1) * pageSize + index + 1
            },
            { title: 'E-CODE', dataIndex: 'eCode', key: 'eCode' },
            {
                title: 'EMPLOYEE NAME',
                dataIndex: 'employeeName',
                key: 'employeeName',
                width: 200,
                render: text => <div style={{ wordWrap: 'break-word', maxWidth: '200px' }}>{text}</div>
            },
            ...validColumns
        ];
    };
    


    const filteredEmployees = filterEmployees();
    const columns = generateColumns();

    const exportToCSV = () => {
        const mandatoryColumns = ['eCode', 'employeeName'];
    
        const displayedData = filteredEmployees.map(emp => {
            const obj = {};
    
            // Include mandatory columns
            mandatoryColumns.forEach(col => {
                obj[col] = emp[col] || ''; // Default to empty string if data is missing
            });
    
            // Include selected expiry columns
            columns.forEach(col => {
                if (
                    col.dataIndex &&
                    (selectedExpiryColumns.length === 0 || selectedExpiryColumns.includes(col.dataIndex))
                ) {
                    const value = emp[col.dataIndex];
                    if (
                        typeof value === 'string' &&
                        value &&
                        value.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/) &&
                        checkDateValidity(value)
                    ) {
                        obj[col.dataIndex] = moment(value).format('YYYY-MM-DD');
                    } else if (value) {
                        obj[col.dataIndex] = value;
                    } else {
                        obj[col.dataIndex] = '';
                    }
                }
            });
    
            return obj;
        });
    
        const csv = Papa.unparse(displayedData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'employees_data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };
    

    return (
        <>
            <AdminNavbar />
            <div className=''>
                <div className='dashboard'>
                    {/* <h3 style={{ color: '#11686D' }}>Employee Date Reports</h3> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>


                        <Select

                            style={{ width: '25%', fontFamily: 'var(--font)' }}
                            placeholder="Filter by expiry date"
                            value={filterType}
                            onChange={(value) => setFilterType(value)}
                        >
                            <Option value="withinAMonth">Expiry within a month</Option>
                            <Option value="expired">Expired</Option>
                        </Select>
                        <Select
    mode="multiple"
    style={{ width: '35%', fontFamily: 'var(--font)' }}
    placeholder="Select expiry columns"
    value={selectedExpiryColumns}
    onChange={(value) => setSelectedExpiryColumns(value)}
>
    {expiryColumns.map((column) => (
        <Option key={column.dataIndex} value={column.dataIndex}>
            {column.title}
        </Option>
    ))}
</Select>

                        <Button style={{ backgroundColor: "#6FB555", margin: "1vh 1vw", padding: '0 1vw', height: '23px' }} type="primary" onClick={exportToCSV}>
                            Export to CSV
                        </Button>
                    </div>
                    <div style={{ width: '100%', marginTop: '1rem' }}>
                        <Table

                            pagination={{
                                pageSize: 100,
                                current: currentPage,
                                onChange: (page) => setCurrentPage(page)
                            }}
                            dataSource={filteredEmployees}
                            loading={dataLoaded ? false : !dataLoaded}
                            columns={columns}
                            rowKey="_id"
                            scroll={{
                                x: 'max-content',
                                y: `calc(90vh - 250px)`
                            }}
                        />
                    </div>
                </div>
                {/* <div className='home-logo'>
                    <Link to='/admin' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
                    Date Reports
                </div>
                <div className="smalllogo-image">
                    <img src='/crootive.png' alt='Crootive' className='smalllogo-image' />
                </div> */}
            </div>
        </>
    );
};

export default ManDateReports;



