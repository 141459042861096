import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, Typography, message } from 'antd';
import {
    LoadingOutlined
} from '@ant-design/icons';

const { Title } = Typography;

const Manager = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const handleSignup = async (values) => {
		setIsLoading(true);
		const { managerEmail, managerPassword } = values;
		try {
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/createManager', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ managerEmail, managerPassword }),
			});

			const data = await response.json();

			if (response.ok) {
				message.success('Signup successful');
				form.resetFields();
				navigate('/');
			} else {
				setIsLoading(false);
				message.error(data.message || 'Signup failed');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Signup failed');
			setIsLoading(false);
		}
	};

	const handleLogin= () => {
        navigate('/');
    };

	return (
		<div className='login-container'>
			<div className='container'>
				<img src='/login.png' alt='Login' className='login-image' />
				<h2 className='heading'>Manager Signup</h2>
				<Form className='form' form={form} onFinish={handleSignup}>
					<Form.Item
						name="managerEmail"
						rules={[
							{ required: true, message: '' },
							{ type: 'email', message: 'Please enter a valid email!' },
						]}
					>
						<Input className='input-field' style={{width:'50vw'}} placeholder='username'/>
					</Form.Item>
					<Form.Item
						name="managerPassword"
						rules={[{ required: true, message: '' }]}
					>
						<Input.Password  style={{width:'50vw'}} className='input-field' placeholder='password'/>
					</Form.Item>
					<button className='login-button' >
                        {isLoading ? <LoadingOutlined /> : 'SIGNUP'}
                    </button>
					<button className='linkButton' onClick={handleLogin}>
                        Already have an account? Login here
                    </button> <br />
				</Form>
			</div>
		</div>
	);
};

export default Manager;
