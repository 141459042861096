import React, { useState, useEffect } from 'react';
import { message, Button, DatePicker, Select, Radio, Tooltip, Input, FloatButton, Spin } from 'antd';
import html2canvas from 'html2canvas';
import moment from 'moment';
import './LeaveApplication.css';
import { Link, useParams } from 'react-router-dom';
import { SaveOutlined, DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';
import dayjs from 'dayjs';
import Navbar from '../components/Navbar';
const { Option } = Select;
const { TextArea } = Input;
const LeaveApplication = () => {
	const { ecode } = useParams();
	const [isLoading, setIsLoading] = useState(false);

	const [formData, setFormData] = useState({
		leaveType: '',
		surname: '',
		firstName: '',
		branchDepartment: '',
		employeePayrollNo: '',
		jobTitle: '',
		reasonForLeave: '',
		firstDayOfLeave: null,
		lastDayOfLeave: null,
		dateReturnedToWork: null,
		numberOfLeaveDaysTaken: '',
		leaveEntitlement: '',
		dateJoined: null,
		dateLastLeaveTaken: null,
		totalDaysRemainingAfterPreviousLeave: '',
		currentYearLeaveEntitlement: '',
		additionalLeaveDaysRequested: '',
		ticket: '',
		contactDetailsOnHoliday: '',
		otherRequests: '',
		passportNo: '',
		nationality: '',
		passportExpiryDate: null,
		residenceVisaExpiry: null,
		travelDateAndTime: null,
		returnDateAndTime: null,
		flightNo: '',
		departureAirport: '',
		destinationAirport: '',
		employeeSignature: '',
		employeeSignatureDate: null,
		managerName: '',
		managerSignature: '',
		managerDept: '',
		managerSignatureDate: null,
		department: '',
		chairmanSignature: '',
		chairmanSignatureDate: null,
		eCode: ecode, // Add the employeeCode field
	});
	const [companyId, setCompanyId] = useState(null);
	const [logoComponent, setLogoComponent] = useState(null);
	const [isButtonVisible, setIsButtonVisible] = useState(true);
	const [managerEmail, setManagerEmail] = useState(null);
	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);

	// const fetchCompany = async () => {
	// 	const token = localStorage.getItem('token');
	// 	try {
	// 		const response = await fetch('https://hrms-5u7j.onrender.com/admin/getCompany', {
	// 			headers: {
	// 				Authorization: 'Bearer ' + token,
	// 			},
	// 		});
	// 		const data = await response.json();
	// 		if (response.ok) {
	// 			if (data.company && data.company._id) {
	// 				const companyId = data.company._id;
	// 				setCompanyId(companyId);
	// 			} else {
	// 				message.error('Company ID not found in the response');
	// 			}
	// 		} else {
	// 			message.error(data.error || 'Failed to fetch company');
	// 		}
	// 	} catch (error) {
	// 		console.error('Error:', error);
	// 		message.error('Failed to fetch company');
	// 	}
	// };
	// useEffect(() => {
	// 	fetchCompany();
	// }, []);
	const handleChange = (name, value) => {
		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
	};

	useEffect(() => {
		if (ecode) {
			handleFetchDetailsClick();
		}
	}, []);


	useEffect(() => {
		const storedManagerEmail = localStorage.getItem('managerEmail');
		if (storedManagerEmail) {

			const extractedName = storedManagerEmail.split('@')[0];
			const capitalizedName = extractedName.charAt(0).toUpperCase() + extractedName.slice(1);
			setManagerEmail(capitalizedName);

		}
	}, []);

	// console.log(formData)

	const handleSave = async () => {
		if (isLoading)
			return;
		setIsLoading(true);

		if (!formData.firstDayOfLeave || !formData.lastDayOfLeave) {
			setIsLoading(false);

			// Display an error message or take appropriate action when the fields are empty.
			message.error('Please fill in both First Day and Last Day of Leave.');
			return;
		};
		try {
			// await fetchCompany();

			// if (!companyId) {
			// 	message.error('Failed to fetch company');
			// 	return;
			// }
			// Fetch employee information
			const employeeInfo = await fetchEmployeeInfo(formData.eCode);
			const employeeId = employeeInfo[0]._id; // Assuming the response contains _id as the employee id
			const firstDayOfLeaveFormatted = moment(formData.firstDayOfLeave).format('YYYY-MM-DD');
			const lastDayOfLeaveFormatted = moment(formData.lastDayOfLeave).format('YYYY-MM-DD');
			const action = "On Leave Application Submitted for " + firstDayOfLeaveFormatted;
			// console.log(firstDayOfLeaveFormatted, lastDayOfLeaveFormatted)
			// const status="vacation";
			// console.log(employeeInfo, employeeId)
			const canvas = await html2canvas(document.querySelector('.leave-application-form'));
			const imgData = canvas.toDataURL('image/png');

			// Convert base64 image data to blob
			let byteCharacters = atob(imgData.split(',')[1]);
			let byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			let byteArray = new Uint8Array(byteNumbers);
			let blob = new Blob([byteArray], { type: 'image/png' });

			// Create form data and append the necessary values
			const uploadData = new FormData(); // changed the name to uploadData to avoid the conflict
			uploadData.append('file', blob, 'leave-application-form.png');
			// uploadData.append('companyId', companyId);
			uploadData.append('employeeId', employeeId); // Add employeeId to uploadData
			uploadData.append('managerEmail', managerEmail);
			uploadData.append('action', action);
			uploadData.append('firstDayOfLeaveFormatted', firstDayOfLeaveFormatted);
			uploadData.append('lastDayOfLeaveFormatted', lastDayOfLeaveFormatted);
			const token = localStorage.getItem('token');
			const response = await fetch('https://hrms-5u7j.onrender.com/manager/saveFile', {
				method: 'POST',
				headers: {
					Authorization: 'Bearer ' + token,
				},
				body: uploadData, // use uploadData here
			});

			if (response.ok) {
				// console.log('File saved successfully');
				message.success("File saved successfully");
			} else {
				message.error("Error saving file");
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			message.error("Error saving file");
		}
	};


	// Add this function to fetch the employee info
	const fetchEmployeeInfo = async (employeeCode) => {
		try {

			const token = localStorage.getItem('token');
			const response = await fetch(`https://hrms-5u7j.onrender.com/manager/getByECode`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
				body: JSON.stringify({ eCodes: employeeCode }),
			});

			if (!response.ok) {
				message.error('Failed to fetch employee information');
				return;
				// throw new Error('Failed to fetch employee information');
			}

			const employeeInfo = await response.json(); // This line extracts the body of the response
			// console.log(employeeInfo)
			return employeeInfo;
		} catch (error) {
			console.error(error);
		}
	};


	const handleDownload = async () => {
		setIsButtonVisible(false); // Hide the button
		const pdfElement = document.getElementById('section-to-print');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 10,
			filename: 'leave_application_form.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
	};
	const handleFetchDetailsClick = async () => {
		if (formData.eCode) {
			try {
				// console.log("here");
				const employeeInfo = await fetchEmployeeInfo(formData.eCode);
				if (employeeInfo && employeeInfo.length > 0) {
					const firstEmployeeInfo = employeeInfo[0]; // Assuming there's only one employee in the response

					// Update the form fields based on employeeInfo
					setFormData((prevFormData) => ({
						...prevFormData,
						refNo: firstEmployeeInfo.refNo || '',
						qidNumber: firstEmployeeInfo.qidNumber || '',
						firstName: firstEmployeeInfo.employeeName || '',
						position: firstEmployeeInfo.position || '',
						// branchDepartment: firstEmployeeInfo.department || '',TODO: set departmentName instead of department_.id
						passportNo: firstEmployeeInfo.passportNumber || '',
						nationality: firstEmployeeInfo.nationality || '',
						passportExpiryDate: dayjs(firstEmployeeInfo.passportExpiryDate) || null, // Set the value directly
						residenceVisaExpiry: dayjs(firstEmployeeInfo.visaExpiryDate) || null, // Set the value directly
						// Add other form fields here with corresponding values from employeeInfo
					}));
				} else {
					message.error('Employee information not found');
				}
			} catch (error) {
				console.error(error);
				message.error('Failed to fetch employee information');
			}
		} else {
			message.warning('Please enter an Employee Code');
		}
	};

	const calculateDaysOfLeave = () => {
		if (formData.firstDayOfLeave && formData.lastDayOfLeave) {
		  const firstDay = moment(formData.firstDayOfLeave, 'YYYY-MM-DD');
		  const lastDay = moment(formData.lastDayOfLeave, 'YYYY-MM-DD');
		  const daysOfLeave = lastDay.diff(firstDay, 'days') + 1; // +1 to include the first day in the count
		  return daysOfLeave;
		}
		return '';
	  };

	return (
		<>
			<div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
				<Navbar />
			</div>
			<div className="center-container" style={{ paddingTop: 120 }}>
				<div className="leave-application-form">
					<div className='local-leave-container' style={{ minHeight: '297mm', width: '210mm' }}>
						<div className='leave-application' id='section-to-print' style={{ fontSize: 'smaller' }}>
							<table className="section-header">
								<td style={{ width: '20%' }}>{logoComponent}</td>
								<td style={{ width: '60%' }}><h3>Leave Application Form</h3></td>
								<td style={{ width: '20%' }}><h5></h5></td>
							</table>

							<table className="leave-table">
								<tr >
									<td colSpan={4} className='header-row text-center' >
										<h7 style={{ textAlign: 'center', margin: '5px 0px' }}>Please ensure that this application is approved prior to confirming your holiday arrangements.</h7>
									</td>
								</tr>
								<tr>
									<td colSpan={4}>
										<Radio.Group name="leaveType" onChange={handleChange} style={{ display: 'flex', justifyContent: 'space-between', padding: '3px 55px', fontSize: 'smaller' }}>
											<Radio value="Annual Leave">Annual Leave</Radio>
											<Radio value="Local Leave">Local Leave</Radio>
											<Radio value="Emergency Leave">Emergency Leave</Radio>
											<Radio value="Other">Other</Radio>
										</Radio.Group>

									</td>
								</tr>
								<tr>
									<td style={{ width: '26%' }}><label>First Name</label></td>
									<td colSpan={3}>
										<TextArea autoSize name="firstName" value={formData.firstName} onChange={(e) => handleChange('firstName', e.target.value)} />
									</td>
								</tr>
								<tr>
									<td>
										<label>Position</label>
									</td>
									<td>
										<TextArea autoSize name="surname" value={formData.position} onChange={(e) => handleChange('position', e.target.value)} />
									</td>
									<td>
										<label>Employee Code</label>
									</td>
									<td>
										<input onKeyDown={(e) => e.key === "Enter" && handleFetchDetailsClick()} type="text" name="eCode" value={formData.eCode} onChange={(e) => handleChange('eCode', e.target.value)} />
									</td>
								</tr>
								<tr>
									<td>
										<label>QID Number</label>
									</td>
									<td>
										<TextArea autoSize name="qidNumber" value={formData.qidNumber} onChange={(e) => handleChange('qidNumber', e.target.value)} />
									</td>
									<td>
										<label>Reference Number</label>
									</td>
									<td>
										<TextArea autoSize name="refNo" value={formData.refNo} onChange={(e) => handleChange('refNo', e.target.value)} />
									</td>
								</tr>
								{/* <tr>
									<td>
										<label>Job Title</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="jobTitle" value={formData.jobTitle} onChange={(e) => handleChange('jobTitle', e.target.value)} />
									</td>
								</tr> */}
								<tr>
									<td colSpan={4} className='header-row' style={{ margin: '5px 0px' }}><h5>Reason for leave:</h5></td>
								</tr>
								<tr>
									<td colSpan={4}>
										<TextArea autoSize name="reasonForLeave" value={formData.reasonForLeave} onChange={(e) => handleChange('reasonForLeave', e.target.value)} />
									</td>
								</tr>
								<tr className='date-fields'>
									<td>First Day of Leave</td>
									<td>Last Day of Leave</td>
									<td>Date returned to work</td>
									<td>No. of leave days taken</td>
								</tr>
								<tr>
									<td>
										<DatePicker
											style={{ width: '100%', height: '25px' }}
											name="firstDayOfLeave"
											value={formData.firstDayOfLeave ? moment(formData.firstDayOfLeave) : null}
											onChange={(date) => handleChange('firstDayOfLeave', date ? date.toDate() : null)}
										/>
									</td>
									<td>
										<DatePicker
											name="lastDayOfLeave"
											style={{ width: '100%', height: '25px' }}
											value={formData.lastDayOfLeave ? moment(formData.lastDayOfLeave) : null}
											onChange={(date) => handleChange('lastDayOfLeave', date ? date.toDate() : null)}
										/>
									</td>
									<td>
										<DatePicker style={{ width: '100%', height: '25px' }} name="dateReturnedToWork" value={formData.dateReturnedToWork} onChange={(date) => handleChange('dateReturnedToWork', date)} />
									</td>
									<td>
									<input style={{ height: '25px' }} type="number" name="numberOfLeaveDaysTaken" value={calculateDaysOfLeave()} readOnly  />
									</td>
								</tr>
								<tr>
									<td colSpan={4} className='header-row'><h5 >Leave Entitlement</h5></td>
								</tr>
							</table>
							<table className='leave-table5'>
								<tr style={{ fontSize: 'small' }}>
									<td>Date Joined</td>
									<td>Date last leave taken</td>
									<td style={{ padding: '10px 0' }}>Total days remaining after previous leave</td>
									<td>Current year leave entitlement</td>
									<td>Additional leave days requested</td>
								</tr>
								<tr>
									<td>
										<DatePicker style={{ width: '100%', height: '25px' }} name="dateJoined" value={formData.dateJoined} onChange={(date) => handleChange('dateJoined', date)} />
									</td>
									<td>
										<DatePicker style={{ width: '100%', height: '25px' }} name="dateLastLeaveTaken" value={formData.dateLastLeaveTaken} onChange={(date) => handleChange('dateLastLeaveTaken', date)} />
									</td>
									<td>
										<input
											type="text"
											name="totalDaysRemainingAfterPreviousLeave"
											value={formData.totalDaysRemainingAfterPreviousLeave}
											onChange={(e) => handleChange('totalDaysRemainingAfterPreviousLeave', e.target.value)}
										/>
									</td>
									<td>
										<input
											type="text"
											name="currentYearLeaveEntitlement"
											value={formData.currentYearLeaveEntitlement}
											onChange={(e) => handleChange('currentYearLeaveEntitlement', e.target.value)}
										/>
									</td>
									<td>
										<input
											type="text"
											name="additionalLeaveDaysRequested"
											value={formData.additionalLeaveDaysRequested}
											onChange={(e) => handleChange('additionalLeaveDaysRequested', e.target.value)}
										/>
									</td>
								</tr>
								<tr>
									<td>Ticket</td>
									<td colSpan={4}>
										<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
											<span>
												<input type="radio" name="ticket" value="Paid by Company" onChange={handleChange} />
												Paid by Company
											</span>
											<span>
												<input type="radio" name="ticket" value="Own ticket" onChange={handleChange} />
												Own ticket
											</span>
										</div>
									</td>
								</tr>
								<tr>
									<td>Contact details whilst on holiday (Tel / Fax No. if applicable)</td>
									<td colSpan={4}><TextArea autoSize name="contactDetailsOnHoliday" value={formData.contactDetailsOnHoliday} onChange={(e) => handleChange('contactDetailsOnHoliday', e.target.value)} /></td>
								</tr>
								<tr>
									<td>Other Requests</td>
									<td colSpan={4}><TextArea autoSize name="otherRequests" style={{ height: '25px' }} value={formData.otherRequests} onChange={(e) => handleChange('otherRequests', e.target.value)} /></td>
								</tr>
							</table>
							<table className='leave-table'>
								<tr><td colSpan={12} className='header-row'><h5>Details of Flight Booking:</h5></td></tr>
								<tr>
									<td colSpan={3}>
										<label>Passport No</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="passportNo" value={formData.passportNo} onChange={(e) => handleChange('passportNo', e.target.value)} />
									</td>
									<td colSpan={3}>
										<label>Nationality</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="nationality" value={formData.nationality} onChange={(e) => handleChange('nationality', e.target.value)} />
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<label>Passport Expiry Date</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="passportExpiryDate" value={formData.passportExpiryDate} onChange={(date) => handleChange('passportExpiryDate', date)} />
									</td>
									<td colSpan={3}>
										<label>Residence Visa Expiry</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="residenceVisaExpiry" value={formData.residenceVisaExpiry} onChange={(date) => handleChange('residenceVisaExpiry', date)} />
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<label>Travel Date & Time (am/pm)</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="travelDateAndTime" value={formData.travelDateAndTime} onChange={(date) => handleChange('travelDateAndTime', date)} showTime format="YYYY-MM-DD HH:mm:ss" />
									</td>
									<td colSpan={3}>
										<label>Return Date & Time (am/pm)</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="returnDateAndTime" value={formData.returnDateAndTime} onChange={(date) => handleChange('returnDateAndTime', date)} showTime format="YYYY-MM-DD HH:mm:ss" />
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<label>Flight No.</label>
									</td>
									<td colSpan={2}>
										<TextArea autoSize name="flightNo" value={formData.flightNo} onChange={(e) => handleChange('flightNo', e.target.value)} />
									</td>
									<td colSpan={2}>
										<label>Departure (Airport)</label>
									</td>
									<td colSpan={2}>
										<TextArea autoSize name="departureAirport" value={formData.departureAirport} onChange={(e) => handleChange('departureAirport', e.target.value)} />
									</td>
									<td colSpan={2}>
										<label>Destination (Airport)</label>
									</td>
									<td colSpan={2}>
										<TextArea autoSize name="destinationAirport" value={formData.destinationAirport} onChange={(e) => handleChange('destinationAirport', e.target.value)} />
									</td>
								</tr>
								<tr><td colSpan={12} className='header-row' style={{ textAlign: 'center' }}><h5>Please attach details as above for others due to travel with you under your Contract</h5></td></tr>
								<tr>
									<td colSpan={3}>
										<label>Employee’s Signature</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="employeeSignature" value={formData.employeeSignature} onChange={(e) => handleChange('employeeSignature', e.target.value)} />
									</td>
									<td colSpan={3}>
										<label>Date</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="employeeSignatureDate" value={formData.employeeSignatureDate} onChange={(date) => handleChange('employeeSignatureDate', date)} />
									</td>
								</tr>
								<tr><td colSpan={12} className='header-row'><h5>Authorisation Details: I have approved the holiday dates requested above:</h5></td></tr>
								<tr>
									<td colSpan={3}>
										<label>Manager’s Name</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="managerName" value={formData.managerName} onChange={(e) => handleChange('managerName', e.target.value)} />
									</td>
									<td colSpan={3}>
										<label>Signature</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="managerSignature" value={formData.managerSignature} onChange={(e) => handleChange('managerSignature', e.target.value)} />
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<label>Department</label>
									</td>
									<td colSpan={3}>
										<TextArea autoSize name="managerDept" value={formData.managerDept} onChange={(e) => handleChange('managerDept', e.target.value)} />
									</td>
									<td colSpan={3}>
										<label>Date</label>
									</td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="managerSignatureDate" value={formData.managerSignatureDate} onChange={(date) => handleChange('managerSignatureDate', date)} />
									</td>
								</tr>
								<tr>
									<td colSpan={3}><label><strong>Chairman's Signature</strong></label></td>
									<td colSpan={3}><TextArea autoSize name="chairmanSignature" value={formData.chairmanSignature} onChange={(e) => handleChange('chairmanSignature', e.target.value)} /></td>
									<td colSpan={3}><label>Date</label></td>
									<td colSpan={3}>
										<DatePicker style={{ width: '100%' }} name="chairmanSignatureDate" value={formData.chairmanSignatureDate} onChange={(date) => handleChange('chairmanSignatureDate', date)} />
									</td>
								</tr>
							</table>

							{/* <br /> */}
							<div className='leave-checkbox mt-2'>
								<label><input type='checkbox' />HR Dept</label><br />
								<label><input type='checkbox' />Accounts Department</label><br />
								<label><input type='checkbox' />Passport checked for Visa &amp; Expiry Dates</label><br />
								<label><input type='checkbox' />Ticket Booked</label><br />
								<label><input type='checkbox' />Passport Retrieval Request Form</label><br />
							</div>
						</div>
						<FloatButton
							icon={isLoading ? <Spin/> : <SaveOutlined />}
							tooltip="Save"
							onClick={handleSave}
							style={{
								right: 200,
							}}
						/>
						{/* <Tooltip title="Print" placement='right'>
							<FloatButton
								icon={<PrinterOutlined />}
								onClick={() => window.print()}
								style={{
									right: 100,
								}}
							/>
						</Tooltip> */}
						<Tooltip title="Download" placement='top'>
							<FloatButton
								icon={<DownloadOutlined />}
								onClick={handleDownload}
								style={{
									right: 150,
								}}
							/>
						</Tooltip>
					</div>
				</div>
			</div>
		</>
	);
};

export default LeaveApplication;