import { useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import EmployeeDetails from './EmployeeDetails';
import EmployeeTimeline from './EmployeeTimeline';
import EmployeeFiles from './EmployeeFiles';
import { SolutionOutlined, PicRightOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './EmployeePage.css'
import AdminNavbar from './components/AdminNavbar';

const EmployeePage = () => {
  const { id } = useParams();
	
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	
	const [activeTab, setActiveTab] = useState(queryParams.get('tab') || 0);

  return (
    <div className='d-flex flex-column' style={{ minHeight: '100vh' }}>
			<div style={{ position: 'fixed', width: '100%', backgroundColor: 'white', zIndex: 100 }}>
				<AdminNavbar/>
			</div>
			<div className='d-flex gap-5' style={{ flex : 1, paddingTop: 90}}>
				<div className='employee-sidebar'>
					<Tooltip title="Employee Details" placement='right'>
						<div className={activeTab == 0 ? 'active' : ''} onClick={() => setActiveTab(0)}>
							<PicRightOutlined />
						</div>
					</Tooltip>
					<Tooltip title="Employee Timeline" placement='right'>
						<div className={activeTab == 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
							<ClockCircleOutlined />
						</div>
					</Tooltip>
					<Tooltip title="Employee Files" placement='right'>
						<div className={activeTab == 2 ? 'active' : ''} onClick={() => setActiveTab(2)}>
							<SolutionOutlined />
						</div>
					</Tooltip>
				</div>

				{activeTab == 0 && <EmployeeDetails id={id} />}
				{activeTab == 1 && <EmployeeTimeline id={id} />}
				{activeTab == 2 && <EmployeeFiles id={id} />}
			</div>
		</div>
  );
};

export default EmployeePage;
