import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { fetchAndDisplayLogo } from './LogoFetcher';
import html2pdf from 'html2pdf.js';

const TrainingNeedAssessment = () => {
	const [logoComponent, setLogoComponent] = useState(null);

	// useEffect(() => {
	// 	fetchAndDisplayLogo().then((logo) => {
	// 		setLogoComponent(logo);
	// 	});
	// }, []);

	const handleDownload = async () => {
		const pdfElement = document.querySelector('.local-leave-container');

		if (!pdfElement) {
			console.error('PDF element not found.');
			return;
		}

		const options = {
			margin: 0,
			filename: 'training-need-assessment.pdf',
			image: { type: 'jpeg', quality: 0.98 },
			html2canvas: { scale: 2 },
			jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
		};

		html2pdf().from(pdfElement).set(options).save();
	};

	return (
		<div className="center-container">
			<p className='home-header' style={{position:'fixed'}}>
                <Link to='/manager/hr-forms' style={{ color: '#11686D', paddingRight: '3vw' }}><ArrowLeftOutlined /></Link>
            </p>
			<div className="disciplinary-action-form">
				<div className='local-leave-container' style={{ height: '296mm', width: '210mm' }}>
					<table className="section-header">
						<td style={{ width: '20%' }}>{logoComponent}</td>
						<td style={{ width: '60%' }}><h3 style={{ border: 'none', marginBottom: '0' }}>Training Need Assessment</h3></td>
						<td style={{ width: '20%' }}>SD-HR-F11│00</td>
					</table>
					<br />
					<table className="leave-table" style={{ textAlign: 'center', padding: '2px 2px' }}>
						<tr>
							<td style={{ backgroundColor: '#F5F5F5', width: '30%' }}>
								<label style={{ fontWeight: '700' }}>Training Requsted by</label>
							</td>
							<td>
								<input type="text" />
							</td>
							<td style={{ backgroundColor: '#F5F5F5' }}>
								<label style={{ fontWeight: '700' }}>Date</label>
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td style={{ backgroundColor: '#F5F5F5', width: '20%' }}>
								<label style={{ fontWeight: '700' }}>Department / Project</label>
							</td>
							<td>
								<input type="text" />
							</td>
							<td style={{ backgroundColor: '#F5F5F5' }}>
								<label style={{ fontWeight: '700' }}>Priority</label>
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>

					</table>
					<table className="leave-table" style={{ marginTop: '5px', marginBottom: '5px' }}>
						<tr>
							<td style={{ backgroundColor: '#F5F5F5' }}>
								<label style={{ fontWeight: '700' }}>Training Need Assessment</label>
							</td>
						</tr>
						<tr>
							<td>
								<input type="text" />
							</td>
						</tr>
					</table>

					<table className="leave-table" style={{ marginBottom: '5px' }} >
						<tr>
							<td><label>Recommended Staff</label></td>
						</tr>
					</table>
					<table className="leave-table" style={{ textAlign: 'center', padding: '2px 2px' }}>

						<tr style={{ backgroundColor: '#F5F5F5' }}>
							<td style={{ width: '10%' }}>
								<label style={{ fontWeight: '700' }}>S/ N</label>
							</td>
							<td>
								<label style={{ fontWeight: '700' }}>Name</label>
							</td>
							<td>
								<label style={{ fontWeight: '700' }}>Designation</label>
							</td>
							<td >
								<label style={{ fontWeight: '700' }}>Training Need</label>
							</td>
							<td style={{ width: '30%' }}>
								<label style={{ fontWeight: '700' }}>Tentative Training Date</label>
							</td>
						</tr>
						<tr>
							<td>
								1
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td>
								2
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td>
								3
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td>
								4
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td>
								5
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td>
								6
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td>
								7
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td>
								8
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td>
								9
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>
						<tr>
							<td>
								10
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
							<td>
								<input type="text" />
							</td>
						</tr>


					</table>
					<table className="leave-table" style={{ marginBottom: '5px' }}>
						<tr>
							<td><label>Requester Sign</label>
								<label style={{ marginLeft: '200px' }}>Head of Dept. (Name & Sign)</label></td>

						</tr>
					</table>
					<table className="leave-table" style={{ marginBottom: '5px' }}>
						<tr>
							<td style={{ backgroundColor: '#F5F5F5' }}>
								<label style={{ fontWeight: '700' }}>TRAINING SECTION</label>
							</td>
						</tr>
						<tr>
							<td>
								<div style={{ display: 'flex' }}>
									<label style={{ width: '80%' }}>Rejection or Acceptance Remarks:</label>
									<input type="text" />
								</div>

								<div style={{ display: 'flex' }}>
									<label style={{ width: '50%' }}>Name & Sign</label>
									<input type="text" />
									<label>Date:</label>
									<input type="text" />
								</div>

							</td>
						</tr>
					</table>
					<table className="leave-table" style={{ marginBottom: '5px' }}>
						<tr>
							<td style={{ backgroundColor: '#F5F5F5' }}>
								<label style={{ fontWeight: '700' }}>(Sign)</label>
							</td>
							<td>
								<table className="leave-table">
									<tr>
										<td style={{ backgroundColor: '#F5F5F5' }}>
											<label style={{ fontWeight: '700' }}>Approved By</label>
										</td>
									</tr>
									<tr>
										<td>
											<input type="text" />
										</td>
									</tr>
								</table>
							</td>
							<td>
								<table className="leave-table">
									<tr>
										<td style={{ backgroundColor: '#F5F5F5' }}>
											<label style={{ fontWeight: '700' }}>Authorized by</label>
										</td>
									</tr>
									<tr>
										<td>
											<input type="text" />
										</td>
									</tr>
								</table>
							</td>
						</tr>
						<tr>
							<td style={{ backgroundColor: '#F5F5F5' }}>
								<label style={{ fontWeight: '700' }}>Name</label>
							</td>
							<td>

							</td>
							<td>

							</td>
						</tr>
						<tr>
							<td style={{ backgroundColor: '#F5F5F5' }}>
								<label style={{ fontWeight: '700' }}>Designation</label>
							</td>
							<td style={{ backgroundColor: '#F5F5F5' }}>
								<label style={{ fontWeight: '700' }}>QMS Coordinator</label>
							</td>
							<td style={{ backgroundColor: '#F5F5F5' }}>
								<label style={{ fontWeight: '700' }}>Managing Director</label>
							</td>
						</tr>
					</table>
				</div>
				<div className="button-group">
					<button className="download-button" onClick={handleDownload}>
						Download
					</button>
				</div>
			</div>
		</div>
	);
};

export default TrainingNeedAssessment;