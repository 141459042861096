import React, { useState, useEffect, useContext } from 'react';
import { Button, message, Popconfirm, Select, Form,Input, Row, Col } from 'antd';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './PayrollSetup.css';
import { DepartmentsContext } from './DepartmentsContext';
import { DeleteOutlined } from '@ant-design/icons';
import AdminNavbar from './components/AdminNavbar';


const PayrollSetup = () => {
	const [payrollType, setPayrollType] = useState('');
	const [payrollTypeSelect, setPayrollTypeSelect] = useState('');
	const [payrollTypeSelectMain, setPayrollTypeSelectMain] = useState('');
	const [ranges, setRanges] = useState([]);
	const [vehType, setVehType] = useState('');
	const [companyId, setCompanyId] = useState(null);
	const [payrollSetups, setPayrollSetups] = useState({ 'ppd': [], 'ppp': [] });
	const [departments, setDepartments] = useState([]);
	const { selectedDepartments, setSelectedDepartments } = useContext(DepartmentsContext);
	const [companyName, setCompanyName] = useState(null);
	const [showPPO, setShowPPO] = useState(false);

	const navigate = useNavigate();
	const url = 'https://hrms-5u7j.onrender.com/admin';
	// https://hrms-5u7j.onrender.com/admin
	useEffect(() => {
		fetchCompany();
		fetchDepartments();
	}, []);

	const fetchCompany = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(url + '/getCompanyAtt', {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});
			const data = await response.json();
			console.log('Company Data:', data);
			if (response.ok) {
				setCompanyId(data.company._id);

				setCompanyName(data.company.companyName);
				fetchDepartments(data.company.companyName);
				// // console.log("companyName", companyName);
			} else {
				message.error('Failed to fetch company');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch company');
		}
	};


	const fetchDepartments = async (companyName) => {
		if (!companyName) {
			return; // Return early if companyName is falsy
		}
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(url + `/getDepartmentsE/${companyName}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			// // console.log('Departments Data:', data);
			if (response.ok) {
				const departments = data; // Assign the response data directly
				setDepartments(departments);
			} else {
				console.error('Failed to fetch departments:', data.error);
				// throw new Error(data.error);
			}
		} catch (error) {
			console.error('Error:', error);
			// throw error;
		}
	};

	useEffect(() => {
		// Call fetchCompany on component mount
		fetchCompany();
		if (companyId) {
			fetchPayrollSetup();
		}
	}, [companyId]);

	const fetchPayrollSetup = async () => {
		const token = localStorage.getItem('token');
		try {
			const response = await fetch(url + `/getPayRoll/${companyId}`, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			});

			if (!response.ok) {
				message.error('Failed to fetch payroll setup');
				return
				// throw new Error('Failed to fetch payroll setup');
			}

			const data = await response.json();
			// console.log('Payroll Setup:', data);
			if (!data.payrollSetup || data.payrollSetup.length === 0) {
				message.error('Payroll setups are empty');
				return
				// throw new Error('Payroll setups are empty');
			}
			const payrollDict = data.payrollSetup.reduce((acc, { payrollType, ranges }) => {
				acc[payrollType] = ranges; // Set type as key and ranges as value
				return acc;
			}, {});
			setPayrollSetups(payrollDict);


			// Redirect to SalariesAndPayroll page if the payroll type is "fixed"
			if (data.payrollSetup.payrollType === 'fixed' && data.payrollSetup.departments.length > 0) {
				navigate(`/salariesandpayroll/fixed/${selectedDepartments.join(',')}`);
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to fetch payroll setups');
		}
	};

	const handlePayrollTypeChange = (event) => {
		setPayrollType(event);
		setPayrollTypeSelect(event);
		setRanges([]);
		setVehType('');
		//setShowPPO(false);
	};

	const handlePayrollTypeMainChange = (value) => {
		setPayrollTypeSelectMain(value);
		setPayrollTypeSelect('');
		setRanges([]);
		setVehType('');
	};

	const handleVehTypeChange = (event) => {
		const selectedVehType = event;
		setVehType(selectedVehType);
		//setShowPPO(false);
		const firstThreeChars = payrollType.substring(0, 3);
		if (firstThreeChars === 'ppp' || firstThreeChars === 'ppd') {
			if (selectedVehType === 'Bike') {
				setPayrollType(`${firstThreeChars}b`); // Add 'b' for Bike
			} else if (selectedVehType === 'Car') {
				setPayrollType(`${firstThreeChars}c`); // Add 'c' for Car
			}
		}
	};

	const deletePayroll = async (payrollId) => {
		try {
			const token = localStorage.getItem('token');
			const response = await fetch(url + `/deletePayroll/${payrollId}`, {
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			if (response.ok) {
				message.success('Payroll deleted successfully');
				fetchPayrollSetup();
			} else {
				message.error('Failed to delete payroll');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to delete payroll');
		}
	};


	const handleAddRange = () => {
		// Check if there are already ranges added
		if (ranges.length === 0) {
			setRanges([...ranges, { start: '', end: '', rate: '', contract: '' }]);
		} else {
			message.warning("Only one range can be added at a time. Save the current range first.");
		}
	};

	const handleRangeChange = (index, field, value) => {
		const updatedRanges = [...ranges];
		updatedRanges[index][field] = value;
		setRanges(updatedRanges);
	};

	const handleDepartmentChange = (departmentId) => {
		setSelectedDepartments((prevSelectedDepartments) => {
			const updatedDepartments = [...prevSelectedDepartments];
			const departmentIndex = updatedDepartments.indexOf(departmentId);
			if (departmentIndex > -1) {
				updatedDepartments.splice(departmentIndex, 1);
			} else {
				updatedDepartments.push(departmentId);
			}
			return updatedDepartments;
		});
	};


	const handleApply = async () => {
		if (selectedDepartments.length === 0) {
			message.error('Please select at least one department');
			return;
		}

		try {
			const token = localStorage.getItem('token');

			if (!companyId) {
				message.error('Company not found');
				return;
			}

			const payrollData = {
				companyId,
				payrollSetup: {
					payrollType,
					ranges: [...ranges],
					departments: [...selectedDepartments],
				},
			};

			const response = await fetch(url + '/savePayRoll', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(payrollData),
			});

			const data = await response.json();
			if (response.ok) {
				message.success('Payroll setup applied successfully');
				// Process the response data or perform any necessary actions
			} else {
				message.error(data.error || 'Failed to save payroll setup');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to save payroll setup');
		}
	};
	const handleSave = async () => {
		if (ranges.some(range => !range.start || !range.end || !range.rate)) {
			message.error('Please fill all the fields before saving');
			return;
		}
		try {
			const token = localStorage.getItem('token');

			if (!companyId) {
				message.error('Company not found');
				return;
			}
			console.log('payrollSetups:', payrollSetups, payrollSetups[payrollType]);
			for (const range of ranges) {
				if (payrollSetups[payrollType]) {
					for (const payroll of payrollSetups[payrollType]) {
						if (payrollType === 'ppdc') {
							if (String(range.contract) !== String(payroll.contract)) {
								continue;
							}
						}
						if (range.start >= payroll.start && range.start <= payroll.end) {
							message.error(`Rate for ${range.start} already exists`);
							return
						}
						if (range.end >= payroll.start && range.end <= payroll.end) {
							message.error(`Rate for ${range.end} already exists`);
							return
						}
					}
				}
			}



			const payrollData = {
				payrollSetup: {
					payrollType,
					ranges: [...ranges],
				},
			};

			const response = await fetch(url + '/savePayRoll', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(payrollData),
			});

			const data = await response.json();
			if (response.ok) {
				message.success('Payroll setup saved successfully');
				fetchPayrollSetup();
				setRanges([]);
				// Process the response data or perform any necessary actions
			} else {
				message.error(data.error || 'Failed to save payroll setup');
			}
		} catch (error) {
			console.error('Error:', error);
			message.error('Failed to save payroll setup');
		}
	};


	const handleShowPPO = () => {
		setShowPPO(showPPO ? false : true);
	}

	const renderRangeInputs = {
		pppb: 'Bike Pay per Pickup',
		pppc: 'Car Pay per Pickup',
		ppdb: 'Bike Pay per Drop off',
	};

	return (
		<div className=''>
			<AdminNavbar />
			<div className='col-md-7' style={{ padding: '3rem 5rem' }} >
				<Form layout="vertical" onFinish={() => console.log('Form submitted!')}>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item label="Choose Payroll Type" name="payrollTypeSelectMain">
								<Select
									value={payrollTypeSelectMain}
									onChange={handlePayrollTypeMainChange}
								>
									<Select.Option value="ppo">PPO (Pay per Order)</Select.Option>
									<Select.Option value="pph">PPH (Pay per Hour)</Select.Option>
									<Select.Option value="fixed">Fixed Salary</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>

					{(payrollTypeSelectMain === 'ppo') && (
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label="Choose Payroll Order Type" name="payrollTypeSelect">
									<Select
										value={payrollTypeSelect}
										onChange={handlePayrollTypeChange} // Change this line
									>
										<Select.Option value="">Choose Payroll Order Type</Select.Option>
										<Select.Option value="ppp">PPP (Pay per Pickup)</Select.Option>
										<Select.Option value="ppd">PPD (Pay per Drop Off)</Select.Option>
									</Select>
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item label="Choose Vehicle Type" name="vehType">
									<Select
										value={vehType}
										onChange={handleVehTypeChange} // Change this line
										disabled={!payrollTypeSelect}
									>
										<Select.Option value="">Choose Vehicle Type</Select.Option>
										<Select.Option value="Bike">Bike</Select.Option>
										<Select.Option value="Car">Car</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						</Row>
					)}

					{payrollTypeSelectMain === 'fixed' && departments.length > 0 && (
						<div className='payroll-department'>
							<p style={{ fontWeight: '800' }}>Select Departments:</p>
							{departments.map((department) => (
								<div key={department._id}>
									<label>
										<input
											type="checkbox"
											checked={selectedDepartments.includes(department._id)}
											onChange={() => handleDepartmentChange(department._id)}
										/>
										{department.departmentName}
									</label>
								</div>
							))}
						</div>
					)}




					{renderRangeInputs[payrollType] && (
						<div className='title'>
							<Form.Item label={renderRangeInputs[payrollType]} className="custom-label-only" />
							<Button type="primary"  style={{marginRight:'15px'}}  onClick={handleAddRange}>Add Range</Button>
							<Button type="primary" onClick={handleShowPPO}>{showPPO ? 'Hide' : 'Show'} Existing Ranges</Button>
							<div style={{marginTop:'1rem'}}>
							{ranges.map((range, index) => (
								<Row key={index} gutter={16}>
									<Col span={6}>
										<Form.Item>
											<Input
												type="number"
												placeholder="Start"
												value={range.start}
												onChange={(e) => handleRangeChange(index, 'start', e.target.value)}
												style={{marginRight:'15px'}} 
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item>
											<Input
												type="number"
												placeholder="End"
												value={range.end}
												onChange={(e) => handleRangeChange(index, 'end', e.target.value)}
												style={{marginRight:'15px'}} 
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item>
											<Input
												type="number"
												placeholder="Rate"
												value={range.rate}
												onChange={(e) => handleRangeChange(index, 'rate', e.target.value)}
											/>
										</Form.Item>
									</Col>
								</Row>
							))}
							</div>
						</div>
					)}
					{(payrollType === 'ppdc') && (
						<div className='title'>
						<Form.Item label='Car Pay per Drop off' className="custom-label-only" />
							<Button type="primary" style={{marginRight:'15px'}} onClick={handleAddRange}>Add Range</Button>
							<Button type="primary" onClick={handleShowPPO}>{showPPO ? 'Hide' : 'Show'} Existing Ranges</Button>
							<div style={{marginTop:'1rem'}}>
							{ranges.map((range, index) => (
								<Row key={index} gutter={16}>
									<Col span={6}>
										<Form.Item>
											<Input
												type="number"
												placeholder="Contract"
												value={range.contract || ''}
												onChange={(e) => handleRangeChange(index, 'contract', e.target.value)}
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item>
											<Input
												type="number"
												placeholder="Start"
												value={range.start}
												onChange={(e) => handleRangeChange(index, 'start', e.target.value)}
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item>
											<Input
												type="number"
												placeholder="End"
												value={range.end}
												onChange={(e) => handleRangeChange(index, 'end', e.target.value)}
											/>
										</Form.Item>
									</Col>
									<Col span={6}>
										<Form.Item>
											<Input
												type="number"
												placeholder="Rate"
												value={range.rate}
												onChange={(e) => handleRangeChange(index, 'rate', e.target.value)}
											/>
										</Form.Item>
									</Col>
								</Row>
							))}
						</div>
						</div>
					)}

					{payrollTypeSelectMain === 'fixed' && selectedDepartments.length > 0 && (
						<button className='primary-button' onClick={handleApply}>Apply</button>
					)}

					{payrollTypeSelectMain !== 'fixed' && (
						<button className='primary-button' onClick={handleSave}>Save</button>
					)}

				</Form>

				{payrollSetups[payrollType]?.length > 0 && showPPO && (
					<div className="existing-setups-container" >
						<h3 >Existing Payroll Setups:</h3>
						<ul>
							{payrollSetups[payrollType].map((range, index) => (
								<li key={index}>
									Payroll Range: <strong>{range.start}</strong> - <strong>{range.end}</strong> (PPO: {range.rate})
									{payrollType === 'ppdc' && (
										<span> (Contract: {range.contract})</span> // Display contract only for ppdc
									)}
									<Popconfirm
										title="Are you sure you want to delete this range?"
										onConfirm={() => deletePayroll(range._id)}
									>
										<Button danger type='text' shape='circle' icon={<DeleteOutlined />}></Button>
									</Popconfirm>
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
			{/* <p className='home-header' style={{ top: '40px', left: '20px' }}>
				<Link to='/admin' style={{ color: '#11686D', paddingRight: '3vw' }}><HomeFilled /></Link>
				Payroll Setup
			</p> */}
		</div>
	);
};

export default PayrollSetup;
